import { useMutation, useQuery, useQueries } from "react-query";
import { apiRequest } from "@services/api";
import {
    withEnabledCondition,
    withPageScope,
    asDataQuery,
    asEmbeddedQuery,
    Scope,
    withLoadingContext,
} from "@hooks/higher-order-hooks";
import { useMemo } from "react";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import { withRefreshKey } from "@providers/RefreshKeyProvider";
import { useParams } from "react-router-dom";
import { useProposalsFacade } from "@bounded-context/customer-investment-strategy/facades/proposals";

export const useCustomerInvestmentStrategyFeasibilityDictionaryQuery = Symbol("useCustomerInvestmentStrategyFeasibilityScreenUrlQuery");

const useCustomerInvestmentStrategiesProposalsQuery = (options) => {
    const { useProposalsUrlQuery } = useProposalsFacade();
    const { data: url } = useProposalsUrlQuery();

    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);
    const useQueryWithRefreshKey = withRefreshKey(useConditionalQuery);
    const useQueryWithLoadingContext = withLoadingContext(useQueryWithRefreshKey);

    const queryKey = ["customer-investment-strategies", "proposals", { url }];

    const queryFn = () => apiRequest
        .set("Accept", "application/hal+json")
        .get(url)
        .send();

    return useQueryWithLoadingContext({ queryKey, queryFn, ...options });
};

export const useCustomerInvestmentStrategiesProposalsDataQuery = asDataQuery(useCustomerInvestmentStrategiesProposalsQuery);
export const useCustomerInvestmentStrategiesProposalsEmbeddedQuery = asEmbeddedQuery(useCustomerInvestmentStrategiesProposalsQuery);

export const useCustomerInvestmentStrategiesQuery = (options) => {
    const { data: customerInvestmentStrategies } = useCustomerInvestmentStrategiesProposalsEmbeddedQuery("customer-investment-strategies", { keepPreviousData: true });

    const queries = useMemo(() => {
        if (!Array.isArray(customerInvestmentStrategies)) return [];
        return customerInvestmentStrategies?.map((cis, index) => {
            const meta = {
                ...options?.meta,
                index,
                scope: Scope.PAGE,
            };

            const effectiveOptions = {
                select: (x) => get(x, "body"),
                ...options,
                meta,
            };

            const queryFn = () => Promise.resolve({
                body: cis
            });

            const url = get(cis, "_links.self");

            return {
                queryKey: ["customer-investment-strategies", "proposals", {
                    url,
                }],
                queryFn,
                ...effectiveOptions
            };
        });
    }, [customerInvestmentStrategies, options]);


    return useQueries(queries);
};

export const useCustomerInvestmentStrategySustainabilityCharacteristicsQuery = (queryUrl) => {
    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!queryUrl);

    const queryKey = [queryUrl];

    const queryFn = () => apiRequest
        .set("Accept", "application/hal+json")
        .get(queryUrl)
        .send();

    return useConditionalQuery({ queryKey, queryFn });
};

export const useCustomerInvestmentStrategiesProposalsMutation = (options) => {
    const { useProposalsUrlQuery } = useProposalsFacade();
    const { data: url } = useProposalsUrlQuery();

    const mutationFn = (data) => apiRequest
            .set("Accept", "application/hal+json")
            .post(url)
            .ok((response) => response.status === 200 || response.status === 409)
            .send(data);

    return useMutation(mutationFn, {
        useErrorBoundary: false,
        ...options,
    });
};

export const useActiveCustomerInvestmentStrategyDataQuery = (options) => {
    const select = (activeCustomerInvestmentStrategy) => get(activeCustomerInvestmentStrategy, "[0]");

    return useCustomerInvestmentStrategiesProposalsEmbeddedQuery("active-customer-investment-strategy", { select, ...options });
};

export const useCustomerInvestmentStrategyProposalDataQuery = ({ strategyId, ...options }) => {
    const relation = "customer-investment-strategies";

    const { strategyId: strategyIdFromParams } = useParams();

    const usedStrategyId = strategyId || strategyIdFromParams;

    const select = (customerInvestmentStrategies) => {
        const strategyIdIndex = findIndex(customerInvestmentStrategies, { id: usedStrategyId });

        return customerInvestmentStrategies?.[strategyIdIndex];
    };

    return useCustomerInvestmentStrategiesProposalsEmbeddedQuery(relation, { ...options, select });
};
