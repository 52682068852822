import * as colors from "@material-ui/core/colors";

export default {
    root: {
        backgroundColor: colors.blueGrey[50],
        color: colors.blueGrey[900]
    },
    deletable: {
        "&:focus": {
            backgroundColor: colors.blueGrey[100]
        }
    }
};
