import superagent from "superagent";
import store from "@ducks";
import { actions as errorBoundaryActions, ERROR_TYPES } from "@ducks/error-boundary";
import { remove } from "@services/utils";

const apiListeners = [];
export const addApiListener = (cb, predicateFn) => {
    const listener = { cb, predicateFn };
    apiListeners.push(listener);
    return listener;
};

export const removeApiListener = (listener) =>
    remove(apiListeners, listener);

const listenerInterceptor = (error, response, request) => {
    apiListeners.forEach(listener => {
        if (listener.predicateFn && !listener.predicateFn(error, response, request)) {
            return;
        }

        listener.cb(error, response, request);
    });
};

const errorInterceptor = useStore => (error, response, request) => {
    if (!error) {
        return;
    }

    if (!useStore) {
        console.error(error.response && error.response.error ? error.response.error.stack : error);
        return;
    }

    if (error.response && error.response.error && error.response.serverError) {
        store.dispatch(errorBoundaryActions.setErrorData(ERROR_TYPES.SERVER_NETWORK_ERROR, error.response.error));
    } else if (error.crossDomain || !navigator.onLine) {
        store.dispatch(errorBoundaryActions.setErrorData(ERROR_TYPES.LOW_LEVEL_NETWORK_ERROR, error));
    } else {
        console.error(error.response && error.response.error ? error.response.error.stack : error);
    }
};

/*
NEEDS FIX B: separate the various aspects here (session, auth) into individual plugins
 */
const investifyPlugin = function (useStore) {
    return (function (useStore) {
        const responseInterceptors = [
            listenerInterceptor,
            errorInterceptor(useStore)
        ];

        return req => {
            req._maskedEnd = req.end;
            req.end = function (callback) {
                return req._maskedEnd(function (err, response) {
                    responseInterceptors.forEach(interceptor => interceptor(err, response, req));
                    return callback(err, response);
                });
            };

            return req;
        };
    })(useStore);
};

export const legacyApiRequest = superagent
    .agent()
    .withCredentials()
    .use(investifyPlugin(true));

export const legacyRequest = superagent
    .agent()
    .use(investifyPlugin(true));

export const apiRequest = superagent
    .agent()
    .withCredentials()
    .use(investifyPlugin(false));

export const request = superagent
    .agent()
    .use(investifyPlugin(false));
