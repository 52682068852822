import env from "@config";
import { legacyApiRequest } from "@services/api";

const identity = (x) => x;

const getLoginUri = () => legacyApiRequest.get(env.API_AUTH).then((re) => re.body.loginUri);

export const auth = (user, pass) => getLoginUri().then((loginUri) => legacyApiRequest
        .post(loginUri)
        .set("Content-Type", "application/hal+json")
        .set("Accept", "application/hal+json")
        .send({ type: "login", email: user, password: pass }));

export const register = (user, pass, dataProtection) => getLoginUri().then((loginUri) => legacyApiRequest
        .post(loginUri)
        .set("Content-Type", "application/json")
        .set("Accept", "application/hal+json")
        .send({ type: "registration", email: user, password: pass, agreedToDataPrivacyStatement: dataProtection }));

export const prospectAuth = (payload) => getLoginUri().then((loginUri) => legacyApiRequest
    .post(loginUri)
    .send(payload)
    .then(identity));
