import { Dictionary } from "@common/config/dictionaries";
import { createDictionaryRequest } from "@utils/dictionary";

export * from "@common/config/dictionaries";

/*
 * Not a great solution. It acts as a workaround to make modules known to the builder (path in import has to be at least partially defined).
 */
const dictionaryModules = {
    [Dictionary.BC_ADVISORY_PROCESS.TILE]: createDictionaryRequest({ dictionaryId: "advisory-process/advisory-process", providerId: "content-access" }),
    [Dictionary.BC_PAYMENTS.DASHBOARD_EXTENSION]: createDictionaryRequest({ dictionaryId: "totalPortfolioComponent", providerId: "contentful" }),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.DASHBOARD.PERFORMANCE]: createDictionaryRequest({ dictionaryId: "performanceComponent", providerId: "contentful" }),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.DASHBOARD.PORTFOLIO_EVOLUTION]: createDictionaryRequest({ dictionaryId: "portfolioEvolutionComponent", providerId: "contentful" }),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.DASHBOARD.TOTAL_PORTFOLIO]: createDictionaryRequest({ dictionaryId: "totalPortfolioComponent", providerId: "contentful" }),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.TRANSACTIONS]: createDictionaryRequest({ dictionaryId: "customerTransactionsComponent", providerId: "contentful" }),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.PORTFOLIO_DETAILS]: createDictionaryRequest({ dictionaryId: "portfolio-analysis/portfolio-details", providerId: "content-access" }),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.DASHBOARD.VALUE_PRESERVATION]: import("@bounded-context/portfolio-analysis/dictionary/dashboard/valuePreservation"),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.DASHBOARD.DIRECTIVE_CONFORMITY]: import("@bounded-context/portfolio-analysis/dictionary/dashboard/directiveConformity"),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.DASHBOARD.SUSTAINABILITY]: import("@bounded-context/portfolio-analysis/dictionary/dashboard/sustainability"),
    [Dictionary.BC_PORTFOLIO_ANALYSIS.DASHBOARD.PORTFOLIO_REALIZATION_OF_PURPOSE]: import("@bounded-context/portfolio-analysis/dictionary/dashboard/realizationOfPurpose"),
    [Dictionary.BC_SEPA_MANDATE.DEFAULT]: import("@bounded-context/sepa-mandate/dictionary/sepa-mandate"),
    [Dictionary.BC_PAYMENT_PLANS.DEFAULT]: import("@bounded-context/payment-plans/dictionary/payment-plans"),
    [Dictionary.SCREEN_ERROR]: import("@screens/dictionary/error"),
} as const;

export default dictionaryModules;
