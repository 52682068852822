import React, { useCallback } from "react";
import { useFooterDataQuery } from "@hooks/footer";
import { useLocationEffect } from "@hooks/router";
import Hidden from "@material-ui/core/Hidden";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import MuiListItemText from "@material-ui/core/ListItemText";
import { AnimatePresence, useCycle, m } from "framer-motion";
import { isEmpty, trim } from "lodash";
import { Link } from "react-router-dom";
import { parsePlaceholderText } from "@utils";
import Fab from "@material-ui/core/Fab";
import Color from "color";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MenuIcon from "@material-ui/icons/Menu";
import If from "@components/If";
import { useIsRoutableLink } from "@core/application/router/hooks";

const styles = theme => ({
    root: {
        position: "fixed",
        zIndex: theme.zIndex.appBar,
        width: "100%",
        height: "auto",
        display: "flex",
        bottom: 0,
        boxShadow: theme.shadows[16],
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        "& a": {
            textDecoration: "none",
            color: theme.palette.text.primary
        }
    },
    fabRoot: {
        transition: theme.transitions.create(["box-shadow", "transform"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
        }),
        position: "fixed",
        zIndex: theme.zIndex.appBar + 1,
        bottom: theme.spacing(1),
        right: theme.spacing(2),
        boxShadow: `0 0 0 1px ${Color(theme.palette.primary.main).fade(0.6).toString()}`,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        "&:active": {
            transform: "scale(0.95)",
            backgroundColor: theme.palette.background.default,
            boxShadow: `0 0 0 1px ${Color(theme.palette.primary.main).toString()}`,
        },
        "&:hover, &:focus,": {
            boxShadow: `0 0 0 2px ${Color(theme.palette.primary.main).fade(0.1).toString()}`,
            backgroundColor: theme.palette.background.default,
        },
        [theme.breakpoints.down("sm")]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    },
    footerHeight: {
        height: theme.props?.footer?.main?.height,
    },
    footerContent: {
        height: theme.props?.footer?.main?.height,
        justifyContent: "space-between",
        alignItems: "center",
    },
    brandAndYear: {
        paddingLeft: "2rem",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1rem",
        }
    },
    linksOnDesktop: {
        display: "flex",
        paddingRight: "1rem",
    },
    footerButton: {
        fontWeight: 400,
        fontSize: "0.875rem",
        marginRight: "0.5rem"
    },
    spacingBorder: {
        marginLeft: "0.2rem"
    },
    mobileMenu: {
        width: "100%"
    },
    goUp: {
        position: "absolute"
    },
    mobileDrawer: {
        width: "100%",
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        paddingRight: theme.spacing(8)
    },
    mobileDrawerText: {
        color: theme.palette.text.primary,
        "& span, a": {
            fontSize: theme.typography?.body2?.fontSize,
        },
    },
    contactContainerOnMobile: {
        extend: "textOnMobileDrawer",
        display: "flex",
        flexDirection: "column"
    },
    menuButton: {
        float: "right"
    },
    emailLink: {
        color: theme.palette.link.main,
    },
    copyright: {
        color: theme.palette.primary.main,
        minWidth: theme.spacing(2)
    },
    placeholder: {
        height: theme.props.footer.main.height,
        display: "block",
    },
});

const Footer = ({ classes }) => {
    const [open, toggleOpen] = useCycle(false, true);
    const footerData = useFooterDataQuery();
    const isLoading = !isEmpty(footerData) && footerData.some(query => query.isLoading);

    const onDidChangeLocation = useCallback(() => {
        toggleOpen(0);
    }, [toggleOpen]);

    useLocationEffect(onDidChangeLocation);

    const isRoutableLink = useIsRoutableLink();

    if (isLoading) return null;

    const dictionary = !isEmpty(footerData[0]) && footerData[0]?.data?.dictionary;
    const supportDictionary = !isEmpty(footerData[1]) && footerData[1]?.data?.dictionary;

    const imprintLink = trim(dictionary?.imprintLink);
    const imprintLinkText = trim(dictionary?.imprintLinkText);
    const customerInfoPdf = dictionary?.customerInfoPdf;
    const customerInfoLink = trim(dictionary?.customerInfoLink);
    const customerInfoLinkText = trim(dictionary?.customerInfoLinkText);
    const dataProtectionPdf = dictionary?.dataProtectionPdf;
    const dataProtectionLink = trim(dictionary?.dataProtectionLink);
    const dataProtectionLinkText = trim(dictionary?.dataProtectionLinkText);
    const supportPhone1 = trim(supportDictionary?.supportPhone1);
    const phoneNumberText = trim(dictionary?.phoneNumberText);
    const email = trim(dictionary?.specificEmail ? dictionary?.specificEmail : supportDictionary?.supportMailAddress);
    const emailText = trim(dictionary?.orEmailText?.split("#")[1] ? dictionary?.orEmailText?.split("#")[1] : dictionary?.orEmailText);
    const extraLink1 = trim(dictionary?.extraLink1);
    const extraLink1Text = trim(dictionary?.extraLink1Text);
    const extraLink2 = trim(dictionary?.extraLink2);
    const extraLink2Text = trim(dictionary?.extraLink2Text);

    const MobileMenu = () => {
        return (
            <>
                <MuiList classes={{ root: classes.mobileDrawer }}>
                    <If condition={imprintLinkText && imprintLink}>
                        <MuiListItem
                            component={isRoutableLink(imprintLink) ? Link : "a"}
                            button
                            {...(isRoutableLink(imprintLink) ? { to: imprintLink } : { href: imprintLink, target: "_blank", rel: "noopener noreferrer" })}
                        >
                            <MuiListItemText classes={{ root: classes.mobileDrawerText }} primary={imprintLinkText} />
                        </MuiListItem>
                    </If>
                    <If condition={customerInfoLinkText && customerInfoLink}>
                        <MuiListItem
                            component={"a"}
                            button
                            {...(
                                {
                                    href: parsePlaceholderText(customerInfoLink, {
                                        customerInfoPdf
                                    }),
                                    target: "_blank",
                                    rel: "noopener noreferrer"
                                }
                            )}
                        >
                            <MuiListItemText classes={{ root: classes.mobileDrawerText }} primary={customerInfoLinkText} />
                        </MuiListItem>
                    </If>
                    <If condition={dataProtectionLinkText && dataProtectionLink}>
                        <MuiListItem
                            component={"a"}
                            button
                            {...(
                                {
                                    href: parsePlaceholderText(dataProtectionLink, {
                                        dataProtectionPdf
                                    }),
                                    target: "_blank",
                                    rel: "noopener noreferrer"
                                }
                            )}
                        >
                            <MuiListItemText classes={{ root: classes.mobileDrawerText }} primary={dataProtectionLinkText} />
                        </MuiListItem>
                    </If>
                    <If condition={supportPhone1}>
                        <MuiListItem
                            component={"a"}
                            button
                            {...({
                                href: `tel:${supportPhone1}`
                            })}
                        >
                            <MuiListItemText classes={{ root: classes.mobileDrawerText }} primary={phoneNumberText || supportPhone1} />
                        </MuiListItem>
                    </If>
                    <If condition={email}>
                        <MuiListItem
                            component={"a"}
                            button
                            {...({
                                href: "mailto:" + email,
                                target: "_blank",
                                rel: "noopener noreferrer"
                            })}
                        >
                            <MuiListItemText classes={{ root: classes.mobileDrawerText }} primary={emailText || email} />
                        </MuiListItem>
                    </If>
                    <If condition={extraLink1Text && extraLink1}>
                        <MuiListItem
                            component={isRoutableLink(extraLink1) ? Link : "a"}
                            button
                            {...(isRoutableLink(extraLink1) ? { to: extraLink1 } : { href: extraLink1, target: "_blank", rel: "noopener noreferrer" })}
                        >
                            <MuiListItemText classes={{ root: classes.mobileDrawerText }} primary={extraLink1Text} />
                        </MuiListItem>
                    </If>
                    <If condition={extraLink2Text && extraLink2}>
                        <MuiListItem
                            component={isRoutableLink(extraLink2) ? Link : "a"}
                            button
                            {...(isRoutableLink(extraLink2) ? { to: extraLink2 } : { href: extraLink2, target: "_blank", rel: "noopener noreferrer" })}
                        >
                            <MuiListItemText classes={{ root: classes.mobileDrawerText }} primary={extraLink2Text} />
                        </MuiListItem>
                    </If>
                </MuiList>
            </>
        );
    };

    return (
        <>
            <Hidden smDown>
                <Grid
                    component={"footer"}
                    container
                    className={classes.root}
                >
                    <Grid container className={classes.footerContent}>
                        <Grid item className={classes.brandAndYear}>
                            <Typography
                                variant="body2"
                            >© {dictionary?.brandName} {(new Date().getFullYear())}</Typography>
                        </Grid>
                        <Grid item className={classes.linksOnDesktop}>
                            <If condition={imprintLinkText && imprintLink}>
                                <Typography variant="button" className={classes.footerButton}>
                                    {isRoutableLink(imprintLink) ?
                                        <Link to={imprintLink}>
                                            {imprintLinkText}
                                        </Link> :
                                        <a href={imprintLink} target="_blank" rel="noopener noreferrer">
                                            {imprintLinkText}
                                        </a>}
                                    <span className={classes.spacingBorder}> | </span>
                                </Typography>
                            </If>
                            <If condition={customerInfoLinkText && customerInfoLink}>
                                <Typography variant="button" className={classes.footerButton}>
                                    <a href={parsePlaceholderText(customerInfoLink, {
                                        customerInfoPdf
                                    })}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >{customerInfoLinkText}
                                    </a>
                                    <span className={classes.spacingBorder}> | </span>
                                </Typography>
                            </If>
                            <If condition={dataProtectionLinkText && dataProtectionLink}>
                                <Typography variant="button" className={classes.footerButton}>
                                    <a href={parsePlaceholderText(dataProtectionLink, {
                                        dataProtectionPdf
                                    })}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >{dataProtectionLinkText}
                                    </a>
                                    <span className={classes.spacingBorder}> | </span>
                                </Typography>
                            </If>
                            <If condition={supportPhone1}>
                                <Typography variant="button" className={classes.footerButton}>
                                    <a href={`tel:${supportPhone1}`}>{phoneNumberText || supportPhone1}</a>
                                    <span className={classes.spacingBorder}> | </span>
                                </Typography>
                            </If>
                            <If condition={email}>
                                <Typography variant="button" className={classes.footerButton}>
                                    <a href={"mailto:" + (email)}
                                       target="_blank" rel="noopener noreferrer"
                                    >
                                        <span className={classes.emailLink}>
                                            {emailText || email}
                                        </span>
                                    </a>
                                </Typography>
                            </If>
                            <If condition={email && ((extraLink1Text && extraLink1) || (extraLink2Text && extraLink2))}>
                                <Typography variant="button" className={classes.footerButton}>
                                    <span className={classes.spacingBorder}> | </span>
                                </Typography>
                            </If>
                            <If condition={extraLink1Text && extraLink1}>
                                <Typography variant="button" className={classes.footerButton}>
                                    {isRoutableLink(extraLink1) ?
                                        <Link to={extraLink1}>{extraLink1Text}</Link> :
                                        <a href={extraLink1} target="_blank"
                                           rel="noopener noreferrer">{extraLink1Text}</a>}
                                    <span className={classes.spacingBorder}> | </span>
                                </Typography>
                            </If>
                            <If condition={extraLink2Text && extraLink2}>
                                <Typography variant="button" className={classes.footerButton}>
                                    {isRoutableLink(extraLink2) ?
                                        <Link to={extraLink2}>{extraLink2}</Link> :
                                        <a href={extraLink2} target="_blank"
                                           rel="noopener noreferrer">{extraLink2Text}</a>}
                                </Typography>
                            </If>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Fab
                    size={"small"}
                    aria-label="footer-menu"
                    onClick={toggleOpen}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    classes={{
                        root: classes.fabRoot
                    }}
                >
                    <MenuIcon/>
                </Fab>
                <AnimatePresence>
                    {open && (
                        <Grid
                            component={m.footer}
                            container
                            className={classes.root}
                            initial={{
                                translateY: "100%",
                            }}
                            animate={{
                                translateY: 0,
                                transition: { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] },
                            }}
                            exit={{
                                translateY: "100%",
                                transition: { delay: 0.1, duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] },
                            }}
                        >
                            <MobileMenu/>
                        </Grid>
                    )}
                </AnimatePresence>
            </Hidden>
            <span className={classes.placeholder}/>
        </>
    );
};

export default withStyles(styles, { name: "Footer" })(Footer);
