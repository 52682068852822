import { withEnabledCondition, withSessionScope } from "@hooks/higher-order-hooks";
import { useHomeLinksQuery } from "@hooks/home";
import { apiRequest } from "@services/api";
import { useQuery } from "react-query";
import type { UseQueryOptions, UseQueryResult } from "react-query";
import type { Policies } from "@core/application/policies/protocol";
import type { Response, HTTPError } from "superagent";

import get from "lodash/get";

export * from "@common/core/application/policies/hooks";

function usePoliciesQuery<TData = unknown, TError = HTTPError>(options?: UseQueryOptions<Response, TError, TData>): UseQueryResult<TData, TError> {
    const { data: url } = useHomeLinksQuery("access-policies");

    const useScopedQuery = withSessionScope(useQuery);
    const useConditionalQuery = withEnabledCondition(useScopedQuery, (enabled: boolean) => enabled && !!url);

    const queryKey = ["policies"];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/hal+json");

    return useConditionalQuery({ queryKey, queryFn, ...options });
}

/** @override */
export function useScreenServicePoliciesContentQuery(options?: UseQueryOptions<Response, HTTPError, Policies>): UseQueryResult<Policies> {
    const select: (response: Response) => Policies = (response: Response) => get(response, "body.content_", {});

    return usePoliciesQuery<Policies>({ ...options, select });
}
