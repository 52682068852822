import Color from "color";

function clamp(value, min = 0, max = 1) {
    return Math.min(Math.max(min, value), max);
}

function hexToRgb(color) {
    color = color.substr(1);

    const re = new RegExp(`.{1,${color.length >= 6 ? 2 : 1}}`, "g");
    let colors = color.match(re);

    if (colors && colors[0].length === 1) {
        colors = colors.map((n) => n + n);
    }

    return colors
        ? `rgb${colors.length === 4 ? "a" : ""}(${colors
            .map((n, index) => (index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000))
            .join(", ")})`
        : "";
}

function decomposeColor(color) {
    // Idempotent
    if (color.type) {
        return color;
    }

    if (color.charAt(0) === "#") {
        return decomposeColor(hexToRgb(color));
    }

    const marker = color.indexOf("(");
    const type = color.substring(0, marker);

    let values = color.substring(marker + 1, color.length - 1);
    let colorSpace;

    if (type === "color") {
        values = values.split(" ");
        colorSpace = values.shift();
        if (values.length === 4 && values[3].charAt(0) === "/") {
            values[3] = values[3].substr(1);
        }
    } else {
        values = values.split(",");
    }
    values = values.map((value) => parseFloat(value));

    return { type, values, colorSpace };
}

function recomposeColor(color) {
    const { type, colorSpace } = color;
    let { values } = color;

    if (type.includes("rgb")) {
        // Only convert the first 3 values to int (i.e. not alpha)
        values = values.map((n, i) => (i < 3 ? parseInt(n, 10) : n));
    } else if (type.includes("hsl")) {
        values[1] = `${values[1]}%`;
        values[2] = `${values[2]}%`;
    }
    if (type.includes("color")) {
        values = `${colorSpace} ${values.join(" ")}`;
    } else {
        values = `${values.join(", ")}`;
    }

    return `${type}(${values})`;
}

function alpha(color, value) {
    color = decomposeColor(color);
    value = clamp(value);

    if (color.type === "rgb" || color.type === "hsl") {
        color.type += "a";
    }
    if (color.type === "color") {
        color.values[3] = `/${value}`;
    } else {
        color.values[3] = value;
    }

    return recomposeColor(color);
}

export { Color, alpha, clamp, hexToRgb, decomposeColor, recomposeColor };
