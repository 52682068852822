import {useHomeDataQuery} from "@hooks/home";
import {apiRequest} from "@services/api";
import get from "lodash/get";
import {useMemo} from "react";
import {useQueries} from "react-query";

const defaultLinks = ["footer", "support-contact-data"]

const useFooterQuery = ({links = defaultLinks, homeData, ...options}) => {
    const queries = useMemo(() => {
        if(Array.isArray(links) && !!homeData) {
            return links.map((link, index) => {
                const url = get(homeData, `_links.${link}.href`);
                const effectiveOptions = {
                    ...options,
                    select: x => get(x, "body"),
                    enabled: (options?.enabled),
                };

                const queryFn = () => apiRequest
                    .get(url)
                    .set("Accept", "application/hal+json")
                    .set("Accept", "application/json")
                    .send();

                return {
                    queryKey: [ "app", "footer", `${link}_data`, {
                        index,
                        url
                    } ],
                    queryFn,
                    ...effectiveOptions
                };
            });
        }
        else {
            return []
        }
    }, [homeData, links, options]);

    return useQueries(queries);
};

export const useFooterDataQuery = options => {
    const {data: homeData, isLoading} = useHomeDataQuery()

    const select = response => get(response, "body");
    return useFooterQuery({ ...options, homeData, enabled: !isLoading, select });
};
