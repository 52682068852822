import Footer from "@components/Footer";
import React from 'react';

export const withFooter = Component => props => {

    return (
        <>
            <Component {...props} />
            <Footer {...props} />
        </>
    )
};

export default withFooter;
