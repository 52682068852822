export default {
    root: {},
    elevation1: {
        boxShadow: "none"
    },
    outlined: {
        border: "none",
        boxShadow: "none"
    },
};
