import React from "react";
import { withStyles } from "@material-ui/styles";
import styles from "@components/Link.styles";
import { Link as ReactRouterLink } from "react-router-dom";
import type { ComponentType, PropsWithChildren, FC } from "react";

export type Path = Readonly<{
    pathname: string
    search: string
    hash: string
}>;

export type LinkComponentProps = PropsWithChildren<Readonly<{
    to: string | Partial<Path>
    href: string | Partial<Path>
    classes: Record<string, string>
    [props: string]: unknown
}>>;

export type LinkComponent = ComponentType<LinkComponentProps>;

const Link: FC<LinkComponentProps> = React.forwardRef<HTMLAnchorElement, LinkComponentProps>(({ to, href, children, classes, ...props }, ref) => (
    <ReactRouterLink ref={ref} to={to || href} className={classes.link} {...props}>
        {children}
    </ReactRouterLink>
));

Link.displayName = "Link";

export default React.memo(withStyles(styles, { name: "Link" })(Link));
