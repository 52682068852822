import { createFacade } from "@utils/slots";
import type { UseQueryOptions, UseQueryResult } from "react-query";

export interface ProposalsFacade {
    useProposalsUrlQuery: (options?: UseQueryOptions) => UseQueryResult<string>
}

const { useFacade, FacadeProvider, withFacadeProvider } = createFacade<ProposalsFacade>({ name: "Proposals" });

export {
    useFacade as useProposalsFacade,
    FacadeProvider as ProposalsFacadeProvider,
    withFacadeProvider as withProposalsFacadeProvider
};
