import { type Route } from "@core/application/router/contributables";
import type { FC, PropsWithChildren } from "react";
import { createContext, useContext } from "react";

export interface GenerateRoutePathOptions {
    readonly paramsOverrides?: Record<string, unknown>
    readonly searchParamsOverrides?: Record<string, unknown>
    readonly keepPreviousSearch?: boolean
}

export type GenerateRoutePath = (routeOrRouteId: Route | string, options?: GenerateRoutePathOptions) => string;

export type GenerateRoutePathFactory = () => GenerateRoutePath;

const GenerateRoutePathContext = createContext<GenerateRoutePathFactory | undefined>(undefined);

export function useGenerateRoutePath() {
    const generateRoutePathFactory = useContext(GenerateRoutePathContext);

    if (generateRoutePathFactory) {
        return generateRoutePathFactory();
    }

    throw new Error("useGenerateRoutePath must be used within a GenerateRoutePathContext.");
}

type GenerateRoutePathProviderProps = PropsWithChildren<{
    readonly generateRoutePathFactory: GenerateRoutePathFactory
}>;

const GenerateRoutePathProvider: FC<GenerateRoutePathProviderProps> = ({ children, generateRoutePathFactory }) => <GenerateRoutePathContext.Provider value={generateRoutePathFactory}>
    {children}
</GenerateRoutePathContext.Provider>;

export default GenerateRoutePathProvider;
