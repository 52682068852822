import PropTypes from "prop-types";
import { LazyMotion as FRLazyMotion, domAnimation } from "framer-motion";

LazyMotion.propTypes = {
	children: PropTypes.node,
};

export default function LazyMotion({ children }) {
	return (
		<FRLazyMotion features={domAnimation}>
			{children}
		</FRLazyMotion>
	);
}

export const withLazyMotion = Component => props => (
    <LazyMotion>
        <Component {...props}/>
    </LazyMotion>
);
