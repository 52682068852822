import { TransitionEnter, TransitionExit } from "@components/transitions/utils/variants/transition";


export const Fade = (props) => {
    const distance = props?.distance ?? 2.5;
    const durationIn = props?.durationIn || 0.25;
    const durationOut = props?.durationOut || 0.125;
    const easeIn = props?.easeIn;
    const easeOut = props?.easeOut;

    return {
        in: {
            initial: { opacity: 0 },
            animate: { opacity: 1, transition: TransitionEnter },
            exit: { opacity: 0, transition: TransitionExit },
        },
        inUp: {
            initial: { y: distance, opacity: 0 },
            animate: { y: 0, opacity: 1, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { y: distance, opacity: 0, transition: TransitionExit({ durationOut, easeOut }) },
        },
        inDown: {
            initial: { y: -distance, opacity: 0 },
            animate: { y: 0, opacity: 1, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { y: -distance, opacity: 0, transition: TransitionExit({ durationOut, easeOut }) },
        },
        inLeft: {
            initial: { x: -distance, opacity: 0 },
            animate: { x: 0, opacity: 1, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { x: -distance, opacity: 0, transition: TransitionExit({ durationOut, easeOut }) },
        },
        inRight: {
            initial: { x: distance, opacity: 0 },
            animate: { x: 0, opacity: 1, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { x: distance, opacity: 0, transition: TransitionExit({ durationOut, easeOut }) },
        },


        out: {
            initial: { opacity: 1 },
            animate: { opacity: 0, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { opacity: 1, transition: TransitionExit({ durationOut, easeOut }) },
        },
        outUp: {
            initial: { y: 0, opacity: 1 },
            animate: { y: -distance, opacity: 0, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { y: 0, opacity: 1, transition: TransitionExit({ durationOut, easeOut }) },
        },
        outDown: {
            initial: { y: 0, opacity: 1 },
            animate: { y: distance, opacity: 0, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { y: 0, opacity: 1, transition: TransitionExit({ durationOut, easeOut }) },
        },
        outLeft: {
            initial: { x: 0, opacity: 1 },
            animate: { x: -distance, opacity: 0, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { x: 0, opacity: 1, transition: TransitionExit({ durationOut, easeOut }) },
        },
        outRight: {
            initial: { x: 0, opacity: 1 },
            animate: { x: distance, opacity: 0, transition: TransitionEnter({ durationIn, easeIn }) },
            exit: { x: 0, opacity: 1, transition: TransitionExit({ durationOut, easeOut }) },
        },
    };
};
