import React, { useEffect, useRef } from "react";
import { useIsLoggedIn } from "@bounded-context/iam/hooks/login";
import { useCurrentIdentityContractsDataQuery, useCurrentIdentityDataQuery } from "@bounded-context/iam/hooks/identity";
import If from "@components/If";
import Menu from "@components/menu/Menu";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { useMenuItemEntries } from "@core/application/menu/hooks";
import { useToggle } from "@hooks/utils";
import { useHeaderData } from "@hooks/header";
import withAll from "lodash/flowRight";
import { connect } from "react-redux";
import withRouter from "@components/withRouter";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withStyles } from "@material-ui/styles";
import { getSubtitle, getTitle } from "@ducks/header";
import { identity } from "@config/identity";
import { getGlobalResource } from "@ducks/application-lifecycle";
import Link from "@components/Link";

import styles from "@components/Header.styles";
import MenuItem from "@components/menu/MenuItem";

import SelectContractMenuItem from "@components/menu/SelectContractMenuItem";
import Stack from "@components/Stack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const MainMenu = ({ classes }) => {
    const isLoggedIn = useIsLoggedIn();
    const [toggle, { toggle: setToggle }] = useToggle();
    const anchorRef = useRef(null);

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    const menuEntries = useMenuItemEntries(MenuItem);

    const handleToggle = () => {
        setToggle();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setToggle(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setToggle(false);
        }
    }

    if (isLoggedIn) {
        return (
            <>
                <Button
                    aria-controls={toggle ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    ref={anchorRef}
                    color="inherit"
                    variant="text"
                    classes={{
                        root: classes.desktopUserPanelButton,
                    }}
                    onClick={handleToggle}
                    startIcon={<MenuIcon className={classes.userIcon}/>}
                >
                    {!sm && "Menü"}
                </Button>
                <Menu
                    id="menu"
                    anchorRef={anchorRef}
                    open={toggle}
                    handleClose={handleClose}
                    handleListKeyDown={handleListKeyDown}
                >
                    {menuEntries}
                </Menu>
            </>
        );
    }

    return (
        <Button
            component={Link}
            to={"/login"}
            size="small"
            color="inherit"
            variant="text"
            classes={{
                root: classes.desktopUserPanelButton,
            }}
        >
            {"Anmelden"}
        </Button>
    );
};

const ContractMenu = ({ classes }) => {
    const isLoggedIn = useIsLoggedIn();
    const [toggle, { toggle: setToggle }] = useToggle();
    const anchorRef = useRef(null);

    const { data: identity } = useCurrentIdentityDataQuery({ enabled: isLoggedIn });
    const { data: contractsData } = useCurrentIdentityContractsDataQuery({ enabled: isLoggedIn });
    const { content_: contracts } = contractsData || {};

    const handleToggle = () => {
        setToggle();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setToggle(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setToggle(false);
        }
    }

    if (isLoggedIn) {
        return (
            <>
                <If condition={identity}>
                    <Button
                        aria-controls={toggle ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        ref={anchorRef}
                        color="inherit"
                        variant="text"
                        classes={{
                            root: classes.contractMenuButton,
                            disabled: classes.disabled
                        }}
                        size={"small"}
                        onClick={handleToggle}
                        endIcon={contracts && contracts.length > 1 && <ArrowDropDownIcon className={classes.userIcon}/>}
                        disabled={!contracts || contracts.length <= 1}
                    >
                        <Typography variant={"body1"} className={classes.contractName}>
                            {identity && (identity?.name || identity?.login.email)}
                        </Typography>
                    </Button>
                </If>
                <If condition={contracts && contracts.length > 1}>
                    <Menu
                        id="menu"
                        anchorRef={anchorRef}
                        open={toggle}
                        handleClose={handleClose}
                        handleListKeyDown={handleListKeyDown}
                    >
                        {contracts && contracts.length > 1 && contracts.map((contract, i) => (
                            <SelectContractMenuItem
                                key={contract.contractId}
                                label={`Vertrag ${i + 1}`}
                                onChange={handleToggle}
                                {...contract}
                            />
                        ))}
                    </Menu>
                </If>
            </>
        );
    }

    return null;
};

// eslint-disable-next-line react/display-name
const Header = React.memo(({ headerProps, ...props }) => {
    const { classes } = props;

    const { showMenu = true } = headerProps;

    const { setHeaderData } = useHeaderData();

    React.useEffect(() => {
        setHeaderData({ visible: true });
        return () => setHeaderData(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const baseAppName = identity.name;
    document.title = baseAppName + (props.title ? ` - ${props.title}` : "") + (props.subtitle ? ` - ${props.subtitle}` : "");

    return (
        <Stack direction={"row"} className={classes.root}>
            <span className={classes.placeholder}/>
            <AppBar
                classes={{
                    root: classes.appbar
                }}
            >
                <Toolbar
                    classes={{
                        root: classes.toolbar
                    }}
                    disableGutters
                >
                    <Stack direction={"row"} className={classes.logoContainer}>
                        <Link
                            className={classes.logo}
                            style={{
                                backgroundImage: `url(${props.logo}), url(${props.logoResponsive})`,
                            }}
                            to={"/"}
                        >
                            <span/>
                        </Link>
                    </Stack>
                    <Stack
                        direction={"row"}
                        className={classes.pageTitle}
                    >
                        <Typography classes={{ root: classes.title }} align="center" color="inherit" component={"h2"}>
                            {props.title}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        className={classes.menuContainer}
                    >
                        <ContractMenu classes={classes}/>
                        <If condition={showMenu}>
                            <MainMenu classes={classes}/>
                        </If>
                    </Stack>
                </Toolbar>
            </AppBar>
        </Stack>
    );
});

const mapStateToProps = (state) => ({
    title: getTitle(state),
    subtitle: getSubtitle(state),
    logo: getGlobalResource(state) != null ? getGlobalResource(state).dictionary.logo : "",
    logoResponsive: getGlobalResource(state) != null ? getGlobalResource(state).dictionary.logoResponsive : "",
});

export default withAll(
    connect(mapStateToProps, null),
    withRouter,
    withStyles(styles, { name: "Header" })
)(Header);
