const styles = () => ({
    root: {
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
    }
});

export default styles;
