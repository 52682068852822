import "@theme/variables.css";

import shadows, { customShadows } from "@theme/shadows";
import shape from "@theme/shape";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import createTypography from "@material-ui/core/styles/createTypography";

import overrides from "@theme/overrides";
import { Color } from "@utils/color";

const primaryBase = blue;
const primary = primaryBase[500];
const secondary = pink.A400;

const uiTheme = createTheme({ shape, shadows: shadows.light });

export const palette = {
    ...uiTheme.palette,
    primary: {
        main: primary
    },
    secondary: {
        main: secondary
    },
    background: {
        default: "#fafafa",
        table: "#f9fafa",
    },
    grey: {
        50: "#f6f6f6",
        100: "#eeeeee",
        200: "#e0e0e0",
        300: "#dcdcdc",
        400: "#bdbdbd",
        500: "#8a8a8a",
        600: "#757575",
        700: "#565555",
        800: "#424242",
        900: "#333333"
    },
    text: {
        main: primary,
        primary,
        secondary
    },
    link: {
        main: primary,
    },
    contextInfoIcon: {
        main: uiTheme.palette.grey[800]
    },
    contextInfoHeader: {
        main: uiTheme.palette.grey[500]
    },
    contextInfoContent: {
        main: uiTheme.palette.grey[500]
    },
    positive: {
        main: "green"
    },
    negative: {
        main: "red"
    },
    info: {
        main: "#1890FF"
    }
};

const typography = createTypography(palette, {
    button: {
        fontSize: "1.25rem",
    },
    h1: {
        fontSize: "1.375rem",
        width: "100%"
    },
    h2: {
        fontSize: "1.27rem",
        width: "100%"
    },
    h3: {
        fontSize: "1.173rem",
        width: "100%"
    },
    h4: {
        fontSize: "1.083rem",
        width: "100%"
    },
    h5: {
        fontSize: "1rem",
        width: "100%"
    },
    h6: {
        fontSize: "0.923rem",
        width: "100%"
    },
    subtitle1: {
        fontSize: "0.853rem",
        width: "100%"
    },
    subtitle2: {
        fontSize: "0.8rem",
        width: "100%"
    },
    overline: {
        width: "100%"
    },
    dashboard: {
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: "calc(2rem * 0.05vw",
        letterSpacing: "0.43px",
        lineHeight: 1.1
    }
});

const zIndex = {
    ...uiTheme?.zIndex,
    tooltip: 1100,
};

export const theme = createTheme({
    ...uiTheme,
    palette,
    typography,
    overrides: {
        ...overrides,
        MeasurementsTable: {
            positive: {
                backgroundColor: uiTheme.palette.success.main
            },
            negative: {
                backgroundColor: uiTheme.palette.error.main
            },
        },
        MuiFormHelperText: {
            root: {
                ...typography.caption,
                margin: 0,
            }
        },
        MuiButton: {
            root: {
                width: "auto",
                borderRadius: 2
            },
            contained: {
                "&:hover": {
                    boxShadow: uiTheme.shadows[2],
                },
                "&:focus": {
                    boxShadow: uiTheme.shadows[2]
                },
                "&:active": {
                    boxShadow: uiTheme.shadows[2]
                }
            }
        },
        MuiCardHeader: {
            avatar: {
                marginRight: 0
            },
            content: {
                display: "flex",
                flexDirection: "column",
            }
        },
        MuiFormLabel: {
            root: {
                color: "inherit"
            }
        },
        MuiTypography: {
            root: {
                color: "inherit"
            },
            colorTextSecondary: {
                color: "inherit"
            }
        },
        HorizontalScrollList: {
            gridListTileContent: {
                margin: 0,
                padding: 0,
                borderRadius: uiTheme.shape.borderRadius,
            },
            gridListTile: {
                marginRight: uiTheme.spacing(4),
            },
        },
        Input: {
            root: {
                minHeight: 59.5,
            },
            outlinedLabel: {
                transform: ({ variant }) => variant === "outlined" && `translate(${theme.spacing(2)}px, ${theme.spacing(3.5)}px) scale(1)`,
                "&.MuiInputLabel-shrink": {
                    fontSize: "1rem",
                    transform: `translate(${uiTheme.spacing(1.75)}px, -6px) scale(0.75)`
                }
            },
        },
        AddressComponent: {
            phoneInputClass: {
                "&&": {
                    height: 59.5
                }
            }
        },
        PrivateNotchedOutline: {
            legendNotched: {
                fontSize: "0.75rem",
            }
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottom: "0px solid transparent",
                },
                "&:after": {
                    borderBottom: "0px solid transparent",
                },
                "&:hover:not($disabled):not($focused):not($error):before": {
                    borderBottom: "0px solid transparent",
                },
                "&$disabled:before": {
                    borderBottomStyle: "none"
                },
            },

        },
        SelectComponent: {
            root: {
                margin: uiTheme.spacing(0, 0),
                outline: "none",
            },
            label: {
                fontSize: "1rem",
            },
            select: {
                height: 59.5,
            },
            outlinedLabel: {
                transform: "translate(12px, 20px) scale(1)",
            }
        },
        IdentificationComponent: {
            datePicker: {
                margin: uiTheme.spacing(0, 0),
                "& > .MuiInputLabel-outlined": {
                    fontSize: "1rem",
                    transform: "translate(12px, 20px) scale(1)",
                },
                "& > .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                },
                "& > .MuiInputBase-root": {
                    height: 59.5,
                }
            },
            required: {
                display: "none",
            },
        },
        IntersectionObserverWrapper: {
            overflowMenu: {
                "& > .MuiIconButton-root": {
                    color: "inherit"
                }
            }
        },
        Performance: {
            numberFormat: {
                fontWeight: 400,
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: Color(palette.background.default).fade(0.25).toString()
            },
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1640
        }
    },
    props: {
        Input: {
            variant: "outlined",
            textAlign: "left",
            notched: true,
            shrink: true,
        },
        MyRisk: {
            hasFooter: true
        },
        MuiFormHelperText: {
            variant: "outlined",
        },
        icons: {
            filter: "hue-rotate(123.31deg) saturate(109.67%)",
            borderColor: "white"
        },
        links: {
            color: secondary,
            borderBottom: `1px solid ${secondary}`
        },
        header: {
            height: 50,
            textColor: "#fff",
            backgroundColor: primary,
        },
        footer: {
            main: {
                height: 50
            },
            mobile: {
                height: 56
            }
        },
        progressbar: {
            height: 7
        },
        charts: {
            backgroundColors: [primaryBase.A200, primaryBase.A400, primaryBase[100]]
        },
        shell: {
            application: {
                width: 1300
            },
        },
        wrapper: {
            get width() { return this.main.width + this.sidebar.left + this.sidebar.width; },
            main: {
                width: "var(--mainPanelWidth)"
            },
            sidebar: {
                width: 200,
                left: 55
            },
            investmentProfile: {
                width: "var(--mainPanelWidth)",
            }
        },
        banner: {
            backgroundColor: "transparent",
        },
        email: {
            backgroundColor: "transparent",
        }
    },
    shadows: shadows.light,
    customShadows: customShadows.light,
    zIndex,
    chart: {
        primary: palette.primary.main,
        secondary: palette.secondary.main,
        base: palette.text.main,
    }
});

export default theme;
