import { createContext, useContext } from "react";

const DictionaryContext = createContext();

const DictionaryProvider = ({ dictionary, children }) => {
    return <DictionaryContext.Provider value={dictionary}>
        {children}
    </DictionaryContext.Provider>;
};

const useDictionary = () => {
    return useContext(DictionaryContext);
};

export { DictionaryProvider, useDictionary };
