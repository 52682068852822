import { apiRequest } from "@services/api";

export type DictionaryResponse = Readonly<{
    default: Readonly<{
        dictionary: Record<string, unknown>
    }>
}>;

export type DictionaryRequest = () => Promise<DictionaryResponse>;

export interface DictionaryRequestOptions {
    providerId: "contentful" | "content-access"
    dictionaryId: string
}

export function createDictionaryRequest({ providerId, dictionaryId }: DictionaryRequestOptions): DictionaryRequest {
    return async function() {
        const publicUrl = process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL || "";

        return apiRequest
            .get(`${publicUrl}/border-proxy/screen-service/proxies/dictionaries/${providerId}/${dictionaryId.replace("/", ".")}`)
            .set("Accept", "application/json")
            .send()
            .then((response) => ({ default: response.body }));
    };
}
