export interface Policies {
    readonly bc?: {
        readonly customer_investment_strategy?: {
            readonly topics_enabled?: boolean
        }

        readonly fee_management?: {
            readonly contract_special_conditions_read?: boolean
        }

        readonly iam?: {
            readonly email_read?: boolean
        }

        readonly investment_profile?: {
            readonly access?: boolean
        }

        readonly onboarding?: {
            readonly access?: boolean
        }

        readonly payments?: {
            readonly access?: boolean
            readonly investment_plan?: boolean
            readonly withdrawal_self_service?: boolean
        }
    }
}

export const Policy = {
    ACCOUNTS_READ: "bc.accounts.read",
    ACCOUNTS_WRITE: "bc.accounts.write",
    ADVISORS_READ: "bc.advisors.read",
    ADVISORY_PROCESS_INIT: "bc.advisory_process.init",
    ADVISORY_PROCESS_PROXY_ACCESS: "bc.advisory_process.proxy_access",
    ADVISORY_PROCESS_READ: "bc.advisory_process.read",
    ADVISORY_PROCESS_WRITE: "bc.advisory_process.write",
    ADVISOR_IDENTIFICATIONS_WRITE: "bc.advisor_identifications.write",
    ADVISOR_TILE: "bc.advisors.read",
    AML_READ: "bc.aml.read",
    ASSET_MANAGEMENT_CONFIG_READ: "bc.asset_management.config_read",
    ASSET_MANAGEMENT_CONFIG_WRITE: "bc.asset_management.config_write",
    AUDIT_LOGGING_CREATE: "bc.audit_logging.create",
    AUDIT_LOGGING_EXPORT: "bc.audit_logging.export",
    AUDIT_LOGGING_GET_ALL: "bc.audit_logging.get_all",
    AVAILABLE_MODULES_READ: "bc.available_modules.read",
    BANK_IMPORT_EXPORT: "bc.bank_import.export",
    BANK_IMPORT_FILE_UPLOAD: "bc.bank_import.file_upload",
    BANK_IMPORT_READ: "bc.bank_import.read",
    BANK_IMPORT_TRIGGER_IMPORT: "bc.bank_import.trigger_import",
    BINDINGS_WEBHOOK_ACCESS: "bc.bindings.webhook_access",
    BINDINGS_WRITE: "bc.bindings.write",
    BUSINESS_CONSISTENCY_ACCESS: "bc.business_consistency.access",
    CAPTURE_PROFESSIONAL_STATUS: "bc.onboarding.capture_professional_status_enabled",
    CAPTURE_SEPA_MANDNATE_FOR_FEE_COLLECTION: "bc.onboarding.capture_sepa_mandate_for_fee_collection_enabled",
    CONTRACTS_EXPORT: "bc.contracts.export",
    CONTRACTS_READ: "bc.contracts.read",
    CONTRACTS_WRITE: "bc.contracts.write",
    CONTRACT_APPROVAL_READ: "bc.contract_approval.read",
    CONTRACT_APPROVAL_WRITE: "bc.contract_approval.write",
    CONTRACT_MANAGEMENT_ADVISOR_BINDINGS_READ: "bc.contract_management.advisor_bindings_read",
    CONTRACT_MANAGEMENT_ADVISOR_BINDINGS_WRITE: "bc.contract_management.advisor_bindings_write",
    CONTRACT_MANAGEMENT_ADVISOR_CONFIRMATION: "bc.contract_management.advisor_confirmation",
    CONTRACT_MANAGEMENT_BINDING_CACHE: "bc.contract_management.binding_cache",
    CONTRACT_MANAGEMENT_CANCELLATION_READ: "bc.contract_management.cancellation_read",
    CONTRACT_MANAGEMENT_CANCELLATION_WRITE: "bc.contract_management.cancellation_write",
    CONTRACT_MANAGEMENT_CONTRACTS_FULL_SCOPE_READ: "bc.contract_management.contracts_full_scope_read",
    CONTRACT_MANAGEMENT_CONTRACTS_READ: "bc.contract_management.contracts_read",
    CONTRACT_MANAGEMENT_CUSTOMER_CONFIRMATION: "bc.contract_management.customer_confirmation",
    CONTRACT_MANAGEMENT_CUSTOMER_DELEGATION: "bc.contract_management.customer_delegation",
    CONTRACT_MANAGEMENT_EXPORT: "bc.contract_management.export",
    CONTRACT_MANAGEMENT_PROCESS_ACCESS: "bc.contract_management.process_access",
    CONTRACT_MANAGEMENT_PROXY_RELATION_MANAGE: "bc.contract_management.proxy_relation_manage",
    CONTRACT_MANAGEMENT_PROXY_RELATION_READ: "bc.contract_management.proxy_relation_read",
    CONTRACT_MANAGEMENT_SEPA_EXPORT: "bc.contract_management.sepa_export",
    CONTRACT_MANAGEMENT_TERMINATE: "bc.contract_management.terminate",
    CONTRACT_SIGNING_PROCESS_READ: "bc.contract_signing_process.read",
    CONTRACT_SIGNING_PROCESS_WRITE: "bc.contract_signing_process.write",
    CONTRACT_SPECIAL_CONDITIONS_READ: "bc.fee_management.contract_special_conditions_read",
    CONTRACT_TERMINATION_READ: "bc.contract_termination.read",
    CONTRACT_TERMINATION_WRITE: "bc.contract_termination.write",
    CUSTODIAN_ACCOUNTS_EXPORT: "bc.custodian_accounts.export",
    CUSTODIAN_ACCOUNTS_OPENING_BAADER_WRITE: "bc.custodian_accounts_opening_baader.write",
    CUSTODIAN_ACCOUNTS_OPENING_FNZ_WRITE: "bc.custodian_accounts_opening_fnz.write",
    CUSTODIAN_ACCOUNTS_OPENING_INTENTION_READ: "bc.custodian_accounts_opening_intention.read",
    CUSTODIAN_ACCOUNTS_OPENING_INTENTION_WRITE: "bc.custodian_accounts_opening_intention.write",
    CUSTODIAN_ACCOUNTS_OPENING_PROCESS_CREATE: "bc.custodian_accounts_opening_process.create",
    CUSTODIAN_ACCOUNTS_OPENING_PROCESS_READ: "bc.custodian_accounts_opening_process.read",
    CUSTODIAN_ACCOUNTS_OPENING_PROCESS_WRITE: "bc.custodian_accounts_opening_process.write",
    CUSTODIAN_ACCOUNTS_READ: "bc.custodian_accounts.read",
    CUSTODIAN_ACCOUNTS_WRITE: "bc.custodian_accounts.write",
    CUSTOMER_INVESTMENT_STRATEGY_API_ACCESS: "bc.customer_investment_strategy.api_access",
    CUSTOMER_INVESTMENT_STRATEGY_CUSTOMER_INVESTMENT_STRATEGIES_EXPORT: "bc.customer_investment_strategy.customer_investment_strategies_export",
    CUSTOMER_INVESTMENT_STRATEGY_CUSTOMER_INVESTMENT_STRATEGIES_IMPORT: "bc.customer_investment_strategy.customer_investment_strategies_import",
    CUSTOMER_INVESTMENT_STRATEGY_CUSTOMER_PREFERENCES_IMPORT: "bc.customer_investment_strategy.customer_preferences_import",
    CUSTOMER_INVESTMENT_STRATEGY_INVESTMENT_PLANS_IMPORT: "bc.customer_investment_strategy.investment_plans_import",
    CUSTOMER_INVESTMENT_STRATEGY_INVESTMENT_QUOTAS_EXPORT: "bc.customer_investment_strategy.investment_quotas_export",
    CUSTOMER_INVESTMENT_STRATEGY_LEGALLY_ACTIVATE: "bc.customer_investment_strategy.legally_activate",
    CUSTOMER_INVESTMENT_STRATEGY_REINVESTMENT_PLAN_READ: "bc.customer_investment_strategy.reinvestment_plan_read",
    CUSTOMER_INVESTMENT_STRATEGY_REINVESTMENT_PLAN_WRITE: "bc.customer_investment_strategy.reinvestment_plan_write",
    CUSTOMER_INVESTMENT_STRATEGY_REINVESTMENT_RATES_EXPORT: "bc.customer_investment_strategy.reinvestment_rates_export",
    CUSTOMER_INVESTMENT_STRATEGY_TOPICS_ACCESS: "bc.customer_investment_strategy.topics_access",
    CUSTOMER_MANAGEMENT_EXPORT: "bc.customer_management.export",
    CUSTOMER_MANAGEMENT_SEARCH: "bc.customer_management.search",
    CUSTOMER_MANAGEMENT_UPDATE: "bc.customer_management.update",
    DATA_ANALYSES_ACCESS: "bc.data_analyses.access",
    DECLARATION_OF_CONSENT_TASK_OFFLINE_READ: "bc.declaration_of_consent_task.read",
    DECLARATION_OF_CONSENT_TASK_OFFLINE_WRITE: "bc.declaration_of_consent_task.write",
    DECLARATION_OF_CONSENT_TASK_READ: "bc.declaration_of_consent_task.read",
    DECLARATION_OF_CONSENT_TASK_WRITE: "bc.declaration_of_consent_task.write",
    DOCUMENTS_READ: "bc.documents.read",
    DOCUMENTS_WRITE: "bc.documents.write",
    DOCUMENT_FOLDER_READ: "bc.document_folder.read",
    DOCUMENT_FOLDER_UPDATE: "bc.document_folder.update",
    EMAIL_READ: "bc.iam.email_read",
    EU_SUSTAINABILITY_REGULATION_EX_POST_REPORT_CONFIGURE: "bc.eu_sustainability_regulation.ex_post_report_configure",
    EU_SUSTAINABILITY_REGULATION_EX_POST_REPORT_CREATE: "bc.eu_sustainability_regulation.ex_post_report_create",
    EVENT_ROUTER_SUBSCRIBE: "bc.event_router.subscribe",
    FEE_MANAGEMENT_CONTRACT_FEE_API: "bc.fee_management.contract_fee_api",
    FEE_MANAGEMENT_CONTRACT_SPECIAL_CONDITIONS_READ: "bc.fee_management.contract_special_conditions_read",
    FEE_MANAGEMENT_CONTRACT_SPECIAL_CONDITIONS_WRITE: "bc.fee_management.contract_special_conditions_write",
    FEE_MANAGEMENT_EXPORT: "bc.fee_management.export",
    IAM: "bc.iam.read",
    IAM_CREATE: "bc.iam.create",
    IAM_DEVELOPMENT_SUPPORT: "bc.iam.development_support",
    IAM_EMAIL_CHANGE: "bc.iam.email_change",
    IAM_EMAIL_READ: "bc.iam.email_read",
    IAM_EXPORT: "bc.iam.export",
    IAM_MANAGE: "bc.iam.manage",
    IAM_READ: "bc.iam.read",
    IAM_READ_ALL: "bc.iam.read_all",
    IAM_READ_POLICY_MATRIX: "bc.iam.read_policy_matrix",
    IAM_STAFF_USER_DELETE: "bc.iam.staff_user_delete",
    IAM_STAFF_USER_EXPORT: "bc.iam.staff_user_export",
    IAM_STAFF_USER_IMPORT: "bc.iam.staff_user_import",
    IAM_STAFF_USER_READ: "bc.iam.staff_user_read",
    IAM_TOTP_MANAGEMENT: "bc.iam.totp_management",
    IAM_TOTP_SUPPORT: "bc.iam.totp_support",
    IDENTIFICATIONS_EXPORT: "bc.identifications.export",
    IDENTIFICATIONS_READ: "bc.identifications.read",
    IDENTIFICATIONS_TASKS_WRITE: "bc.identification_tasks.write",
    IDENTIFICATION_TASKS_READ: "bc.identification_tasks.read",
    IDENTIFICATION_TASKS_READ_ALL: "bc.identification_tasks.read_all",
    IDENTIFICATION_TASKS_READ_UUIDS: "bc.identification_tasks.read_uuids",
    IDENTIFICATION_TASKS_WRITE: "bc.identification_tasks.write",
    IDENTITY_MAPPINGS: "bc.identity-mappings.read",
    IDENTITY_MAPPINGS_EXPORT: "bc.identity_mappings.export",
    IDENTITY_MAPPINGS_READ: "bc.identity_mappings.read",
    IDENTITY_MAPPINGS_WRITE: "bc.identity_mappings.write",
    INVESTMENTS_EXPORT: "bc.investments.export",
    INVESTMENTS_READ: "bc.investments.read",
    INVESTMENTS_READ_ALL: "bc.investments.read_all",
    INVESTMENTS_WRITE: "bc.investments.write",
    INVESTMENT_AMOUNT_READ: "bc.investment_amount.read",
    INVESTMENT_AMOUNT_WRITE: "bc.investment_amount.write",
    INVESTMENT_LIST_READ: "bc.investment_list.read",
    INVESTMENT_PROFILE_ACCESS: "bc.investment_profile.access",
    INVESTMENT_PROFILE_GENERIC_QUESTIONNAIRE: "bc.investment_profile.generic_questionnaire",
    INVESTMENT_PROFILE_HISTORY: "bc.investment_profile.history",
    INVESTMENT_PROFILE_INVESTMENT_PROFILES_EXPORT: "bc.investment_profile.investment_profiles_export",
    INVESTMENT_PROFILE_LEGALLY_ACTIVATE: "bc.investment_profile.legally_activate",
    INVESTMENT_PROFILE_READ: "bc.investment_profile.read",
    INVESTMENT_PROFILE_VALIDITY_STATE_UPDATE: "bc.investment_profile.validity_state_update",
    INVESTMENT_PROJECTIONS_READ: "bc.investment_projections.read",
    KYC_PROCESS_ACCESS: "bc.kyc.process_access",
    MARKETING_PROMOTION_CAMPAIGN_MANAGE: "bc.marketing.promotion_campaign_manage",
    MARKETING_PROMOTION_CAMPAIGN_READ: "bc.marketing.promotion_campaign_read",
    ONBOARDING_ACCESS: "bc.onboarding.access",
    ONBOARDING_BECOME_CUSTOMER_PROCESS_EXPORT: "bc.onboarding.become_customer_process_export",
    ONBOARDING_BECOME_CUSTOMER_PROCESS_START: "bc.onboarding.become_customer_process_start",
    ONBOARDING_CAPTURE_PROFESSIONAL_STATUS_ENABLED: "bc.onboarding.capture_professional_status_enabled",
    ONBOARDING_CAPTURE_SEPA_MANDATE_FOR_FEE_COLLECTION_ENABLED: "bc.onboarding.capture_sepa_mandate_for_fee_collection_enabled",
    OSPLUS_MANAGE: "bc.osplus.manage",
    OSPLUS_READ: "bc.osplus.read",
    OSPLUS_WRITE: "bc.osplus.write",
    PAYMENTS_ACCESS: "bc.payments.access",
    PAYMENTS_ACCESS_BANK_TRANSFER: "bc.payments.access_bank_transfer",
    PAYMENTS_ACCESS_SEPA: "bc.payments.access_sepa",
    PAYMENTS_ACCESS_WITHDRAWAL: "bc.payments.access_withdrawal",
    PAYMENTS_INVESTMENT_PLAN: "bc.payments.investment_plan",
    PAYMENTS_READ: "bc.payments.read",
    PAYMENTS_REQUESTS: "bc.payments.requests",
    PAYMENTS_REQUESTS_EXPORT: "bc.payments.requests_export",
    PAYMENTS_REQUESTS_IMPORT: "bc.payments.requests_import",
    PAYMENTS_WITHDRAWAL_REQUEST: "bc.payments.withdrawal_request",
    PAYMENT_PLANS_ADVISORY_SAVINGS_PLAN_READ: "bc.payment_plans.advisory_savings_plan_read",
    PAYMENT_PLANS_CANCEL: "bc.payment_plans.cancel",
    PAYMENT_PLANS_CREATE: "bc.payment_plans.create",
    PAYMENT_PLANS_EXPORT: "bc.payment_plans.export",
    PAYMENT_PLANS_READ: "bc.payment_plans.read",
    PERSONS_ABSTRACT_CREATE: "bc.persons.abstract_create",
    PERSONS_ABSTRACT_EXPORT: "bc.persons.abstract_export",
    PERSONS_ABSTRACT_READ: "bc.persons.abstract_read",
    PERSONS_ABSTRACT_READ_ALL: "bc.persons.abstract_read_all",
    PERSONS_ABSTRACT_WRITE: "bc.persons.abstract_write",
    PERSONS_LEGAL_CREATE: "bc.persons.legal_create",
    PERSONS_LEGAL_EXPORT: "bc.persons.legal_export",
    PERSONS_LEGAL_READ: "bc.persons.legal_read",
    PERSONS_LEGAL_WRITE: "bc.persons.legal_write",
    PERSONS_NATURAL_CREATE: "bc.persons.natural_create",
    PERSONS_NATURAL_EXPORT: "bc.persons.natural_export",
    PERSONS_NATURAL_READ: "bc.persons.natural_read",
    PERSONS_NATURAL_WRITE: "bc.persons.natural_write",
    PERSON_IDENTIFICATION_IDENTITY_RECORD_EXPORT: "bc.person_identification.identity_record_export",
    POLICY_HOOKS_CHECK_PATH: "bc.policy_hooks.check_path",
    POLICY_HOOKS_CHECK_QUERY: "bc.policy_hooks.check_query",
    POLICY_HOOKS_EXECUTE: "bc.policy_hooks.execute",
    PORTFOLIO_ANALYSIS_ANALYSES: "bc.portfolio_analysis.analyses",
    PORTFOLIO_ANALYSIS_BALANCE: "bc.portfolio_analysis.balance",
    PORTFOLIO_ANALYSIS_BALANCE_COMPARISON: "bc.portfolio_analysis.balance_comparison",
    PORTFOLIO_ANALYSIS_CONTRACTS_KPIS: "bc.portfolio_analysis.contracts_kpis",
    PORTFOLIO_ANALYSIS_CUSTOMER_HOLDINGS_EXPORT: "bc.portfolio_analysis.customer_holdings_export",
    PORTFOLIO_ANALYSIS_ORDINARY_INCOME_IN_YEAR: "bc.portfolio_analysis.ordinary_income_in_year",
    PORTFOLIO_ANALYSIS_PMS_API: "bc.portfolio_analysis.pms_api",
    PORTFOLIO_ANALYSIS_PMS_TRANSACTIONS_EXPORT: "bc.portfolio_analysis.pms_transactions_export",
    PORTFOLIO_ANALYSIS_PMS_VALUES_EXPORT: "bc.portfolio_analysis.pms_values_export",
    PORTFOLIO_ANALYSIS_PORTFOLIO_DEVELOPMENT: "bc.portfolio_analysis.portfolio_development",
    PORTFOLIO_ANALYSIS_PROFIT: "bc.portfolio_analysis.profit",
    PORTFOLIO_ANALYSIS_PROFIT_AND_PERFORMANCE: "bc.portfolio_analysis.profit_and_performance",
    PORTFOLIO_ANALYSIS_TRANSACTIONS: "bc.portfolio_analysis.transactions",
    PORTFOLIO_ANALYSIS_VALUE_PRESERVATION: "bc.portfolio_analysis.value_preservation",
    PORTFOLIO_MANAGEMENT_EXECUTE: "bc.portfolio_management.execute",
    PORTFOLIO_MANAGEMENT_INSTRUCTIONS_READ: "bc.portfolio_management.instructions_read",
    PORTFOLIO_MANAGEMENT_INSTRUCTIONS_WRITE: "bc.portfolio_management.instructions_write",
    POSTBOX_BULK_IMPORT: "bc.postbox.bulk_import",
    POSTBOX_FULL_DOWNLOAD: "bc.postbox.full_download",
    POSTBOX_FULL_EXPORT: "bc.postbox.full_export",
    POSTBOX_READ: "bc.postbox.read",
    POSTBOX_SINGLE_IMPORT: "bc.postbox.single_import",
    POSTBOX_WRITE: "bc.postbox.write",
    PRECONTRACTUAL_INFORMATION_DELIVER: "bc.precontractual_information.deliver",
    PRODUCT_ORCHESTRATION_CONTRACT_IMPORT_ACCESS: "bc.product_orchestration.contract_import.access",
    PRODUCT_ORCHESTRATION_CONTRACT_IMPORT_DEVELOPMENT_ACCESS: "bc.product_orchestration.contract_import.development_access",
    REPORTING_ACQUISITION_REPORT: "bc.reporting.acquisition_report",
    REPORTING_CREATE: "bc.reporting.create",
    ROBO_ADVISOR_READ: "bc.robo_advisor.read",
    SALES_ORGANIZATIONS_EXPORT: "bc.sales_organizations.export",
    SALES_ORGANIZATIONS_READ: "bc.sales_organizations.read",
    SALES_ORGANIZATIONS_SALES_ORGANIZATION_IMPORT: "bc.sales_organizations.sales_organization_import",
    SCENARIO_ANALYSIS_SIMULATION_READ: "bc.scenario_analysis.simulation_read",
    SCENARIO_ANALYSIS_SIMULATION_UPDATE: "bc.scenario_analysis.simulation_update",
    SEPA_MANDATE_EXPORT: "bc.sepa_mandate.export",
    SEPA_MANDATE_READ: "bc.sepa_mandate.read",
    SEPA_MANDATE_WRITE: "bc.sepa_mandate.write",
    TAGGING_READ: "bc.tagging.read",
    TARGET_ALLOCATION_PROJECTIONS: "bc.target_allocation.projections",
    TOPICS_ACCESS: "bc.customer_investment_strategy.topics_access",
    TOP_HOLDINGS_ACCESS: "bc.top_holdings.access",
    TOP_HOLDINGS_ASSET_TO_HOLDING_ASSET_MAPPINGS_EXPORT: "bc.top_holdings.asset_to_holding_asset_mappings_export",
    TOP_HOLDINGS_ASSET_TO_HOLDING_ASSET_MAPPINGS_IMPORT: "bc.top_holdings.asset_to_holding_asset_mappings_import",
    TOP_HOLDINGS_HOLDING_ASSETS_EXPORT: "bc.top_holdings.holding_assets_export",
    TOP_HOLDINGS_HOLDING_ASSETS_IMPORT: "bc.top_holdings.holding_assets_import",
    TOTP_MANAGE: "bc.iam.totp_management",
    TOTP_SUPPORT: "bc.iam.totp_support",
    USER_MANAGEMENT_DELETE: "bc.user_management.delete"
};
