import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

import { Container, Fade } from "@components/transitions/utils/variants";


MotionContainer.propTypes = {
    action: PropTypes.bool,
    animate: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

const defaultTransition = Fade().in;


export const withMotionContainer = (Component) => ({ variants = defaultTransition, ...props }) => {
    return (
        <MotionContainer variants={variants}>
            <Component {...props} />
        </MotionContainer>
    );
};

export default function MotionContainer({ animate, action = false, children, ...other }) {
    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={Container()}
            style={{
                height: "100%",
                width: "100%",
                flexGrow: 1,
            }}
            {...other}
        >
            {children}
        </motion.div>
    );
}
