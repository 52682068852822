import { createFacade } from "@utils/slots";
import type { UseQueryOptions, UseQueryResult } from "react-query";

export interface TransactionsFacade {
    useTransactionsUrlQuery: (options?: UseQueryOptions) => UseQueryResult<string>
}

const { useFacade, FacadeProvider, withFacadeProvider } = createFacade<TransactionsFacade>({ name: "Transactions" });

export {
    useFacade as useTransactionsFacade,
    FacadeProvider as TransactionsFacadeProvider,
    withFacadeProvider as withTransactionsFacadeProvider
};
