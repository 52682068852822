import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import GlobalStyles from "@theme/global";

import { create } from "jss";
import jssPresetDefault from "jss-preset-default";

import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";

import theme from "@theme";

const jss = create({
    plugins: [
        ...jssPresetDefault().plugins,
    ]
});

export default function ThemeProvider({ theme, children }) {
    return (
        <StylesProvider jss={jss}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <GlobalStyles/>
                {children}
            </MuiThemeProvider>
        </StylesProvider>
    );
}

export const withThemeProvider = (Component) => (props) => (
    <ThemeProvider theme={theme}>
        <Component {...props}/>
    </ThemeProvider>
);
