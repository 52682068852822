import Modal, { ModalContent, ModalFooter, ModalHeader } from "@components/Modal";
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ButtonComponent from "@components/ButtonComponent";
import { useHookImplementation } from "@providers/ImplementationsProvider";
import { useCustomerSupportContactDictionaryQuery } from "@bounded-context/follow-up-communication/hooks/support-contact-data";

const styles = (theme) => ({
    dialogRoot: {
        zIndex: `${theme.zIndex.appBar - 1}!important`,
        padding: "35px"
    },
    dialogContainer: {
        padding: "35px"
    },
    dialogContent: {
        paddingBottom: "30px"
    },
    dialogText: {
        paddingBottom: "15px"
    },
    dialogErrorText: {
        color: theme.palette.error.main,
    },
    dialogEscapeIcon: {
        right: 0,
        position: "absolute"
    },
    dialogRefreshButtonUnknownError: {
        justifyContent: "center",
        marginBottom: theme.spacing(2)
    },
    contactDetailsContainer: {
        width: "100%",
        alignSelf: "center",
        "& span:nth-child(2)": {
            display: "none"
        },
        [theme.breakpoints.up("sm")]: {
            width: "65%",
            display: "flex",
            justifyContent: "space-evenly",
            "& span:nth-child(2)": {
                display: "block"
            },
        },
    },
    secondText: {},
    secondSupportPhoneNumber: {}
});

const DialogUnknownError = React.forwardRef((props, ref) => {
    const {
        classes, dict, hideModalOnClick = () => { }, showModal = true
    } = props;

    const { data: supportDictionary } = useHookImplementation(useCustomerSupportContactDictionaryQuery)();

    return (
        <Modal
            open={showModal}
            handleModal={hideModalOnClick}
            maxWidth={"sm"}
            ref={ref}
            BackdropComponent={() => { }}
            classes={{
                root: classes.dialogRoot
            }}
        >
            <ModalHeader
                classes={{
                    header: classes.modalHeader
                }}
            >
                <Typography variant="h2"
                    className={classes.dialogErrorText}>{dict?.errorDialogUnknownTitle}</Typography>
            </ModalHeader>
            <ModalContent
                classes={{
                    content: classes.modalContent
                }}
            >
                <Grid container justifyContent={"center"}>
                    <Grid item className={classes.dialogContent}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {dict?.errorDialogUnknownContentTextFirst}
                        </Typography>
                        <Typography variant="body1" className={classes.secondText}>
                            {dict?.errorDialogUnknownContentTextSecond}
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.contactDetailsContainer} direction={"column"} xs={4}
                        alignItems={"center"}>
                        <Typography component="span" variant="subtitle1" className={classes.secondSupportPhoneNumber}>
                            {supportDictionary?.supportPhone2}
                        </Typography>
                        <Typography component="span" variant="subtitle1">
                            {supportDictionary?.supportPhone1}
                        </Typography>
                    </Grid>
                    <Grid container item justifyContent="center">
                        <ButtonComponent
                            variant="text"
                            size="small"
                            href={`mailto:${supportDictionary?.supportMailAddress}`}
                            className="underline"
                            id={"supportMailAddress"}
                        >
                            {supportDictionary?.supportMailAddress}
                        </ButtonComponent>
                    </Grid>
                </Grid>
            </ModalContent>
            <ModalFooter
                classes={{
                    actions: classes.dialogRefreshButtonUnknownError
                }}
            >
                <ButtonComponent
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                    id={"reload"}
                >
                    {dict?.errorDialogButtonLabel || "Seite neu laden"}
                </ButtonComponent>
            </ModalFooter>
        </Modal>
    );
});

export default withStyles(styles, { name: "DialogUnknownError" })(DialogUnknownError);
