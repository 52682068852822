import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Modal, { ModalContent, ModalFooter, ModalHeader } from "@components/Modal";
import { withStyles } from "@material-ui/styles";
import ButtonComponent from "@components/ButtonComponent";

const styles = theme => ({
    dialogRoot: {
        zIndex: `${theme.zIndex.appBar - 1}!important`,
        padding: "35px"
    },
    dialogContainer: {
        padding: "35px"
    },
    dialogContent: {
        paddingBottom: "30px"
    },
    dialogErrorText: {
        color: theme.palette.error.main,
    },
    dialogEscapeIcon: {
        right: 0,
        position: "absolute"
    },
    dialogRefreshButtonNoConnection: {
        alignSelf: "flex-end",
    }
});

const DialogNoConnection = React.forwardRef((props, ref) => {
    const {
        classes, dict, hideModalOnClick = () => { }, showModal = true
    } = props;

    return (
        <Modal
            open={showModal}
            handleModal={hideModalOnClick}
            maxWidth={"sm"}
            ref={ref}
            BackdropComponent={() => { }}
            classes={{
                root: classes.dialogRoot
            }}
        >
            <ModalHeader
                classes={{
                    header: classes.modalHeader
                }}
            >
                <Typography variant="h2" className={classes.dialogErrorText}>{dict?.errorDialogUnknownTitle}</Typography>
            </ModalHeader>
            <ModalContent
                classes={{
                    content: classes.modalContent
                }}
            >
                <Grid container justifyContent={"center"}>
                    <Grid item className={classes.dialogContent}>
                        <Typography variant="body1">
                            {dict?.errorDialogNoConnectionContentText}
                        </Typography>
                    </Grid>
                </Grid>
            </ModalContent>
            <ModalFooter
                classes={{
                    actions: classes.dialogRefreshButtonNoConnection
                }}
            >
                <ButtonComponent
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                    id={"reload"}
                >
                    {dict?.errorDialogButtonLabel}
                </ButtonComponent>
            </ModalFooter>
        </Modal>
    );
});

export default withStyles(styles)(DialogNoConnection);
