import palette from "@theme/palette";
import color from "color";

export default {
    colorAction: {
        color: color(palette.primary.main).fade(0.3).toString(),
    },
    fontSizeLarge: {
        fontSize: 48
    }
};
