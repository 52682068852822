import get from 'lodash/get';

import { getPath, putAnswers, getStepPersonalData, getPersonalDataStepScreen, completePersonalData } from '@services/api.service';
import { actions as progress } from '@ducks/progress';
import { actions as header } from '@ducks/header';
import { actions as stepMetaActions } from '@ducks/step-meta';
import { batchActions } from 'redux-batched-actions';

export const types = {
    PERSONAL_DATA_LOAD_STEP_SUCCESS: 'PERSONAL_DATA/LOAD_STEP_SUCCESS',
    PERSONAL_DATA_LOAD_STEP_FAIL: 'PERSONAL_DATA/LOAD_STEP_FAIL',
    PERSONAL_DATA_STORE_ANSWERS: 'PERSONAL_DATA/STORE_ANSWERS',
    PERSONAL_DATA_SAVE_ANSWER: 'PERSONAL_DATA/SAVE_ANSWER',

    PERSONAL_DATA_ADD_TAX_LAND: 'PERSONAL_DATA/ADD_TAX_LAND',
    PERSONAL_DATA_REMOVE_TAX_LAND: 'PERSONAL_DATA/REMOVE_TAX_LAND',
    PERSONAL_DATA_EDIT_TAX_LAND: 'PERSONAL_DATA/EDIT_TAX_LAND',

    PERSONAL_DATA_REMOVE_CAPITAL_SOURCES: 'PERSONAL_DATA/REMOVE_CAPITAL_SOURCES',
    PERSONAL_DATA_ADD_CAPITAL_SOURCES: 'PERSONAL_DATA/ADD_CAPITAL_SOURCES',

    PERSONAL_DATA_STEP_ERROR: 'PERSONAL_DATA/STEP_ERROR',
    PERSONAL_DATA_HIDE_TOAST: 'PERSONAL_DATA/HIDE_TOAST'
};

export const initialState = {
    currentStep: null,
    data: null,
    error: null,
    stepError: null,
    toastVisible: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.PERSONAL_DATA_LOAD_STEP_SUCCESS: {
            const newState = { ...state, currentStep: action.step, error: null };
            if(action.clearValidationErrors) {
              newState.stepError = null;
              newState.toastVisible = false;
            }
            return newState;
        }
        case types.PERSONAL_DATA_LOAD_STEP_FAIL:
            return { ...state, currentStep: null, error: action.error };
        case types.PERSONAL_DATA_ADD_TAX_LAND:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        taxData: {
                            ...state.data.data.taxData,
                            taxDataPerLandList: [
                                ...state.data.data.taxData.taxDataPerLandList,
                                {
                                    taxId: null,
                                    taxLand: 'DE',
                                    taxLandVisualRepresentation: '',
                                    noTaxIdInTaxLand: false
                                }
                            ]
                        }
                    }
                }
            };

        case types.PERSONAL_DATA_REMOVE_TAX_LAND:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        taxData: {
                            ...state.data.data.taxData,
                            taxDataPerLandList: [
                                ...state.data.data.taxData.taxDataPerLandList.slice(0, action.payload.index),
                                ...state.data.data.taxData.taxDataPerLandList.slice(action.payload.index + 1)
                            ]
                        }
                    }
                }
            }
        case types.PERSONAL_DATA_EDIT_TAX_LAND:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        taxData: {
                            ...state.data.data.taxData,
                            taxDataPerLandList: [
                                ...state.data.data.taxData.taxDataPerLandList.map((e, i) =>
                                    action.payload.index === i ? { ...e, [action.payload.field]: action.payload.value } : e
                                )
                            ]
                        }
                    }
                }
            }
        case types.PERSONAL_DATA_ADD_CAPITAL_SOURCES:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        moneyLaunderingPrevention: {
                            ...state.data.data.moneyLaunderingPrevention,
                            capitalSources: [
                                ...state.data.data.moneyLaunderingPrevention.capitalSources,
                                action.payload
                            ]
                        }
                    }
                }
            }
        case types.PERSONAL_DATA_REMOVE_CAPITAL_SOURCES:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        moneyLaunderingPrevention: {
                            ...state.data.data.moneyLaunderingPrevention,
                            capitalSources: [
                                ...state.data.data.moneyLaunderingPrevention.capitalSources.filter(e =>
                                    e.actualValue !== action.payload.actualValue
                                )
                            ]
                        }
                    }
                }
            }
        case types.PERSONAL_DATA_SAVE_ANSWER:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        [action.answer.section]: {
                            ...state.data.data[action.answer.section],
                            [action.answer.id]: action.answer.answer
                        }
                    }

                }
            }
        case types.PERSONAL_DATA_STORE_ANSWERS:
            return { ...state, data: action.answers };
        case types.PERSONAL_DATA_STEP_ERROR:
            return { ...state, stepError: action.stepError, toastVisible: true };
        case types.PERSONAL_DATA_HIDE_TOAST:
            return { ...state, toastVisible: false }
        default:
            return state;
    }
};

export const privateActions = {
  buildError: (errors) => (dispatch, getState) => {
    let parsedErrors = []
    errors.forEach(e => {
        parsedErrors.push({ ...e, description: getState().personalData.currentStep.dictionary[e.errorCode] });
    });
    dispatch(privateActions.displayStepError(parsedErrors));
  },
  displayStepError: stepError => ({ type: types.PERSONAL_DATA_STEP_ERROR, stepError }),
  loadStepSuccess: (step, clearValidationErrors = true) => ({ type: types.PERSONAL_DATA_LOAD_STEP_SUCCESS, step, clearValidationErrors }),
  loadStepFail: error => ({ type: types.PERSONAL_DATA_LOAD_STEP_FAIL, error })
}

export const actions = {
  loadStep: (stepId) => (dispatch) => {
    dispatch(progress.setLoading(true));
    return getPersonalDataStepScreen(stepId).then(step => {

      // Before batching, storeAnswers needed to be dispatched before submitting the current step in order to prevent
      // overriding presets made by step components (e.g. account data). Batching also speeds up rendering.
      dispatch(batchActions([
        actions.storeAnswers(step._embedded.contractData),
        privateActions.loadStepSuccess(step),
        header.updateTitle((step.dictionary && step.dictionary.pageTitle) ? step.dictionary.pageTitle : ''),
        stepMetaActions.setPriority('hints'),
        stepMetaActions.setHintCollapsed(false),
        stepMetaActions.getHints({
          hint: {
              title: step.dictionary.requestPersonalInfoQuestion,
              description: step.dictionary.requestPersonalInfoText
          }
        })
      ]))

      return step;
    })
    .catch(err => {
      dispatch(privateActions.loadStepFail(err));
      return Promise.reject(err);
    })
    .finally(() => dispatch(progress.setLoading(false)));
  },

  startPersonalDataProcessing: () => (dispatch) => {
    dispatch(progress.setLoading(true));

    // NEEDS FIX B: get link from different source than customer-home. Why is this call needed in any case?
    //              Would advisory-context.enter-advisory from home resource yield the same behavior?
    return getPath([{ path: 'customer-home' }, { path: 'advisoryProcessing', method: 'post', body: {} }])
      .then(() => getPath([{ path: 'becoming-customer-context', method: 'post', body: {} }]).then(() => true))
      .finally(() => dispatch(progress.setLoading(false)));
  },
  prevStepNav: () => (dispatch, getState) => {
    const cs = getState().personalData.currentStep;

    dispatch(progress.setLoading(true));
    return getStepPersonalData(cs._links['previous'].href)
      .finally(() => dispatch(progress.setLoading(false)))
  },
  nextStepNav: (validationGroup) => (dispatch, getState) => {

    const cs = getState().personalData.currentStep;
    const url = cs._links['contractData'].href;

    const data = { ...getState().personalData.data, validationGroup };

    dispatch(progress.setLoading(true));
    return putAnswers(url, data)
      .then(() => {
        if (cs._links['next']) {
          return getStepPersonalData(cs._links['next'].href);
        }
        else /* finish */ {
          return completePersonalData(cs._links['completeContractData'].href);
        }
      })
      .catch(err => {
        if(get(err, 'response.statusCode') === 400 && get(err, 'response.body.fieldErrors')) {
          dispatch(batchActions([
            privateActions.buildError(err.response.body.fieldErrors),
            stepMetaActions.setPriority('errors'),
            stepMetaActions.setHintCollapsed(true)
          ]));
        }

        // Backend changes version even if request has been returned with 400. This means subsequent
        // requests would result in 409 unless we refresh here.
        if([400, 409].includes(get(err, 'response.statusCode'))) {
          return getStepPersonalData(cs._links['self'].href)
              .then(step => {

                dispatch(batchActions([
                  privateActions.loadStepSuccess(step, false),
                  header.updateTitle((step.dictionary && step.dictionary.pageTitle) ? step.dictionary.pageTitle : '')
                ]));

                // We still reject in order to notify consumers that navigation is not allowed
                return Promise.reject('validation error');
              }, (err) => dispatch(privateActions.loadStepFail(err)));
        }

        return Promise.reject(err);
      })
      .finally( () => dispatch(progress.setLoading(false)));
  },

  storeAnswers: (answers) => ({ type: types.PERSONAL_DATA_STORE_ANSWERS, answers }),
  saveAnswer: (answer) => ({ type: types.PERSONAL_DATA_SAVE_ANSWER, answer }),

  addTaxLand: () => ({ type: types.PERSONAL_DATA_ADD_TAX_LAND }),
  removeTaxLand: (payload) => ({ type: types.PERSONAL_DATA_REMOVE_TAX_LAND, payload }),
  editTaxLand: (payload) => ({ type: types.PERSONAL_DATA_EDIT_TAX_LAND, payload }),

  addCapitalSources: (payload) => ({ type: types.PERSONAL_DATA_ADD_CAPITAL_SOURCES, payload }),
  removeCapitalSources: (payload) => ({ type: types.PERSONAL_DATA_REMOVE_CAPITAL_SOURCES, payload }),
}

export const getCurrentStep = (state) => state.personalData.currentStep;
export const getStepData = (state) => state.personalData.data;
export const errors = (state) => state.personalData.error;
export const getStepError = (state) => state.personalData.stepError;
export const getHints = (state) => state.stepMeta.hints;
