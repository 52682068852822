/**
 * There are various possible approaches to realizing profiles. I opted against the create-react built in build
 * environments as well as against the env-cmd approach, because they seemed to limited with respect to our multi
 * repository philosophy to whitelabelling.
 *
 * cra built ins:
 * https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
 *
 * env-cmd:
 * https://facebook.github.io/create-react-app/docs/deployment#customizing-environment-variables-for-arbitrary-build-environments
 *
 * A programmatical solution yields the biggest flexibility, because this module is subject to our file aliasing
 * (we can later decompose the configuration into different aspects and only override certain aspects from whitelabel
 * apps). Another minor benefit is that changes to this modules are picked up without having to restart the development
 * server (as opposed to using build environments). However, we do lose html substition with variables from env files.
 * In the medium term a mixed approach may make sense.
 *
 * Basically I took inspiration from https://serverless-stack.com/chapters/environments-in-create-react-app.html
 *
 * Right now all configs are included in all builds (which is uncritical). We can try to fix this by using the webpack
 * tree shaking, which involves some hints on the build layer (most importantly marking modules with sideEffects - to be
 * on the safe side we should mark all modules to have side effects but this one).
 *
 * https://webpack.js.org/guides/tree-shaking/
 */
import config from "@base/config";

export const FEATURES = {
    topicSelection: {
        enabled: true,
    },
    minimumPasswordLength: 6,
    displaySecondSupportPhoneNumber: true,
    digitsSizeFactorCoefficient: 1,
    promotionCodes: false,
    dashboard: {
        transactionsAmount: 6
    },
    pieces: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 3
    }
};

export default config;
