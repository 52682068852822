import { useIsLoggedIn } from "@bounded-context/iam/hooks/login";
import { asDataQuery, withEnabledCondition, withSessionScope } from "@hooks/higher-order-hooks";
import { useQuery, useMutation } from "react-query";
import { apiRequest } from "@services/api";
import { useResolveURL } from "@hooks/common";
import { useDispatch } from "react-redux";
import { actions } from "@ducks/auth";

const useCurrentIdentityQuery = (options) => {
    const isLoggedIn = useIsLoggedIn();
    const useScopedQuery = withSessionScope(useQuery);
    const useConditionalQuery = withEnabledCondition(useScopedQuery, (enabled) => enabled && isLoggedIn);

    const url = useResolveURL("/border-proxy/user-management/bc-iam/identities/current");

    const queryKey = ["bc-iam", "identities", "current"];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/hal+json");

    return useConditionalQuery({ queryKey, queryFn, ...options });
};

const useCurrentIdentityContractList = (options) => {
    const isLoggedIn = useIsLoggedIn();
    const useScopedQuery = withSessionScope(useQuery);
    const useConditionalQuery = withEnabledCondition(useScopedQuery, (enabled) => enabled && isLoggedIn);

    const url = useResolveURL("/border-proxy/user-management/bc-iam/identities/current/contracts");

    const queryKey = ["bc-iam", "identities", "current", "contracts"];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/hal+json");

    return useConditionalQuery({ queryKey, queryFn, ...options });
};

const useLoginTokenCreationMutation = (options) => {
    const url = useResolveURL("/border-proxy/user-management/bc-iam/identities/current/login-token");
    const mutationFn = () => apiRequest.post(url)
        .set("Accept", "application/hal+json")
        .send();

    const dispatch = useDispatch();

    return useMutation({
        mutationFn,
        onSuccess: (result) => {
            dispatch(actions.refresh(result.body.token));
        },
        ...options
    });
};

export const useContractChangeMutation = (options) => {
    const url = useResolveURL("/border-proxy/user-management/bc-iam/identities/current/contracts");

    const mutationFn = (contractId) => apiRequest
        .post(url)
        .set("Content-Type", "application/json")
        .send({ contractId });

    const { mutate: token } = useLoginTokenCreationMutation();

    return useMutation({
            mutationFn,
            onSuccess: () => token(),
            ...options
        });
};

export const useCurrentIdentityDataQuery = asDataQuery(useCurrentIdentityQuery);
export const useCurrentIdentityContractsDataQuery = asDataQuery(useCurrentIdentityContractList);
