import {
    asDictionaryQuery,
    withEnabledCondition,
    withPageScope
} from "@hooks/higher-order-hooks";
import { useHomeLinksQuery } from "@hooks/home";
import { apiRequest } from "@services/api";
import { useQuery } from "react-query";

const useGlobalDataQuery = (options) => {
    const { data: url } = useHomeLinksQuery("global-data");
    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, enabled => enabled && !!url);

    const queryKey = ["global", url];

    const queryFn = () => {
        const request = apiRequest
            .get(url)
            .set("Accept", "application/json");

        return request;
    };

    return useConditionalQuery({ queryKey, queryFn, ...options, useErrorBoundary: true });
};

export const useGlobalDataDictionaryQuery = asDictionaryQuery(useGlobalDataQuery);
