import { batchActions } from 'redux-batched-actions';

import { actions as progress } from '@ducks/progress';
import { getPath, followPath, getHal } from '@services/api.service';
import { actions as errorBoundaryActions, ERROR_TYPES } from '@ducks/error-boundary';

import get from 'lodash/get';

export const types = {
  SET_TOPIC_DETAILS: 'TOPIC_HOLDING_DETAILS/SET_TOPIC_DETAILS',
  SET_PORTFOLIO_PERFORMANCE: 'TOPIC_HOLDING_DETAILS/SET_PORTFOLIO_PERFORMANCE',
  SET_TOPIC_PERFORMANCE: 'TOPIC_HOLDING_DETAILS/SET_TOPIC_PERFORMANCE',
  SET_SELECTED_TOPIC: 'TOPIC_HOLDING_DETAILS/SET_SELECTED_TOPIC',
  SET_SELECTED_DATE_RANGE: 'TOPIC_HOLDING_DETAILS/SET_SELECTED_DATE_RANGE',
  SET_DATE_RANGES_DOMAIN: 'TOPIC_HOLDING_DETAILS/SET_DATE_RANGES_DOMAIN'
}

const initialState = {
  topicDetails: null,
  portfolioPerformance: null,
  dateRangesDomain: null,
  selectedTopic: null,
  topicPerformance: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOPIC_DETAILS:
      return { ...state, topicDetails: action.topicDetails };
    case types.SET_PORTFOLIO_PERFORMANCE:
      return { ...state, portfolioPerformance: action.performance }
    case types.SET_SELECTED_TOPIC:
      return { ...state, selectedTopic: action.topic }
    case types.SET_SELECTED_DATE_RANGE:
      return { ...state, selectedDateRange: action.selectedDateRange }
    case types.SET_TOPIC_PERFORMANCE:
      return { ...state, topicPerformance: action.performance }
    case types.SET_DATE_RANGES_DOMAIN:
      return { ...state, dateRangesDomain: action.dateRangesDomain }
    default:
      return state;
  }
}

const errorHandler = (dispatch) => (err) => {
  dispatch(errorBoundaryActions.setErrorData(ERROR_TYPES.REDUX_ACTION_ERROR, err));
  return Promise.reject(err);
}

const loadTopicPerformance = (topic, range) =>
  followPath(topic, [ { path: 'performance-development', template: {key: 'dateRange', value: range}}]);

const loadPortfolioPerformance = (topicDetails, range) =>
  followPath(topicDetails, [{ path: 'performance', template: {key: 'dateRange', value: range}}]);

const privateActions = {
  setTopicDetails: (topicDetails) => ( { type: types.SET_TOPIC_DETAILS, topicDetails } ),
  setPortfolioPerformance: (dateRange, value) => ({ type: types.SET_PORTFOLIO_PERFORMANCE, performance: { value, dateRange } }),
  setSelectedTopic: topic => ({ type: types.SET_SELECTED_TOPIC, topic }),
  setTopicPerformance: (topic, dateRange, value) => ({ type: types.SET_TOPIC_PERFORMANCE, performance: { value, topic, dateRange } }),
  setDateRangesDomain: (dateRangesDomain) => ({ type: types.SET_DATE_RANGES_DOMAIN, dateRangesDomain }),
  setSelectedDateRange: (selectedDateRange) => ({ type: types.SET_SELECTED_DATE_RANGE, selectedDateRange }),
  refreshPerformance: (refreshPortfolio) => (dispatch, getState) => {

    const topic = getSelectedTopic(getState());
    const dateRange = getSelectedDateRange(getState());

    if(!topic || !dateRange) {
      return;
    }

    dispatch(progress.setLoading(true));
    const portfolioPerformancePromise = refreshPortfolio
      ? loadPortfolioPerformance(getTopicDetails(getState()), dateRange)
      : Promise.resolve({datasets: [getPortolioPerformance(getState()).value]});

    return Promise.all([
      loadTopicPerformance(topic, dateRange),
      portfolioPerformancePromise
    ])
      .then(res => {
        dispatch(batchActions([
          privateActions.setTopicPerformance(topic, dateRange, res[0]),
          privateActions.setPortfolioPerformance(dateRange, res[1].datasets[0])
        ]));
      })
      .catch(errorHandler(dispatch))
      .finally(() => dispatch(progress.setLoading(false)));
  }
}

export const actions = {
  loadInitialState: () => (dispatch, getState) => {
    dispatch(progress.setLoading(true));
    return getPath([{ path: 'topic-holding-details' }])
      .then( re => {
        dispatch(privateActions.setTopicDetails(re));

        return getHal(re._links.dateRanges.href)
          .then(resp => resp.body)
          .then(repr => {
            const dateRangesDomain = repr._embedded.dateRanges;
            dispatch(privateActions.setDateRangesDomain(dateRangesDomain));
            return {
              topics: getTopics(getState()),
              dateRangesDomain
            };
          });
      } )
      .catch(errorHandler(dispatch))
      .finally(() => dispatch(progress.setLoading(false)));
  },

  selectDateRange: (requestedDateRange) => (dispatch, getState) => {
    dispatch(privateActions.setSelectedDateRange(requestedDateRange));
    return privateActions.refreshPerformance(true)(dispatch, getState);
  },

  selectTopic: (requestedTopic) => (dispatch, getState) => {
    dispatch(privateActions.setSelectedTopic(requestedTopic));
    return privateActions.refreshPerformance(getPortolioPerformance(getState()) == null)(dispatch, getState);
  }
}

const getTopicDetails = state => state.topicHoldingDetails.topicDetails;
export const getDictionary = (state) => get(state, 'topicHoldingDetails.topicDetails.dictionary', null);
export const getTopics = (state) => get(state, 'topicHoldingDetails.topicDetails._embedded.topics', null);
export const getPortolioPerformance = state => state.topicHoldingDetails.portfolioPerformance;
export const getDateRangesDomain = state => state.topicHoldingDetails.dateRangesDomain;
export const getSelectedDateRange = state => state.topicHoldingDetails.selectedDateRange;
export const getSelectedTopic = state => state.topicHoldingDetails.selectedTopic;
export const getTopicPerformance = state => state.topicHoldingDetails.topicPerformance;

export const isConsistent = state => {
  return get(getTopicPerformance(state), 'topic.id') === get(getSelectedTopic(state), 'id');
}
