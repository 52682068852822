import { getPath, confirmChanges } from '@services/api.service';
import { actions as progress } from './progress';
import { actions as header } from '@ducks/header';

export const phases = {
    OVERVIEW: 'overview',
    FINISH: 'finish',
    DETAILS: 'details'
};

export const types = {
    TOPIC_START_SCREEN_LOAD_SUCCESS: 'TOPIC/START_SCREEN_LOAD_SUCCESS',
    TOPIC_START_SCREEN_LOAD_FAIL: 'TOPIC/START_SCREEN_LOAD_FAIL',

    TOPIC_FINISH_SCREEN_LOAD_SUCCESS: 'TOPIC/FINISH_SCREEN_LOAD_SUCCESS',
    TOPIC_FINISH_SCREEN_LOAD_FAIL: 'TOPIC/FINISH_SCREEN_LOAD_FAIL',

    TOPIC_INVESTMENT_PLAN_LOAD_SUCCESS: 'TOPIC/INVESTMENT_PLAN_LOAD_SUCCESS',
    TOPIC_INVESTMENT_PLAN_LOAD_FAIL: 'TOPIC/INVESTMENT_PLAN_LOAD_FAIL',

    TOPIC_PORTFOLIO_LOAD_SUCCESS: 'TOPIC/PORTFOLIO_LOAD_SUCCESS',
    TOPIC_PORTFOLIO_LOAD_FAIL: 'TOPIC/PORTFOLIO_LOAD_FAIL',

    TOPIC_SET_PHASE: 'TOPIC/SET_PHASE'
};

export const initialState = {
    topicsStartScreen: null,
    topicsFinishScreen: null,
    topics: [],
    investmentPlan: [],
    topicPortfolio: [],
    error:null,
    phase: phases.OVERVIEW
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.TOPIC_START_SCREEN_LOAD_SUCCESS:
            return { ...state, topicsStartScreen: action.topicsStartScreen }
        case types.TOPIC_START_SCREEN_LOAD_FAIL:
            return { ...state, error: action.error }

        case types.TOPIC_FINISH_SCREEN_LOAD_SUCCESS:
            return { ...state, topicsFinishScreen: action.topicsFinishScreen }
        case types.TOPIC_FINISH_SCREEN_LOAD_FAIL:
            return { ...state, error: action.error }

        case types.TOPIC_INVESTMENT_PLAN_LOAD_SUCCESS:
            return { ...state, investmentPlan: action.investmentPlan }
        case types.TOPIC_INVESTMENT_PLAN_LOAD_FAIL:
            return { ...state, error: action.error }

        case types.TOPIC_PORTFOLIO_LOAD_SUCCESS:
            return { ...state, topicPortfolio: action.topics }
        case types.TOPIC_PORTFOLIO_LOAD_FAIL:
            return { ...state, error: action.error }

        case types.TOPIC_SET_PHASE:
            return { ...state, phase: action.phase }

        default:
            return state;
    }
};

export const actions = {
    // START SCREEN
    loadTopicsStartScreen: () => (dispatch) => new Promise((resolve, reject) => {
        dispatch(progress.setLoading(true));
        getPath([{ path: 'topics' }]).then((re) => {
            dispatch(actions.topicsStartScreenLoadSuccess(re));
            dispatch(header.updateTitle((re.dictionary && re.dictionary.pageTitle) ? re.dictionary.pageTitle : ''));
            dispatch(progress.setLoading(false));
            resolve();
        }).catch(err => {
            dispatch(actions.topicsStartScreenLoadFail(err));
            dispatch(progress.setLoading(false));
            reject(err);
        });
    }),

    // FINISH SCREEN
    loadTopicsFinishScreen: () => (dispatch) => new Promise((resolve, reject) => {
        dispatch(progress.setLoading(true));
        getPath([{ path: 'topics' }, {path: 'finish'}]).then((re) => {
            dispatch(actions.topicsFinishScreenLoadSuccess(re));
            dispatch(header.updateTitle((re.dictionary && re.dictionary.pageTitle) ? re.dictionary.pageTitle : ''));
            dispatch(progress.setLoading(false));
            resolve();
        }).catch(err => {
            dispatch(actions.topicsFinishScreenLoadFail(err));
            dispatch(progress.setLoading(false));
            reject(err);
        });
    }),

    // INVESTMENT PLAN
    loadInvestmentPlan: () => (dispatch, getState) => new Promise((resolve, reject) => {
        //dispatch(progress.setLoading(true));
        getPath([{ path: 'topics' }, { path: 'investmentPlan', all: true }]).then((re) => {
            dispatch(actions.investmentPlanLoadSuccess(re));
            //dispatch(progress.setLoading(false));
            resolve();
        }).catch(err => {
            dispatch(actions.investmentPlanLoadFail(err));
            //dispatch(progress.setLoading(false));
            reject();
        });
    }),

    confirmTopicsChanges: (href) => (dispatch) => {
        dispatch(progress.setLoading(true));
        return confirmChanges(href).then(_ => {
            dispatch(progress.setLoading(false));
        }).catch(err => {
            dispatch(progress.setLoading(false));
        });
    },

    setPhase: phase => ({type: types.TOPIC_SET_PHASE, phase}),

    topicsStartScreenLoadSuccess: topicsStartScreen => ({ type: types.TOPIC_START_SCREEN_LOAD_SUCCESS, topicsStartScreen }),
    topicsStartScreenLoadFail: error => ({ type: types.TOPIC_START_SCREEN_LOAD_FAIL, error }),

    topicsFinishScreenLoadSuccess: topicsFinishScreen => ({ type: types.TOPIC_FINISH_SCREEN_LOAD_SUCCESS, topicsFinishScreen }),
    topicsFinishScreenLoadFail: error => ({ type: types.TOPIC_START_SCREEN_LOAD_FAIL, error }),

    investmentPlanLoadSuccess: investmentPlan => ({ type: types.TOPIC_INVESTMENT_PLAN_LOAD_SUCCESS, investmentPlan }),
    investmentPlanLoadFail: error => ({ type: types.TOPIC_INVESTMENT_PLAN_LOAD_FAIL, error }),

    topicPortfolioLoadSuccess: topics => ({ type: types.TOPIC_PORTFOLIO_LOAD_SUCCESS, topics }),
    topicPortfolioLoadFail: error => ({ type: types.TOPIC_PORTFOLIO_LOAD_FAIL, error })
};

export const getTopicStartScreen = (state) => state.topic.topicsStartScreen;
export const getTopicFinishScreen = (state) => state.topic.topicsFinishScreen;
export const getInvestmentPlan = (state) => state.topic.investmentPlan;
export const getTopicPortfolio = (state) => state.topic.topicPortfolio;
export const getSelectionPhase = (state) => state.topic.phase;
