export const types = {
  SESSION_REFRESH: 'SESSION/REFRESH'
}

export const initialState = {
  activeSession: {
    sessionType: null,
    sessionDurationInSeconds: null,
    version: 0
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SESSION_REFRESH:
      return { ...state, activeSession: { ...action.activeSession, version: state.activeSession.version+1 } };
    default:
      return state;
  }
}

export const actions = {
  refreshSession: (activeSession) => dispatch => dispatch({ type: types.SESSION_REFRESH, activeSession })
}

export const getActiveSession = (state) => state.session.activeSession;
