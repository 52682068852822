import palette from "@theme/palette";
import typography from "@theme/typography";

export default {
    root: {
    },
    input: {
        height: "100%",
        margin: 0,
        padding: 0,
        textAlign: ({ textAlign }) => textAlign ?? "inherit",
        "&::placeholder": {},
        fontSize: typography.subtitle1.fontSize,
        fontWeight: typography.body1?.fontWeight ?? "400"
    },
};
