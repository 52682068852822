import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";

const breakpointValues = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 960,
            lg: 1200,
            xl: 1600,
        },
    },
};

const { breakpoints } = createTheme({ breakpoints: breakpointValues });

export { breakpoints };
export default breakpoints;
