import get from 'lodash/get';

export const types = {
    SET_ERROR_DATA: 'ERROR_BOUNDARY/SET_ERROR_DATA',
    UNSET_ERROR_DATA: 'ERROR_BOUNDARY/UNSET_ERROR_DATA',
    HIDE_MODAL: 'ERROR_BOUNDARY/HIDE_MODAL'
};

export const ERROR_TYPES = {
    LOW_LEVEL_NETWORK_ERROR: 'LOW_LEVEL_NETWORK_ERROR_CAUGHT',
    SERVER_NETWORK_ERROR: 'SERVER_ERROR_CAUGHT',
    FRONTEND_ERROR: 'FRONTEND_ERROR_CAUGHT',
    REDUX_ACTION_ERROR: 'REDUX_ACTION_ERROR'
}

export const DEFAULT_ERROR_DIALOG_TEXT = {
    errorDialogNoConnectionTitle: 'Keine Netzwerkverbindung',
    errorDialogNoConnectionContentText: 'Bitte überprüfen Sie ihre Netzwerkverbindung. Wir konnten keine Verbindung zum Server herstellen.',
    errorDialogButtonLabel: 'Seite neu laden',
    errorDialogUnknownTitle: 'Entschuldigung, es ist ein unbekannter Fehler aufgetreten.',
    errorDialogUnknownContentTextFirst: 'Es tut uns leid, dass ein Fehler aufgetreten ist. Im Moment ist die Seite nicht erreichbar. Wir arbeiten bereits an der Behebung des Fehlers.',
    errorDialogUnknownContentTextSecond: 'Bitte wenden Sie sich bei weiteren Fragen an den Support.',
    errorDialogUnknownTelLux: 'LU +352 278 628 68',
    errorDialogUnknownTelGer: 'DE +49 221 650 888 00',
    errorDialogUnknownButtonLabel: 'service@investify.com'
}

export const initialState = {
    errorData: null,
    modalVisible: false,
};

export default (state = initialState, action) => {

    switch (action.type) {
        case types.SET_ERROR_DATA:
            var extErrorData = {
                ...action.errorData,
                prevBrowserLocation: get( state , 'errorData.browserLocation'),
                browserLocation: window.location.pathname };

            return { ...state, errorData: extErrorData, modalVisible: action.modalVisible }
        case types.HIDE_MODAL:
            return { ...state, modalVisible: action.modalVisible }
        case types.UNSET_ERROR_DATA:
            return { ...state, errorData: action.errorData }
        default:
            return state
    }
};

export const actions = {
    setErrorData: (errorType, errorCaught) => {
        console.error(errorCaught.response && errorCaught.response.error ?
            errorCaught.response.error.stack : errorCaught);
        return ({ type: types.SET_ERROR_DATA, errorData: { errorType, errorCaught }, modalVisible: true });
    },
    unsetErrorData: () => ({ type: types.UNSET_ERROR_DATA, errorData: null }),
    hideModal: () => ({ type: types.HIDE_MODAL, modalVisible: false })
}

export const getErrorData = state => state.errorBoundary.errorData;
export const getModalVisible = state => state.errorBoundary.modalVisible;
