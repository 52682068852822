import React, { memo } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "@components/Modal.styles";

import {
    DialogActions,
} from "@material-ui/core";

export const Footer = ({ children, classes, ...props }) => (
        <DialogActions
            classes={{
                root: classes.actions
            }}
            {...props}
        >
            {children}
        </DialogActions>
    );

Footer.displayName = "ModalFooter";

export default memo(withStyles(styles, { name: "ModalFooter" })(Footer));
