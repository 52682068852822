import { useCallback } from "react";
import assign from "lodash/assign";
import get from "lodash/get";
import isString from "lodash/isString";
import transform from "lodash/transform";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "@providers/NavigateFunctionProvider";

export const mergeQueryStrings = (...args) => {
    const queryString = qs.stringify(transform(args, (result, value) => {
        assign(result, isString(value) ? qs.parse(value) : value);
    }, {}));
    return queryString ? `?${queryString}` : "";
};

export const useHandleRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return useCallback((url) => {
        const toSearch = isString(url) ? qs.extract(url) : get(url, "search", "");
        const search = mergeQueryStrings(location.search, toSearch);
        const nextLocation = isString(url)
            ? { pathname: url.split("?")[0], search }
            : { ...url, search };
        return navigate(nextLocation);
    }, [navigate, location.search]);
};
