import { useMemo } from "react";
import { useFlowContext } from "@providers/FlowContextProvider";

export * from "@common/hooks/common";

export const useIsFlow = (...flows) => {
    const flow = useFlowContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => flows.reduce((previous, current) => previous || current === flow, false), [flow, ...flows]);
};
