import { getCustomerState } from '@services/api.service';

export const types = {
  LOAD_USER_STATUS: 'USER/LOAD_USER_STATUS',
  LOAD_USER_STATUS_SUCCESS: 'USER/LOAD_USER_STATUS_SUCCESS',
  LOAD_USER_STATUS_FAIL: 'USER/LOAD_USER_STATUS_FAIL'
}

export const initialState = {
  userStatus: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USER_STATUS_SUCCESS:
      return { ...state, userStatus: action.userStatus, error: null }
    case types.LOAD_USER_STATUS_FAIL:
      return { ...state, userStatus: null, error: action.error }
    default:
      return state;
  }
}

export const actions = {
  loadUserStatus: () => (dispatch) => {
    getCustomerState().then(
        re => dispatch(actions.loadUserStatusSuccess(re)),
        err => dispatch(actions.loadUserStatusFail(err))
      )
  },
  loadUserStatusSuccess: (userStatus) =>
    ({ type: types.LOAD_USER_STATUS_SUCCESS, userStatus }),
  loadUserStatusFail: (error) =>
    ({ type: types.LOAD_USER_STATUS_FAIL, error }),
}

export const getUserStatus = (state) => state.user.userStatus;
