import { useQuery, type UseQueryResult } from "react-query";
import { useCallback } from "react";
import { useHookImplementation } from "@providers/ImplementationsProvider";
import type { Policies } from "@core/application/policies/protocol";

import get from "lodash/get";

export const usePoliciesContentQuery = Symbol("usePoliciesContentQuery");
export type UsePoliciesContentQuery = () => UseQueryResult<Policies>;

export const usePolicyTestByAggregateIdContentQuery = Symbol("usePolicyTestByAggregateIdContentQuery");
export type UsePolicyTestByAggregateIdContentQuery = (aggregateIds: string[]) => UseQueryResult<string[]>;

export * from "@common/core/application/policies/hooks";

export const usePolicyBasedAccessControl = (aggregateIds: string[] = []) => {
    const { data: policies } = useHookImplementation(usePoliciesContentQuery)();
    const { data: policyTestByInvestmentGrant } = useHookImplementation(usePolicyTestByAggregateIdContentQuery)(aggregateIds);

    return useCallback((scope: string | string[]) => {
        if (!policies) {
            return false;
        }
        if (Array.isArray(scope)) {
            return scope.some((s) => get(policies, s, false));
        }
        let result = get(policies, scope, false);
        if (!result && policyTestByInvestmentGrant) {
            result = !!policyTestByInvestmentGrant
                && policyTestByInvestmentGrant.length > 0
                && policyTestByInvestmentGrant.every((p: string) => aggregateIds.includes(p));
        }
        return result;
    }, [policies, policyTestByInvestmentGrant]);
};

export const createIsAccessPolicyValid = (policyScope: string) => () => {
    const validatePolicyAccess = usePolicyBasedAccessControl();

    return validatePolicyAccess(policyScope);
};

/** @override */
export const useHybridOnboardingPolicyTestContentQuery = (aggregateIds: string[]) => {
    return (aggregateIds) as unknown as UseQueryResult<string[]>;
};
