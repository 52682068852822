import { useContext } from "react";
import type { ServiceIdentifier } from "@core/application/ioc";
import { Container } from "@core/application/ioc";
import { ContainerContext } from "@core/application/ioc/providers";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { usePoliciesContentQuery } from "@core/application/policies/hooks";
import { withEnabledCondition, withSessionScope } from "@hooks/higher-order-hooks";
import { useHookImplementation } from "@providers/ImplementationsProvider";

import modules from "@core/application/ioc/modules";

import flatten from "lodash/flatten";

export function useContainerQuery(options?: UseQueryOptions<Container>) {
    const { data: policies } = useHookImplementation(usePoliciesContentQuery)();

    const useScopedQuery = withSessionScope(useQuery);
    const useConditionalQuery = withEnabledCondition(useScopedQuery, (enabled: boolean) => enabled && !!policies);

    const queryKey = ["ioc", "container"];

    const queryFn = async () => {
        const container = new Container();

        const modulesToBeLoaded = await Promise.all(modules
            .filter((module) => module.shouldLoad(policies))
            .map((module) => module.load()));

        const moduleOrModules = modulesToBeLoaded.map((module) => module.default);

        flatten(moduleOrModules).forEach((module) => container.load(module));
        return container;
    };

    return useConditionalQuery({ queryKey, queryFn, select: (x: Container) => x, ...options });
}

export function useContainer() {
    const container = useContext(ContainerContext);

    if (container === undefined) throw new Error("There is no container to be used.");

    return container;
}

export function useInject<T>(serviceIdentifier: ServiceIdentifier<T>): T {
    return useContainer().get(serviceIdentifier);
}
