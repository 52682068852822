import QueryClientProviderBase from "@common/providers/QueryClientProvider";
import { ReactQueryDevtools } from "react-query/devtools";

import { makeStyles } from "@material-ui/styles";

const styles = (theme) => ({
    reactQueryDevTools: {
        "&&": {
            margin: "0!important",
            bottom: `${theme.spacing(8)}px!important`,
            right: `${theme.spacing(2)}px!important`,
        }
    }
});

const useStyles = makeStyles(styles);

const QueryClientProvider = ({ children }) => {
    const classes = useStyles();
    return <QueryClientProviderBase disableDevtools={true}>
        {children}
        {/* eslint-disable-next-line no-undef */}
        {process.env.NODE_ENV !== "production" && (
            <ReactQueryDevtools toggleButtonProps={{
                className: classes.reactQueryDevTools
            }}
                                position={"bottom-right"}
            />
        )}
    </QueryClientProviderBase>;
};

export const withQueryClientProvider = (Component) => (props) => <QueryClientProvider>
    <Component {...props}/>
</QueryClientProvider>;

export default QueryClientProvider;
