import { getPath, getHal } from "@services/api.service";
import { actions as progress } from "@ducks/progress";
import { actions as header } from "@ducks/header";

import { actions as errorBoundaryActions, ERROR_TYPES } from "@ducks/error-boundary";
import config from "@config";
const { PUBLIC_URL } = config;

const CORE_ID = "CORE";
const TOPICS_ID = "TOPICS";
const CORE_NAME = "core-value";
const LIQUIDITY_NAME = "liquidity-account-value";
const TOPICS_NAME = "combined-topic-value";

export const types = {
    PORTFOLIO_ANALYSIS_LOAD: "PORTFOLIO_ANALYSIS/LOAD",
    PORTFOLIO_ANALYSIS_LOAD_SUCCESS: "PORTFOLIO_ANALYSIS/LOAD_SUCCESS",

    DATE_RANGES_LOAD: "PORTFOLIO_ANALYSIS/DATE_RANGES_LOAD",
    DATE_RANGES_LOAD_SUCCESS: "PORTFOLIO_ANALYSIS/DATE_RANGES_LOAD_SUCCESS",

    NETWORTH_LOAD: "PORTFOLIO_ANALYSIS/NETWORTH_LOAD",
    NETWORTH_LOAD_SUCCESS: "PORTFOLIO_ANALYSIS/NETWORTH_LOAD_SUCCESS"
};

export const initialState = {
    portfolio: null,
    dateRangesData: null,
    networthData: null
};

const networthDevelopmentUrl = `${PUBLIC_URL}/border-proxy/portfolioanalytics/networth-development/{dateRange}`;

export const getPortfolio = (state) => state.portfolioAnalysis.portfolio;
export const getDateRangesData = (state) => state.portfolioAnalysis.dateRangesData;
export const getNetworthData = (state) => state.portfolioAnalysis.networthData;
export const getSelectedDateRange = (state) => state.portfolioAnalysis.selectedDateRange;

export default (state = initialState, action) => {
    switch (action.type) {
        case types.PORTFOLIO_ANALYSIS_LOAD_SUCCESS:
            return { ...state, portfolio: action.portfolio };
        case types.DATE_RANGES_LOAD_SUCCESS:
            return { ...state, dateRangesData: action.dateRangesData };
        case types.NETWORTH_LOAD_SUCCESS:
            return { ...state, networthData: action.networthData, selectedDateRange: action.dateRange };
        default:
            return state;
    }
};

const errorHandler = (dispatch) => (err) => {
  dispatch(errorBoundaryActions.setErrorData(ERROR_TYPES.REDUX_ACTION_ERROR, err));
  return Promise.reject(err);
};

const internalActions = {
  loadPortfolioSuccess: (portfolio) => ({ type: types.PORTFOLIO_ANALYSIS_LOAD_SUCCESS, portfolio }),
  loadDateRangesSuccess: (dateRangesData) => ({ type: types.DATE_RANGES_LOAD_SUCCESS, dateRangesData }),
  loadNetworthSuccess: (dateRange, networthData) => ({ type: types.NETWORTH_LOAD_SUCCESS, networthData, dateRange })
};

export const actions = {
  loadState: () => (dispatch) => {
    dispatch(progress.setLoading(true));
    return getPath([{ path: "portfolio-balance", all: true }]).then(re => {
      dispatch(header.updateTitle((re.dictionary && re.dictionary.pageTitle) ? re.dictionary.pageTitle : ""));
      re.split = re._embedded.split;
      dispatch(internalActions.loadPortfolioSuccess(re));

      return getHal(re._links.dateRanges.href)
        .then(resp => resp.body)
        .then(repr => dispatch(internalActions.loadDateRangesSuccess(repr._embedded.dateRanges)));
    })
    .catch(errorHandler(dispatch))
    .finally(() => dispatch(progress.setLoading(false)));
  },
  selectDateRange: (dateRange) => (dispatch, getState) => {
    dispatch(progress.setLoading(true));

      const portfolio = getPortfolio(getState());
      const networthHref = networthDevelopmentUrl.replace("{dateRange}", dateRange);

      return getHal(networthHref)
      .then(resp => resp.body)
      .then(re => {
        const networthData = {
          title: "chartTitle",
          series: transformNetworthSeries(re.datasets, portfolio.split),
          profitValues: transformProfitValues(re.profitValues)
        };

        dispatch(internalActions.loadNetworthSuccess(dateRange, networthData));
      })
      .catch(errorHandler(dispatch))
      .finally(() => dispatch(progress.setLoading(false)));
  }
};

function buildChartModule(metaData, chartData) {
    return {
        title: metaData.title,
        color: metaData.color,
        currency: metaData.currency,
        data: chartData
    };
}

function transformNetworthSeries(datasets, split) {
    if (!datasets) {
        return [];
    }

    const rawCore = datasets.find((set) => set.name === CORE_NAME);
    const rawLiquidity = datasets.find((set) => set.name === LIQUIDITY_NAME);
    const coreMetadata = split.find(rec => rec.id === CORE_ID);

    const core = (rawCore && rawLiquidity)
? buildChartModule(coreMetadata, rawCore.data.map((value, key) => {
            return {
                date: value.day,
                value: (value.value + rawLiquidity.data[key].value).toFixed(1)
            };
        }))
: rawLiquidity
? buildChartModule(coreMetadata, rawLiquidity.data.map(({ day, value }) => {
            return { date: day, value };
        }))
: null;

    const rawTopics = datasets.find((set) => set.name === TOPICS_NAME);
    const topicMetadata = split.find(rec => rec.id === TOPICS_ID);
    const topics = rawTopics
? buildChartModule(topicMetadata, rawTopics.data.map(({ day, value }) => {
        return { date: day, value };
    }))
: null;

    return [core, topics].filter(el => el != null);
}


function transformProfitValues(profitValues) {
    if (!profitValues) {
        return [];
    }

    const coreProfit = profitValues.find((pv) => pv.name === CORE_NAME);
    const liquidityProfit = profitValues.find((pv) => pv.name === LIQUIDITY_NAME);
    const topicsProfit = profitValues.find((pv) => pv.name === TOPICS_NAME);

    const displayProfits = [];
    const coreTotalProfit = (coreProfit ? coreProfit.value : 0.0) + (liquidityProfit ? liquidityProfit.value : 0.0);
    displayProfits.push({ name: "core", value: coreTotalProfit });
    displayProfits.push(topicsProfit ? { name: "topics", value: topicsProfit.value } : {});
    displayProfits.push({ name: "totalLabel", value: displayProfits.filter(el => el.name).map(el => el.value).reduce((x, y) => x + y, 0) });
    return displayProfits;
}
