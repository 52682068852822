import MenuItem from "@components/menu/MenuItem";
import styles from "@components/menu/styles";
import { withStyles } from "@material-ui/styles";
import { useCallback } from "react";
import { useContractChangeMutation } from "@bounded-context/iam/hooks/identity";

const SelectContractMenuItem = ({ contractId, active, onChange, ...props }) => {
    const { mutate, isLoading } = useContractChangeMutation();

    const doRefresh = useCallback(async () => {
        mutate(contractId);
        onChange();
    }, [mutate]);

    return (
        <MenuItem
            {...props}
            onClick={doRefresh}
            disabled={active || isLoading}
        />
    );
};

export default withStyles(styles, { name: "SelectContractMenuItem" })(SelectContractMenuItem);
