import If from "@components/If";
import styles from "@components/menu/styles";
import { useHandleRedirect } from "@utils/navigation";
import Icon from "@material-ui/core/Icon";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const MenuItem = ({ classes, ...props }) => {
    const { label = "", route = "", icon: MenuIcon = null } = props;

    const handleRedirect = useHandleRedirect();

    return (
        <MuiMenuItem
            button
            onClick={() => handleRedirect(route?.path ?? route)}
            classes={{
                root: classes.itemRoot
            }}
            {...props}
        >
            <If condition={MenuIcon}>
                <Icon fontSize={"small"} classes={{ root: classes.itemIcon }}>
                    <MenuIcon />
                </Icon>
            </If>
            <Typography variant={"inherit"} noWrap>{label}</Typography>
        </MuiMenuItem>
    );
};

export default withStyles(styles, { name: "MenuItem" })(MenuItem);
