import React, { useMemo } from "react";
import { Link as RRLink } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
    useProperties
} from "@components/application/providers/PropertiesProvider";
import MuiLink from "@material-ui/core/Link";
import { useLocation } from "@hooks/router";
import isString from "lodash/isString";
import queryString from "query-string";
import get from "lodash/get";
import { mergeQueryStrings } from "@utils/navigation";

export * from "@common/components/Link";

const styles = (theme) => ({
    root: {
        color: theme.palette.grey[500],
        cursor: "not-allowed",
        textDecoration: "none"
    },
});

const Link = React.forwardRef(({ className, classes, to, ...props }, ref) => {
    const location = useLocation();
    const properties = useProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const disabled = useMemo(() => (properties?.disabled ?? false), [properties]);
    const isExternalLink = typeof to === "string" && to.includes(":");

    if (disabled) {
        return <span
            className={[classes.root, className].join(" ")}
            ref={ref}
            aria-disabled={disabled}
            {...props}
        />;
    }

    if (isExternalLink) {
        return <MuiLink
            href={to}
            className={[className].join(" ")}
            ref={ref}
            {...props}
        />;
    }

    const toSearch = isString(to) ? queryString.extract(to) : get(to, "search", "");
    const search = mergeQueryStrings(location.search, toSearch);
    const nextLocation = isString(to)
        ? { pathname: to.split("?")[0], search }
        : { ...to, search };

    return (
        <RRLink
            {...props}
            to={nextLocation}
            className={[className].join(" ")}
            ref={ref}
        />
    );
});

export default withStyles(styles, { name: "Link" })(Link);
