import { makeStyles } from "@material-ui/styles";
import { type FC, type PropsWithChildren, type ComponentType, forwardRef } from "react";

import styles from "@components/Stack.styles";

const useStyles = makeStyles(styles, { name: "Stack" });

export type StackProps = PropsWithChildren<{
    readonly component?: ComponentType
    readonly direction: "column" | "row" | "column-reverse" | "row-reverse"
    readonly spacing?: number
    readonly className?: string
    readonly centered?: boolean
    readonly style?: React.CSSProperties
}>;

const Stack: FC<StackProps> = forwardRef<HTMLDivElement, StackProps>(({ children, direction, spacing, className, component, centered, style, ...props }, ref) => {
    const classes = useStyles({ direction, spacing, centered });

    const Component = component || "div";

    return <Component ref={ref} className={[classes.root, className].join(" ")} style={style} {...props}>{children}</Component>;
});

Stack.displayName = "Stack";

export default Stack;
