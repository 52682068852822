import { useTransactionsFacade } from "@bounded-context/portfolio-analysis/facades/transactions";
import { apiRequest } from "@services/api";
import { asEmbeddedQuery, withEnabledCondition, withLoadingContext, withPageScope } from "@hooks/higher-order-hooks";
import { useQuery } from "react-query";
import { withRefreshPeriod } from "@providers/RefreshPeriodProvider";
import { withRefreshKey } from "@providers/RefreshKeyProvider";

export const useCustomerDashboardTransactionsDictionaryQuery = Symbol("useCustomerDashboardTransactionsDictionaryQuery");

const useTransactionsQuery = (options) => {
    const { useTransactionsUrlQuery } = useTransactionsFacade();
    const { data: url } = useTransactionsUrlQuery();

    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);
    const useConditionalQueryWLC = withLoadingContext(useConditionalQuery);
    const useQueryWithRefreshPeriod = withRefreshPeriod(useConditionalQueryWLC);
    const useQueryWithRefreshKey = withRefreshKey(useQueryWithRefreshPeriod);

    const queryKey = ["bc-portfolio-analysis", "transactions", url];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/hal+json")
        .send();

    return useQueryWithRefreshKey({ queryKey, queryFn, useErrorBoundary: true, ...options });
};

export const useTransactionsEmbeddedQuery = asEmbeddedQuery(useTransactionsQuery);
