import { getPath, getHal } from "@services/api.service";
import { legacyApiRequest } from "@services/api";
import { actions as progress } from "@ducks/progress";
import { actions as header } from "@ducks/header";
import { actions as errorBoundaryActions, ERROR_TYPES } from "@ducks/error-boundary";
import { batchActions } from "redux-batched-actions";
import config from "@config";

const CORE_NAME = "core-value";
const LIQUIDITY_NAME = "liquidity-account-value";
const { PUBLIC_URL } = config;
const networthDevelopmentUrl = `${PUBLIC_URL}/border-proxy/portfolioanalytics/networth-development/{dateRange}`;

const contract = {
   routeBaseUri: "/core-details/",
};

export const types = {
   CORE_ALLOCATION_LOAD_SUCCESS: "CORE_ALLOCATION/LOAD_SUCCESS",
   CORE_PRODUCTS_LOAD_SUCCESS: "CORE_PRODUCTS/LOAD_SUCCESS",
   CORE_BALANCE_LOAD_SUCCESS: "CORE_BALANCE/LOAD_SUCCESS",
   CORE_PROFIT_LOAD_SUCCESS: "CORE_PROFIT/LOAD_SUCCESS",
   DICTIONARY_LOAD_SUCCESS: "DICTIONARY/LOAD_SUCESS",
   SET_NETWORTH_URI_TEMPLATE: "CORE_DETAILS/SET_NETWORTH_URI_TEMPLATE"
};

export const initialState = {
   coreAllocation: null,
   coreProducts: null,
   coreBalance: null,
   dictionary: null,
   coreProfit: null,
   error: null
};

export default (state = initialState, action) => {
   switch (action.type) {
      case types.CORE_BALANCE_LOAD_SUCCESS:
         return { ...state, coreBalance: action.coreValues };
      case types.CORE_ALLOCATION_LOAD_SUCCESS:
         return { ...state, coreAllocation: action.coreAllocation };
      case types.CORE_PRODUCTS_LOAD_SUCCESS:
         return { ...state, coreProducts: action.coreProducts };
      case types.CORE_PROFIT_LOAD_SUCCESS:
         return { ...state, coreProfit: action.coreProfit, selectedDateRange: action.dateRange };
      case types.DICTIONARY_LOAD_SUCCESS:
         return { ...state, dictionary: action.dictionary };
      case types.SET_NETWORTH_URI_TEMPLATE:
          return { ...state, networthUriTemplate: action.networthUriTemplate };
      default:
         return state;
   }
};

const privateActions = {
  loadCoreAllocationSuccess: (coreAllocation) => ({ type: types.CORE_ALLOCATION_LOAD_SUCCESS, coreAllocation }),
  loadDictionarySuccess: (dictionary) => ({ type: types.DICTIONARY_LOAD_SUCCESS, dictionary }),
  loadCoreProductsSuccess: (coreProducts) => ({ type: types.CORE_PRODUCTS_LOAD_SUCCESS, coreProducts }),
  loadCoreBalanceSuccess: (coreValues) => ({ type: types.CORE_BALANCE_LOAD_SUCCESS, coreValues }),
  loadCoreProfitSuccess: (dateRange, coreProfit) => ({ type: types.CORE_PROFIT_LOAD_SUCCESS, coreProfit, dateRange }),
  setNetworthUriTemplate: (networthUriTemplate) => ({ type: types.SET_NETWORTH_URI_TEMPLATE, networthUriTemplate }),
};

const errorHandler = (dispatch) => (err) => {
  dispatch(errorBoundaryActions.setErrorData(ERROR_TYPES.REDUX_ACTION_ERROR, err));
  return Promise.reject(err);
};

export const actions = {
  loadCoreDetails: () => (dispatch) => {
    dispatch(progress.setLoading(true));
    return getPath([{ path: "core-details", all: true }]).then(re => {
      dispatch(batchActions([
        header.updateTitle((re.dictionary && re.dictionary.pageTitle) ? re.dictionary.pageTitle : ""),
        privateActions.loadCoreAllocationSuccess(re._embedded.allocation),
        privateActions.loadDictionarySuccess(re.dictionary),
        privateActions.loadCoreProductsSuccess(re._embedded.coreProducts.topicProducts),
        privateActions.setNetworthUriTemplate(re._links.networth.href)
      ]));

      // Backend replies with 406 if Accept is application/hal+json
      return legacyApiRequest.get(re._links.portfolioAnalysis.href)
        .then(resp => resp.body)
        .then(repr => ([
          {
            title: "productsTitle",
            value: repr._embedded.balance.core
          },
          {
            title: "liquidityTitle",
            value: repr._embedded.balance.liquidity
          }
        ]))
        .then(coreValues => dispatch(privateActions.loadCoreBalanceSuccess(coreValues)));
    })
    .catch(errorHandler(dispatch))
    .finally(() => dispatch(progress.setLoading(false)));
  },

  loadCoreProfit: (dateRange) => (dispatch, getState) => {
      const networthHref = networthDevelopmentUrl.replace("{dateRange}", dateRange);

      return getHal(networthHref)
      .then(resp => resp.body)
      .then(re => {
        const totalCoreProfit = re.profitValues
          .filter(pv => [CORE_NAME, LIQUIDITY_NAME].includes(pv.name))
          .map(pv => pv.value)
          .reduce((x, y) => x + y, 0.0);

        dispatch(privateActions.loadCoreProfitSuccess(dateRange, totalCoreProfit));
    })
    .catch(errorHandler(dispatch));
  }
};

export const getCoreDictionary = (state) => state.coreDetails.dictionary;
export const getCoreAllocation = (state) => state.coreDetails.coreAllocation;
export const getCoreBalance = (state) => state.coreDetails.coreBalance;
export const getCoreProfit = (state) => state.coreDetails.coreProfit;
export const getCoreProducts = (state) => state.coreDetails.coreProducts;
export const getSelectedDateRange = (state) => state.coreDetails.selectedDateRange;

const getNetworthUriTemplate = (state) => state.coreDetails.networthUriTemplate;

export const getAssetClassRoutes = (state) => {
   const assetClasses = state.coreDetails.coreAllocation;
   if (!(Array.isArray(assetClasses)) || assetClasses.length === 0) {
      return;
   }

   const routes = {};

   assetClasses
      .forEach(assetClass => routes[assetClass.id] = routeFromAssetClass(assetClass));

   routes.DEFAULT = routes[assetClasses[0].id];

   return routes;
};

function routeFromAssetClass(assetClass) {
   return {
      id: assetClass.id,
      route: contract.routeBaseUri + assetClass.id
   };
}
