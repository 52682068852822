import palette from "@theme/palette";

export default {
    root: {
        textTransform: "none"
    },
    contained: {
        boxShadow: "none",
        fontWeight: "bold",
        "&:hover, &:focus, &:active": {
            boxShadow: "none",
        }
    },
    containedPrimary: {
        backgroundColor: palette.primary.main,
        fontWeight: "bold",
        "&:hover, &:focus, &:active": {
            boxShadow: "none",
        }
    },
    containedSecondary: {
        backgroundColor: palette.secondary.main,
        fontWeight: "bold",
        "&:hover, &:focus, &:active": {
            boxShadow: "none",
        }
    },
};
