import styles from "@components/menu/styles";
import { LoadingBoundary } from "@components/withLoadingBoundary";
import { ClickAwayListener, Grow, MenuList, Popper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import withAll from "lodash/flowRight";
import CardContainer from "@components/CardContainer";

const Menu = ({ classes, children, handleClose, open, handleListKeyDown, anchorRef }) => (
    <Popper
        open={open}
        anchorEl={anchorRef?.current}
        transition
        disablePortal
        placement={"bottom-end"}
    >
        {({ TransitionProps }) => (
            <Grow
                style={{ transformOrigin: "top right" }}
                {...TransitionProps}
            >
                <CardContainer elevation={16} classes={{ root: classes.menuRoot }}>
                    <LoadingBoundary>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                id="menu-list-grow"
                                classes={{ root: classes.listRoot }}
                                autoFocusItem={open}
                                onKeyDown={handleListKeyDown}
                            >
                                {children}
                            </MenuList>
                        </ClickAwayListener>
                    </LoadingBoundary>
                </CardContainer>
            </Grow>
        )}
    </Popper>
);

export default withAll(
    withStyles(styles, { name: "Menu" })
)(Menu);
