const styles = (theme) => ({
        root: {
            "@supports ( (  (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))  ) )": {
                backdropFilter: "blur(6px) grayscale(50%)"
            },
            minWidth: theme.breakpoints?.values?.xs,
            padding: 0,
        },
        closeButton: {
            position: "absolute",
            top: 0,
            right: 0,
            color: theme?.palette?.primary?.main,
            margin: theme?.spacing?.(2.5, 4),
            backgroundColor: theme?.palette?.background?.paper,
            border: `1px solid ${theme?.palette?.primary?.main}`,
            "&:hover, &:focus, &:active": {
                color: theme?.palette?.background?.paper,
                backgroundColor: theme?.palette?.primary?.main,
            }
        },
        paper: {
            position: "relative",
            borderRadius: theme.shape?.borderRadius,
            maxHeight: "90vh",
            overFlow: "auto",
            padding: theme?.spacing?.(2),
            [theme?.breakpoints?.down?.("xs")]: {
                padding: theme?.spacing?.(1),
            },
            boxShadow: theme?.shadows?.[16]
        },
        paperScrollBody: {},
        header: {
            color: theme?.palette?.primary?.main,
            padding: theme?.spacing?.(2),
        },
        content: {
            position: "relative",
            height: "auto",
            overflow: "hidden",
            padding: theme?.spacing?.(1, 2),
        },
        actions: {
            padding: theme?.spacing?.(2),
        }
    });

export default styles;
