import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "@hooks/router";

function withRouter<P>(Component: React.ComponentType<P>) {
    return (props: P) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return <Component {...props} {...{ location, navigate, params }} />;
    };
}

export default withRouter;
