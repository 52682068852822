import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/styles";
import theme from "@theme";

const useStyles = makeStyles((theme) => ({
    containerRoot: {
        transform: `translateY(calc((${theme?.props?.layout?.footer?.absoluteMinHeight ?? "100vw"} + ${theme.spacing(1)}px)))`,
        left: 0,
        right: 0,
        position: "fixed"
    },
    success: {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.success.contrastText,
        marginBottom: 10

    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        marginBottom: 10

    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.success.contrastText,
        marginBottom: 10

    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        marginBottom: 10

    }
}));

const NotificationProvider = (props) => {
    const classes = useStyles();

    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            classes={{
                containerRoot: classes.containerRoot,
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info
            }}
            maxSnack={theme?.props?.notifications?.maxNotifications ?? 5}
            preventDuplicate
            autoHideDuration={5000}
            {...props}
        >
            {props.children}
        </SnackbarProvider>
    );
};

export default NotificationProvider;
