import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/styles";

const GlobalStyles = withStyles((theme) => ({
    "@global": {
        "*": {
            margin: 0,
            padding: 0,
            boxSizing: "border-box",
        },
        html: {
            width: "100%",
            height: "100%",
            "-ms-text-size-adjust": "100%",
            "-webkit-overflow-scrolling": "touch",
            overflowX: "hidden",
        },
        img: {
            maxWidth: "100%"
        },
        input: {
            "&[type=number]": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button": { margin: 0, WebkitAppearance: "none" },
                "&::-webkit-inner-spin-button": { margin: 0, WebkitAppearance: "none" },
            },
            "&::-webkit-input-placeholder": { color: theme.palette.text.disabled },
            "&::-moz-placeholder": { opacity: 1, color: theme.palette.text.disabled },
            "&:-ms-input-placeholder": { color: theme.palette.text.disabled },
            "&::placeholder": { color: theme.palette.text.disabled },
            "&:input:-internal-autofill-selected": { color: theme.palette.text.disabled },
        },
        textarea: {
            "&::-webkit-input-placeholder": { color: theme.palette.text.disabled },
            "&::-moz-placeholder": { opacity: 1, color: theme.palette.text.disabled },
            "&:-ms-input-placeholder": { color: theme.palette.text.disabled },
            "&::placeholder": { color: theme.palette.text.disabled },
            "&:input:-internal-autofill-selected": { color: theme.palette.text.disabled },
        },
        ".MuiInputBase-input": {
            "&-webkit-autofill": {

            }
        }
    },
}))(CssBaseline);

export default GlobalStyles;
