import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { actions as progress } from "@ducks/progress";

export * from "@common/hooks/higher-order-hooks";

export const withSpinner = query => {
    return (options = {}) => {
        const dispatch = useDispatch();
        const { onSettled } = options;

        const onDidSettle = useCallback((...args) => {
            dispatch(progress.setLoading(false));
            return onSettled && onSettled(...args);
        }, [dispatch, onSettled]);

        const result = query({ ...options, onSettled: onDidSettle });

        useEffect(() => {
            if (result.isIdle) dispatch(progress.setLoading(true));
        }, [dispatch, result.isIdle]);

        return result;
    };
};
