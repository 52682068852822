import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styles from "@modules/loading/components/GlobalLoader.styles";
import React from "react";
import Stack from "@components/Stack";
import Typography from "@components/Typography";

const GlobalLoader = ({ classes, size = 72 }) => <Stack direction={"column"} centered className={classes.root} spacing={4}>
        <CircularProgress size={size} color="primary" disableShrink thickness={1} variant={"indeterminate"}/>
        <Typography variant={"caption"}>{"Bitte warten"}</Typography>
    </Stack>;

export default React.memo(withStyles(styles, { name: "GlobalLoader" })(GlobalLoader));
