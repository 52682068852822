import { getPath } from '@services/api.service';

export const types = {
    SUPPORT_CONTACT_DATA_LOAD_SUCCESS: 'SUPPORT_CONTACT_DATA/LOAD_SUCCESS',
    SUPPORT_CONTACT_DATA_LOAD_ERROR: 'SUPPORT_CONTACT_DATA/LOAD_ERROR'
};

export const initialState = {
    data: null,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SUPPORT_CONTACT_DATA_LOAD_SUCCESS:
            return { ...state, data: action.data }
        case types.SUPPORT_CONTACT_DATA_LOAD_ERROR:
            return { ...state, error: action.error }
        default:
            return state;
    }
};

export const actions = {
    loadSupportContactData: () => (dispatch) => {
        getPath([{ path: 'support-contact-data' }]).then(re => {
            dispatch(actions.loadSupportContactDataSuccess(re));
        }).catch(err => {
            dispatch(actions.loadSupportContactDataError(err));
        });
    },
    loadSupportContactDataSuccess: (data) => ({ type: types.SUPPORT_CONTACT_DATA_LOAD_SUCCESS, data }),
    loadSupportContactDataError: (err) => ({ type: types.SUPPORT_CONTACT_DATA_LOAD_ERROR, err }),
}

export const getSupportContactData = (state) => state.supportContactData.data;
