import { useCurrentLoginQuery } from "@bounded-context/iam/hooks/login";
import {
    asContextsQuery, asDataQuery, asDictionaryQuery, asEmbeddedQuery, asLinksQuery, withEnabledCondition, withLoadingContext, withPageScope, withSessionScope
} from "@hooks/higher-order-hooks";
import { useHomeLinksQuery } from "@hooks/home";
import { createGlobalState, useGlobalState, useLocalStorage } from "@hooks/state";
import { apiRequest } from "@services/api";
import { none } from "@hookstate/core";

import get from "lodash/get";
import pickBy from "lodash/pickBy";
import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { withRefreshPeriod } from "@providers/RefreshPeriodProvider";

const handledModalsState = createGlobalState([]);

// TODO Refactor all possible queries into its bounded-context

const useCustomerHomeQuery = ({ refetchInterval, ...options }) => {
    const { data: url } = useHomeLinksQuery("customer-home");
    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);

    const queryKey = ["customer-home", url];

    const queryFn = () => {
        const request = apiRequest
            .get(url)
            .set("Accept", "application/json");

        return refetchInterval ? request.set("X-No-Activity", "true") : request;
    };

    return useConditionalQuery({ queryKey, queryFn, useErrorBoundary: true, refetchInterval, refetchIntervalInBackground: true, ...options });
};

export const useCustomerHomeDataQuery = asDataQuery(useCustomerHomeQuery);
export const useCustomerHomeEmbeddedQuery = asEmbeddedQuery(useCustomerHomeQuery);
export const useCustomerHomeLinksQuery = asLinksQuery(useCustomerHomeQuery);
export const useCustomerHomeDictionaryQuery = asDictionaryQuery(useCustomerHomeQuery);
export const useCustomerHomeContextsQuery = asContextsQuery(useCustomerHomeQuery);


export const useCustomerHomeFollowQuery = ({ id, ...options }) => {
    const { data: url } = useCustomerHomeLinksQuery(id);

    const usePageScopedQuery = withPageScope(useQuery);
    const useQueryWithLoadingContext = withLoadingContext(usePageScopedQuery);
    const useConditionalQuery = withEnabledCondition(useQueryWithLoadingContext, (enabled) => enabled && !!url);
    const useQueryWithRefreshPeriod = withRefreshPeriod(useConditionalQuery);

    const queryKey = ["customer-home", id, url];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/json");

    return useQueryWithRefreshPeriod({ queryKey, queryFn, useErrorBoundary: true, ...options });
};

export const useCustomerHomeFollowDataQuery = asDataQuery(useCustomerHomeFollowQuery);
export const useCustomerHomeFollowDictionaryQuery = asDictionaryQuery(useCustomerHomeFollowQuery);
export const useCustomerHomeFollowLinksQuery = asLinksQuery(useCustomerHomeFollowQuery);

const useNewsBoxQuery = (options) => {
    const { data: url } = useCustomerHomeLinksQuery("newsBox");

    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);

    const queryKey = ["customer-home", "newsbox", url];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/json");

    return useConditionalQuery({ queryKey, queryFn, ...options });
};

export const useNewsBoxDataQuery = asDataQuery(useNewsBoxQuery);


export const useOnboardingCount = () => {
    const { data: onboardingPersonalData } = useCustomerHomeLinksQuery("onboardingPersonalData");
    const { data: onboardingContracts } = useCustomerHomeLinksQuery("onboardingContracts");
    const { data: onboardingIdentification } = useCustomerHomeLinksQuery("onboardingIdentification");

    return [onboardingPersonalData, onboardingContracts, onboardingIdentification].reduce((a, b) => a + (b ? 1 : 0), 0);
};


export const useFlowParams = () => {
    const { data: customerStatus } = useCustomerHomeEmbeddedQuery("status");

    // TODO better implementation - maybe multimethod?
    switch (customerStatus) {
        case undefined:
        case "WAITING_FOR_CONTRACT_SIGNING":
            return { flow: "registered" };
        default:
            return {};
    }
};


const useCancellationMutation = (options) => {
    const { data: url } = useCustomerHomeLinksQuery("cancellation");

    const mutationFn = () => apiRequest
        .post(url)
        .set("Content-Type", "application/json")
        .set("Accept", "application/hal+json")
        .send();

    return useMutation({ mutationFn, ...options });
};

export const useIsIntroducedQuery = (options) => {
    const { isSuccess, data: identity } = useCurrentLoginQuery();

    const [introducedUsers] = useLocalStorage("greetingsModalUsers", []);

    const useConditionalQuery = withEnabledCondition(useQuery, (enabled) => enabled && isSuccess);

    const queryKey = ["customer-home", "modals", "introduced"];

    const queryFn = () => introducedUsers.includes(identity.email);

    return useConditionalQuery({ queryKey, queryFn, select: (x) => x, ...options });
};

export const useDashboardModalsQuery = (options) => {
    const { isSuccess: isSuccessDashboard, data: dashboard } = useCustomerHomeDataQuery();
    const { isSuccess: isSuccessIntroduced, data: isIntroduced } = useIsIntroducedQuery();

    const useConditionalQuery = withEnabledCondition(useQuery, (enabled) => enabled && isSuccessDashboard && isSuccessIntroduced);

    const queryKey = ["customer-home", "modals"];

    const queryFn = () => {
        const modals = get(dashboard, "modals", []);

        if (isIntroduced) return modals.filter((modal) => !modal.id.startsWith("intro"));
        return modals;
    };

    return useConditionalQuery({ queryKey, queryFn, cacheTime: 0, staleTime: 0, refetchOnMount: true, select: (x) => x, ...options });
};

export const useIntroductionModalCallback = (modal, onDidPerformAction) => {
    const { data: identity } = useCurrentLoginQuery();

    const [introducedUsers, setIntroducedUsers] = useLocalStorage("greetingsModalUsers", []);

    return useCallback(() => {
        if (modal.id === "intro2") setIntroducedUsers([...introducedUsers, identity.email]);

        onDidPerformAction();
    }, [modal, introducedUsers, identity.email, setIntroducedUsers, onDidPerformAction]);
};

export const useIdentificationModalCallback = (_, onDidPerformAction) => useCallback(() => {
        onDidPerformAction();
    }, [onDidPerformAction]);

export const useIdentificationModalIdnowIdentInfo = (options) => {
    const select = (response) => get(response, "body.data");
    const { data: idnowInfo } = useCustomerHomeFollowQuery({ select, ...options });

    const url = get(idnowInfo, "_links.start.href");
    const usePageScopedQuery = withPageScope(useQuery);
    const useQueryWithLoadingContext = withLoadingContext(usePageScopedQuery);
    const useConditionalQuery = withEnabledCondition(useQueryWithLoadingContext, (enabled) => enabled && !!url);
    const queryKey = ["customer-home", options?.id, url];

    const queryFn = () => apiRequest
        .post(url)
        .set("Accept", "application/hal+json");
    useConditionalQuery({ queryKey, queryFn, ...options });

    return idnowInfo;
};

export const useDecisionModalCallback = (_, onDidPerformAction) => {
    const queryClient = useQueryClient();

    const { mutate } = useCancellationMutation();

    const onDidCancelDecision = useCallback(async () => {
        await queryClient.invalidateQueries(["customer-investment-strategies", "proposals"]);

        return onDidPerformAction();
    }, [queryClient, onDidPerformAction]);

    return useCallback(() => mutate(undefined, { onSuccess: onDidCancelDecision }), [mutate, onDidCancelDecision]);
};


export const useHandledModals = () => {
    const state = useGlobalState(handledModalsState);

    const handledModals = state.get();

    const addHandledModal = useCallback((modalId) => state[state.length].set(modalId), [state]);

    const removeHandledModal = useCallback((modalId) => {
        const index = handledModals.indexOf(modalId);

        state[index].set(none);
    }, [state, handledModals]);

    const removeHandledModals = useCallback(() => state.set([]), [state]);

    return { handledModals, addHandledModal, removeHandledModal, removeHandledModals };
};


export const useDocumentsPDFIconQuery = (options) => {
    const { data: documentsData } = useCustomerHomeFollowDataQuery({ id: "documents" });

    const url = get(documentsData, "dictionary.pdfIcon");

    const usePageScopedQuery = withSessionScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);
    const useConditionalQueryWLC = withLoadingContext((useConditionalQuery));

    const queryKey = ["customer-home", "documents", "pdf-icon"];

    const queryFn = () => apiRequest
        .get(url);

    const select = (response) => response.text;

    return useConditionalQueryWLC({ queryKey, queryFn, select, keepPreviousData: true, ...options });
};

export const useTransactionIconsQuery = (options) => {
    const iconList = ["TRANSACTION_IN_ICON", "TRANSACTION_OUT_ICON"];

    const { data: url } = useHomeLinksQuery("transactions");

    const usePageScopedQuery = withSessionScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);
    const useConditionalQueryWLC = withLoadingContext((useConditionalQuery));

    const queryKey = ["bc_portfolio_analysis", "transactions", "pdf-icon"];
    const queryFn = () => apiRequest
        .get(url);

    const select = (response) => {
        const dictionary = get(response, "dictionary", {});
        return pickBy(dictionary, iconList);
    };

    return useConditionalQueryWLC({ queryKey, queryFn, select, keepPreviousData: true, ...options });
};
