import type { ComponentType, FC, PropsWithChildren } from "react";
import type { useQuery, QueryKey, UseQueryOptions, UseQueryResult } from "react-query";

import { createContext, useContext } from "react";
import { withMetadata } from "@hooks/higher-order-hooks";

type UseQuery<R = unknown, E = unknown, D = R, K extends QueryKey = QueryKey> = typeof useQuery<R, E, D, K>;

const RefreshKeyContext = createContext<string | undefined>(undefined);

export function useRefreshKey() {
    return useContext(RefreshKeyContext);
}

export function withRefreshKey<R = unknown, E = unknown, D = R, K extends QueryKey = QueryKey>(useQuery: UseQuery<R, E, D, K>) {
    return (options: UseQueryOptions<R, E, D, K>) => {
        const refreshKey = useRefreshKey();
        const useQueryWithMetadata = withMetadata(useQuery, { refreshKey });

        return useQueryWithMetadata(options) as UseQueryResult<D, E>;
    };
}

type RefreshKeyProviderProps = PropsWithChildren<{
    readonly refreshKey: string
}>;

const RefreshKeyProvider: FC<RefreshKeyProviderProps> = ({ children, refreshKey }) => <RefreshKeyContext.Provider value={refreshKey}>
    {children}
</RefreshKeyContext.Provider>;

export function withRefreshKeyProvider<P extends object>(options: RefreshKeyProviderProps) {
    return (Component: ComponentType<P>) => (props: P) => <RefreshKeyProvider {...options}>
        <Component {...props} />
    </RefreshKeyProvider>;
}

export default RefreshKeyProvider;
