import type {
    Route,
    Redirect,
    StartingPoint,
    UserState
} from "@core/application/router/contributables";
import {
    RouterContribution
} from "@core/application/router/contributables";
import { Contributions, Inject, Injectable, Named, PostConstruct } from "@core/application/ioc";

@Injectable
export class RouterRegistry {
    @Inject(Contributions) @Named(RouterContribution)
    private readonly contributions: RouterContribution[];

    private readonly routes: Map<string, Route> = new Map();
    private readonly redirects: Map<string, Redirect> = new Map();
    private readonly startingPoints: Map<string, StartingPoint> = new Map();

    @PostConstruct
    protected init(): void {
        this.contributions.forEach((contribution) => contribution.registerContributions(this));
    }

    public registerRoute(route: Route): void {
        const id = route.id ?? route.path;

        if (id === undefined) {
            throw new Error("Either id or path needs to be defined on route.");
        }

        if (this.routes.has(id)) {
            throw new Error(`There is already a route with id or path '${id}'.`);
        } else {
            this.routes.set(id, route);
        }
    }

    public unregisterRoute(route: Route) {
        const id = route.id ?? route.path;

        if (id === undefined) {
            throw new Error("Either id or path needs to be defined on route.");
        }

        if (this.routes.has(id)) {
            this.routes.delete(id);
        } else {
            throw new Error(`Route with id '${id}' does not exist.`);
        }
    }

    public getAllRoutes(): Route[] {
        return Array.from(this.routes.values());
    }

    public findRouteById(id: string): Route | undefined {
        return this.routes.get(id);
    }

    public registerRedirect(redirect: Redirect): void {
        const id = `${redirect.from}->${redirect.to}`;

        if (this.redirects.has(id)) {
            throw new Error(`There is already a redirect for ${JSON.stringify(redirect)}.`);
        }

        this.redirects.set(id, redirect);
    }

    public getAllRedirects(): Redirect[] {
        return Array.from(this.redirects.values());
    }

    public getAllStartingPoints(): StartingPoint[] {
        return Array.from(this.startingPoints.values());
    }

    public registerStartingPoint(startingPoint: StartingPoint): void {
        const { id } = startingPoint;

        if (this.startingPoints.has(id)) {
            throw new Error(`There is already a redirect for ${id}.`);
        }

        this.startingPoints.set(id, startingPoint);
    }

    public unregisterStartingPoint(idOrStartingPoint: string | StartingPoint): void {
        const id = typeof idOrStartingPoint === "string" ? idOrStartingPoint : idOrStartingPoint.id;

        if (this.startingPoints.has(id)) {
            this.startingPoints.delete(id);
        }
    }

    private findStartingPointByUserState(userState: UserState, loginStatus: boolean): StartingPoint | undefined {
        return this.getAllStartingPoints().find((startingPoint) => !startingPoint.default && startingPoint.canHandle(userState, loginStatus));
    }

    public findDefaultStartingPoint(): StartingPoint | undefined {
        return this.getAllStartingPoints().find((startingPoint) => startingPoint.default);
    }

    public getStartingPointByUserState(userState: UserState, loginStatus: boolean): StartingPoint {
        const startingPoint = this.findStartingPointByUserState(userState, loginStatus);

        if (startingPoint) return startingPoint;

        const defaultStartingPoint = this.findDefaultStartingPoint();

        if (defaultStartingPoint) return defaultStartingPoint;

        throw new Error(`There is neither a default nor a starting point for ${userState} with following login status: ${loginStatus}.`);
    }
}
