import { useEffect, useRef } from "react";
import { useLocation, useResolvedPath, useParams, useSearchParams, redirect, useBeforeUnload, createBrowserRouter, RouterProvider, generatePath, matchPath, useMatch, matchRoutes } from "react-router-dom";
import { useNavigate } from "@providers/NavigateFunctionProvider";
import { createSearchParams } from "react-router-dom";

export * from "react-router-dom";


const useLocationEffect = (callback) => {
    const location = useLocation();

    useEffect(() => {
        callback(location);
    }, [location, callback]);
};

const paramRe = /^:\w+$/;

const dynamicSegmentValue = 3;
const indexRouteValue = 2;
const emptySegmentValue = 1;
const staticSegmentValue = 10;
const splatPenalty = -2;
const isSplat = (s) => s === "*";
function computeScore(path, index) {
    const segments = path.split("/");
    let initialScore = segments.length;
    if (segments.some(isSplat)) {
        initialScore += splatPenalty;
    }
    if (index) {
        initialScore += indexRouteValue;
    }
    return segments
        .filter((s) => !isSplat(s))
        .reduce((score, segment) => {
            if (paramRe.test(segment)) {
                return score + dynamicSegmentValue;
            }
            if (segment === "") {
                return score + emptySegmentValue;
            }
            return score + staticSegmentValue;
        }, initialScore);
}

const joinPaths = (paths) => paths.join("/").replace(/\/\/+/g, "/");

function buildTree(routes) {
    const temp = {};

    routes.forEach((item) => {
        temp[item.id] = { ...item };
    });

    Object.keys(temp).forEach((id) => {
        if (id.lastIndexOf(".") > 0) {
            const parentId = id.substring(0, id.lastIndexOf("."));
            const parent = temp[parentId];

            if (parent) {
                if (!parent.children) {
                    parent.children = [];
                }
                parent.children.push(temp[id]);
            }
        }
    });

    return Object.keys(temp).reduce((acc, id) => {
        if (id.lastIndexOf(".") === -1) acc.push(temp[id]);
        return acc;
    }, []);
}

function flattenRoutes(
    routes,
    branches = [],
    parentsMeta = [],
    parentPath = "",
    parentRoute = {}
) {
    routes.forEach((route, index) => {
        if (typeof route.path !== "string" && !route.index && !route.children?.length) {
            throw new Error(
                "`path` or `index` must be provided in every route object",
            );
        }
        if (route.path && route.index) {
            throw new Error(
                "`path` and `index` cannot be provided at the same time",
            );
        }

        const meta = {
            relativePath: route.path || "",
            childrenIndex: index,
            parent: {
                path: parentPath,
                route: parentRoute
            },
            route,
        };
        if (meta.relativePath.charAt(0) === "/") {
            if (!meta.relativePath.startsWith(parentPath)) {
                throw new Error(
                    "The absolute path of the child route must start with the parent path",
                );
            }
            meta.relativePath = meta.relativePath.slice(parentPath.length);
        }
        const path = joinPaths([parentPath, meta.relativePath]);

        const routesMeta = parentsMeta.concat(meta);

        if (route?.children && route.children.length > 0) {
            if (route.index) {
                throw new Error("Index route cannot have child routes");
            }
            flattenRoutes(route.children, branches, routesMeta, path, route);
        }
        branches.push({
            path,
            score: computeScore(path, route.index),
            routesMeta,
        });
    });
    return branches;
}

function compareIndexes(a, b) {
    const siblings = a.length === b.length && a.slice(0, -1).every((n, i) => n === b[i]);
    return siblings ? a[a.length - 1] - b[b.length - 1] : 0;
}

function rankRoutes(
    routes,
) {
    return routes.sort((a, b) => (a.score !== b.score
        ? b.score - a.score // Higher score first
        : compareIndexes(
            a.routesMeta.map((meta) => meta.childrenIndex),
            b.routesMeta.map((meta) => meta.childrenIndex),
        )));
}

/**
 * @type {import("./common-router").useRemoveStepIdFromSearchParams}
 */
const useRemoveStepIdFromSearchParams = (stepId = "") => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.has("stepId") && searchParams.get("stepId") === stepId) {
            setSearchParams(() => {
                searchParams.delete("stepId");
                return new URLSearchParams({
                    ...Object.fromEntries(searchParams.entries())
                });
            });
        }
    }, [searchParams, setSearchParams, stepId]);
};

/**
 * @type {import("./common-router").useSearchParams}
 */
function useSearchParamsWithUpdate(defaultInit = {}) {
    const [searchParams, setSearchParams] = useSearchParams(defaultInit);
    const defaultSearchParams = useRef(createSearchParams(defaultInit));

    useEffect(() => {
        const update = {};
        let needUpdate = false;

        defaultSearchParams.current.forEach((v, k) => {
            if (!searchParams.has(k)) {
                update[k] = v;
                needUpdate = true;
            }
        });

        if (needUpdate) setSearchParams(update, { replace: true });
    }, [searchParams, setSearchParams]);

    return [searchParams, setSearchParams];
}

export { useRemoveStepIdFromSearchParams, useSearchParamsWithUpdate, useSearchParams, useLocation, useParams, useNavigate, useResolvedPath, useLocationEffect, redirect, useBeforeUnload, createBrowserRouter, RouterProvider, generatePath, matchPath, useMatch, matchRoutes, buildTree, flattenRoutes, rankRoutes };
