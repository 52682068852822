import React, { memo } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "@components/Modal.styles";

import {
    DialogContent,
} from "@material-ui/core";

export const Content = ({ children, classes, ...props }) => (
        <DialogContent
            id="modal-content"
            classes={{
                root: classes.content
            }}
            {...props}
        >
            {children}
        </DialogContent>
    );

Content.displayName = "Modal.Content";

export default memo(withStyles(styles, { name: "ModalContent" })(Content));
