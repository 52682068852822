import { getPath } from "@services/api.service";
import { auth, authWithToken } from "@services/auth.service";
import { actions as progress } from "@ducks/progress";
import { actions as header } from "@ducks/header";
import { DEFAULT_DESTINATION_USER } from "@components/application/navigation";

export const types = {
    AUTH_LOGIN: "AUTH/LOGIN",
    AUTH_LOAD_LOGIN_SUCCESS: "AUTH/LOAD_LOGIN_SUCCESS",
    AUTH_LOAD_LOGIN_FAIL: "AUTH/LOAD_LOGIN_FAIL",
    AUTH_STEP_ERROR: "AUTH/STEP_ERROR",
    AUTH_HIDE_TOAST: "AUTH/HIDE_TOAST",
    AUTH_LOGOUT_REQUESTED: "AUTH/LOG_OUT_REQUESTED",
    AUTH_LOGOUT_SUCCESS: "AUTH/LOG_OUT_SUCCESS",
    EMAIL_ERROR: "AUTH/EMAIL_ERROR",
    PASSWORD_ERROR: "AUTH/PASSWORD_ERROR",
    CLEAR_ERRORS: "AUTH/CLEAR_ERRORS",
    GENERIC_ERROR: "AUTH/GENERIC_ERROR",
    AUTH_AUTHENTICATION_CHANGE: "AUTH/AUTHENTICATION_CHANGE",
};

const errorMap = {
    BAD_EMAIL_SYNTAX: [types.EMAIL_ERROR],
    VIOLATED_PASSWORD_REQUIREMENTS: [types.PASSWORD_ERROR],
    EMAIL_AND_PASSWORD_NOT_OK: [types.EMAIL_ERROR, types.PASSWORD_ERROR],
    LOGIN_DISALLOWED_TEMPORARILY: [types.EMAIL_ERROR],
    LOGIN_CREDENTIALS_INCORRECT: [types.EMAIL_ERROR]
};

export const initialState = {
    loadedLogin: null,
    logoutRequest: null,
    authentication: { state: "UNKNOWN" }
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_LOAD_LOGIN_SUCCESS:
            return { ...state, loadedLogin: action.loadedLogin };
        case types.AUTH_LOAD_LOGIN_FAIL:
            return { ...state, loadedLogin: null };
        case types.AUTH_STEP_ERROR:
            return { ...state, stepError: action.stepError, toastVisible: true };
        case types.AUTH_HIDE_TOAST:
            return { ...state, toastVisible: false };
        case types.EMAIL_ERROR:
            return { ...state, emailError: action.emailError };
        case types.PASSWORD_ERROR:
            return { ...state, passwordError: action.emailError };
        case types.CLEAR_ERRORS:
            return { ...state, emailError: null, passwordError: null, retypePassError: null };
        case types.AUTH_LOGOUT_REQUESTED:
            return { ...state, logoutRequest: action.request };
        case types.AUTH_LOGOUT_SUCCESS:
            return { ...state, logoutRequest: null };
        case types.AUTH_AUTHENTICATION_CHANGE:
          return { ...state, authentication: action.authentication };

        default:
            return state;
    }
};

export const loadLogin = (state) => state.auth.loadedLogin;
export const getEmailError = (state) => state.auth.emailError;
export const getPasswordError = (state) => state.auth.passwordError;
export const getLogoutRequest = (state) => state.auth.logoutRequest;
export const getAuthentication = (state) => state.auth.authentication;

export const actions = {
    loadLogin: () => (dispatch) => {
        dispatch(progress.setLoading(true));
        getPath([{ path: "login-screen" }]).then((loadedLogin) => {
            dispatch(actions.loadLoginSuccess(loadedLogin));
            dispatch(progress.setLoading(false));
            dispatch(header.updateTitle((loadedLogin.dictionary && loadedLogin.dictionary.pageTitle) ? loadedLogin.dictionary.pageTitle : ""));
        }).catch((err) => {
            dispatch(actions.loadLoginFail(err));
            dispatch(progress.setLoading(false));
        });
    },
    login: (user, pass) => (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(progress.setLoading(true));
        dispatch(actions.clearErrors());
        auth(user, pass)
            .then((re) => {
                dispatch(actions.authenticationEstablished("USER"));
                dispatch(progress.setLoading(false));

                resolve();
            })
            .catch((err) => {
                dispatch(progress.setLoading(false));
                if (err.response.body && errorMap[err.response.body.errorType]) {
                    errorMap[err.response.body.errorType].forEach((errorType) => dispatch(actions.triggerError(errorType, getState().auth.loadedLogin.dictionary[err.response.body.errorType])));
                } else {
                    dispatch(actions.triggerError(types.GENERIC_ERROR, getState().auth.loadedLogin.dictionary.genericError));
                }
                reject();
                dispatch(actions.authenticationFailed("FAILED"));
                dispatch(progress.setLoading(false));
            });
    }),
    refresh: (token) => (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(progress.setLoading(true));
        dispatch(actions.clearErrors());
        getPath([{ path: "login-context" }, { path: "logout-and-end-link" }])
        .then(() => {
            dispatch(progress.setLoading(true));
            auth(null, token)
                .then(() => {
                    dispatch(actions.authenticationEstablished("USER"));
                    dispatch(progress.setLoading(false));
                    resolve();
                }).catch(() => {
                    dispatch(actions.authenticationFailed("FAILED"));
                    dispatch(progress.setLoading(false));
                    reject();
                }).finally(() => {
                    window.location.replace("/"); // required as we start with a valid session and open application!
                });
            });
    }),
    logout: () => (dispatch, getState) => {
        dispatch(progress.setLoading(true));
        return getPath([{ path: "login-context" }, { path: "logout-and-end-link" }])
            .then(() => {
                dispatch(actions.authenticationLost(getLogoutRequest(getState())?.cause));
                dispatch({ type: types.AUTH_LOGOUT_SUCCESS });
                dispatch(progress.setLoading(false));
            });
    },
    requestLogout: (cause) => (dispatch) => dispatch(({ type: types.AUTH_LOGOUT_REQUESTED, request: { cause } })),
    loadLoginSuccess: (loadedLogin) => ({ type: types.AUTH_LOAD_LOGIN_SUCCESS, loadedLogin }),
    loadLoginFail: (error) => ({ type: types.AUTH_LOAD_LOGIN_FAIL, error }),
    displayStepError: (stepError) => ({ type: types.AUTH_STEP_ERROR, stepError }),
    hideToast: () => ({ type: types.AUTH_HIDE_TOAST }),
    triggerError: (type, emailError) => ({ type, emailError }),
    clearErrors: () => ({ type: types.CLEAR_ERRORS }),
    authenticationLost: (cause) => ({ type: types.AUTH_AUTHENTICATION_CHANGE, authentication: { state: "LOST", cause } }),
    authenticationEstablished: (cause) => ({ type: types.AUTH_AUTHENTICATION_CHANGE, authentication: { state: "ACTIVE", cause } }),
    authenticationFailed: (cause) => ({ type: types.AUTH_AUTHENTICATION_CHANGE, authentication: { state: "FAILED", cause } }),
};
