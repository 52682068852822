import { getPath } from '@services/api.service';
import { actions as progress } from '@ducks/progress';
import { actions as header } from '@ducks/header';

export const types = {
    DEVICE_LEGITIMATION_PAGE_LOAD_SUCCESS: 'DEVICE_LEGITIMATION_PAGE/LOAD_SUCCESS',
    DEVICE_LEGITIMATION_PAGE_LOAD_FAIL: 'DEVICE_LEGITIMATION_PAGE/LOAD_FAIL'
};

export const initialState = {
    deviceLegitimationData: null,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.DEVICE_LEGITIMATION_PAGE_LOAD_SUCCESS:
            return { ...state, deviceLegitimationData: action.data }
        case types.DEVICE_LEGITIMATION_PAGE_LOAD_FAIL:
            return { ...state, error: action.err }
        default:
            return state;
    }
};

const privateActions = {
    loadDeviceLegitimationPageSuccess: data => ({
        type: types.DEVICE_LEGITIMATION_PAGE_LOAD_SUCCESS, data
    }),
    loadDeviceLegitimationPageFail: err => ({
        type: types.DEVICE_LEGITIMATION_PAGE_LOAD_FAIL, err
    }),
};

export const actions = {
    loadDeviceLegitimationPage: () => (dispatch) => {
        dispatch(progress.setLoading(true));
        return getPath([{ path: 'device-legitimation' }])
            .then(re => {
                dispatch(privateActions.loadDeviceLegitimationPageSuccess(re));
                dispatch(header.updateTitle((re.dictionary && re.dictionary.pageTitle) ? re.dictionary.pageTitle : ''))
                dispatch(progress.setLoading(false));
            })
            .catch(err => {
                dispatch(privateActions.loadDeviceLegitimationPageFail(err));
                dispatch(progress.setLoading(false));
            });
    }
};

export const getDeviceLegitimationData = state => state.deviceLegitimation.deviceLegitimationData;
