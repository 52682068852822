import type { Implementations } from "@common/config/implementations";

import {
    useHybridOnboardingPolicyTestContentQuery,
    usePoliciesContentQuery,
    usePolicyTestByAggregateIdContentQuery,
    useScreenServicePoliciesContentQuery
} from "@core/application/policies/hooks";

import {
    useInvestmentProfileHistoryUrlQuery,
    useInvestmentProfileHistoryUrlQueryImpl,
    useInvestmentProfileTileDictionaryQuery
} from "@hooks/investment-profile";

import {
    useCustomerInvestmentStrategyFeasibilityDictionaryQuery,
    useCustomerInvestmentStrategyFeasibilityDictionaryQueryImpl,
} from "@bounded-context/customer-investment-strategy/hooks/customer-investment-strategy";

import {
    useCustomerHomeDictionaryQuery,
} from "@hooks/dashboard";


import {
    useCustomerDashboardTransactionsDictionaryQuery,
    useCustomerTransactionsDictionaryQuery,
} from "@bounded-context/portfolio-analysis/hooks/transactions";

import { useCustomerSupportContactDictionaryQuery, useSupportContactDataDictionaryQuery } from "@bounded-context/follow-up-communication/hooks/support-contact-data";



import { usePostboxPDFIconQuery } from "@bounded-context/postbox/contributables";
import { usePostboxIconQuery } from "@bounded-context/postbox/hooks";
import { useScreenServiceStaticInformationDictionaryQuery, useStaticInformationDictionaryQuery } from "@hooks/static-information";

export * from "@common/config/implementations";

const implementations: Implementations = {
    hooks: {
        [usePoliciesContentQuery]: useScreenServicePoliciesContentQuery,
        [usePolicyTestByAggregateIdContentQuery]: useHybridOnboardingPolicyTestContentQuery,
        [useInvestmentProfileHistoryUrlQuery]: useInvestmentProfileHistoryUrlQueryImpl,
        [useCustomerInvestmentStrategyFeasibilityDictionaryQuery]: useCustomerInvestmentStrategyFeasibilityDictionaryQueryImpl,
        [useInvestmentProfileTileDictionaryQuery]: useCustomerHomeDictionaryQuery,
        [useCustomerDashboardTransactionsDictionaryQuery]: useCustomerTransactionsDictionaryQuery,
        [useCustomerSupportContactDictionaryQuery]: useSupportContactDataDictionaryQuery,
        [usePostboxPDFIconQuery]: usePostboxIconQuery,
        [useStaticInformationDictionaryQuery]: useScreenServiceStaticInformationDictionaryQuery,
    }
};

export default implementations;
