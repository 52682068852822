import qs from "query-string";

export const Modules = Object.freeze({
  PRODUCT_PRESENTATION: Symbol("product-presentation"),
  REGISTRATION: Symbol("registration"),
  TOPIC_SELECTION: Symbol("topic-selection"),
  RISK_PROFILE: Symbol("risk-profile"),
  DASHBOARD: Symbol("dashboard"),
  INVESTMENT_ASSISTANT: Symbol("investment-assistant"),
  INVESTMENT_AMOUNT: Symbol("investment-amount"),
  INVESTMENT_PROFILE: Symbol("investment-profile"),
  PROMOTION_CODES: Symbol("promotion-codes"),
});

export const asParams = (paramsOrSearch) => {
  if (typeof paramsOrSearch === "object") {
    return paramsOrSearch;
  }

  return qs.parse(paramsOrSearch, { ignoreQueryPrefix: true });
};

export const addParam = (paramsOrSearch, additionalParams) => {
  const params = asParams(paramsOrSearch);
  return { ...params, ...additionalParams };
};

export const removeParams = (paramsOrSearch, paramsToRemove) => {
  const params = asParams(paramsOrSearch);
  paramsToRemove.forEach((p) => delete params[p]);

  return params;
};

export const asSearch = (paramsOrSearch) => {
  if (typeof paramsOrSearch === "string") {
    return paramsOrSearch;
  }

  return `?${qs.stringify(paramsOrSearch)}`;
};
