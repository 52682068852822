import type { FC, PropsWithChildren } from "react";
import React, { type ComponentType, useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { Settings } from "luxon";
import store from "@ducks";

import { PropertiesProvider } from "@providers/PropertiesProvider";
import ContainerProvider from "@core/application/ioc/providers";
import ReactRouterNavigateFunctionProvider from "@providers/ReactRouterNavigateFunctionProvider";
import RegistryGenerateRoutePathProvider from "@providers/RegistryGenerateRoutePathProvider";
import withAll from "lodash/flowRight";
import { ImplementationsProvider } from "@providers/ImplementationsProvider";
import appImplementations from "@config/implementations";
import { useRedirects, useRoutes } from "@core/application/router/hooks";
import { createBrowserRouter, RouterProvider } from "@hooks/router";
import GlobalErrorBoundary from "@modules/error/components/GlobalErrorBoundary";
import ApplicationProvider from "@providers/ApplicationProvider";
import { withLazyMotion } from "@components/transitions/LazyMotion";
import withStrictMode from "@components/withStrictMode";

/**
 * Untested workaround attempt for WLF-545 (english week days, month names etc.
 * within date picker in MSIE and Edge).
 * Apparently the locale supplied to MuiPickersUtilsProvider is not correctly used
 * by luxon to create luxon DateTime
 * instances. This forces luxon to always create instances with german locale.
 */

Settings.defaultLocale = "de";

const Router = () => {
    const routes = useRoutes();
    const redirects = useRedirects();
    const router = useMemo(() => createBrowserRouter([...routes, ...redirects]), [routes]);

    return <RouterProvider router={router}/>;
};

export const ApplicationWrapper: FC<PropsWithChildren & { ErrorBoundary?: ComponentType<PropsWithChildren> }> = ({ children, ErrorBoundary = GlobalErrorBoundary }) => {
    return (
        <ApplicationProvider>
            <ErrorBoundary>
                <ReactRouterNavigateFunctionProvider>
                    <ReduxProvider store={store}>
                        <ImplementationsProvider variations={appImplementations}>
                            <PropertiesProvider>
                                <RegistryGenerateRoutePathProvider>
                                    <ContainerProvider>
                                        {children}
                                    </ContainerProvider>
                                </RegistryGenerateRoutePathProvider>
                            </PropertiesProvider>
                        </ImplementationsProvider>
                    </ReduxProvider>
                </ReactRouterNavigateFunctionProvider>
            </ErrorBoundary>
        </ApplicationProvider>
    );
};

const Application = () => (
    <ApplicationWrapper>
        <Router/>
    </ApplicationWrapper>
);

export default withAll(
    withStrictMode,
    withLazyMotion
)(Application);
