import { useParams, generatePath } from "@hooks/router";
import { useInject } from "@core/application/ioc/hooks";
import { RouterRegistry } from "@core/application/router/services";
import { type PropsWithChildren, type FC, useCallback } from "react";
import { type Route } from "@core/application/router/contributables";

import GenerateRoutePathProvider from "@providers/GenerateRoutePathProvider";

// Currently, does not support nested routes.
function useGenerateRoutePath() {
    const params = useParams();
    const registry = useInject(RouterRegistry);

    return useCallback((routeOrRouteId: Route | string, paramsOverrides?: Record<string, unknown>) => {
        const route = typeof routeOrRouteId === "string" ? registry.findRouteById(routeOrRouteId) : routeOrRouteId;

        if (route === undefined) {
            throw new Error(`Route with id '${routeOrRouteId}' does not exist.`);
        }

        if (route.path === undefined) {
            throw new Error(`Route with id '${route.id}' does not have a path.`);
        }

        return generatePath(route.path, { ...params, ...paramsOverrides });
    }, [params, registry]);
}

const RegistryGenerateRoutePathProvider: FC<PropsWithChildren> = ({ children }) => {
    return <GenerateRoutePathProvider generateRoutePathFactory={useGenerateRoutePath}>
        {children}
    </GenerateRoutePathProvider>;
};

export default RegistryGenerateRoutePathProvider;
