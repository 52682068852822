import { actions as progress } from '@ducks/progress';
import { getPath, changeEmail } from '@services/api.service';
import { actions as header } from '@ducks/header';

export const types = {
    NEW_EMAIL_LOAD: 'NEW_EMAIL/LOAD',
    NEW_EMAIL_LOAD_SUCCESS: 'NEW_EMAIL/LOAD_SUCCESS',
    NEW_EMAIL_LOAD_FAIL: 'NEW_EMAIL/LOAD_FAIL',
    NEW_EMAIL_CHANGED_SUCCESS: 'NEW_EMAIL/CHANGED_SUCCESS',
    NEW_EMAIL_CHANGED_FAIL: 'NEW_EMAIL/CHANGED_FAIL',
    EMAIL_ERROR: 'NEW_EMAIL/EMAIL_ERROR',
    CLEAR_ERRORS: 'NEW_EMAIL/CLEAR_ERRORS',
    UNKNOWN_ERROR: 'NEW_EMAIL/UNKNOWN_ERROR'
}

const errorMap = {
    BAD_EMAIL_SYNTAX: [types.EMAIL_ERROR],
    EMAIL_NOT_WHITELISTED: [types.EMAIL_ERROR],
    DUPLICATE_EMAIL: [types.EMAIL_ERROR],
    UNKNOWN_ERROR: [types.EMAIL_ERROR]
}

export const initialState = {
    error: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.NEW_EMAIL_LOAD_SUCCESS:
            return { ...state, newEmailData: action.newEmailData }
        case types.NEW_EMAIL_LOAD_FAIL:
            return { ...state, error: action.error }
        case types.NEW_EMAIL_CHANGED_SUCCESS:
            return { ...state }
        case types.NEW_EMAIL_CHANGED_FAIL:
            return { ...state, error: action.error };
        case types.EMAIL_ERROR:
            return { ...state, emailError: action.emailError };
        case types.CLEAR_ERRORS:
            return {...state, emailError: null };
        default:
            return state;
        }
}

export const actions = {
    changeEmail: (email) => (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(actions.clearErrors());
        changeEmail(email)
            .then(re => {
                dispatch(actions.changeEmailSuccess());
                resolve();
            })
            .catch(err => {
                if (err.response.body && errorMap[err.response.body.errorType]) {
                    errorMap[err.response.body.errorType].forEach(errorType => dispatch(actions.triggerError(errorType, getState().newEmail.newEmailData.dictionary[err.response.body.errorType])));
                }
                else {
                    dispatch(actions.triggerError(types.UNKNOWN_ERROR, getState().newEmail.newEmailData.dictionary.UNKNOWN_ERROR));
                }

                reject();
            })
    }),
    loadNewEmail: () => (dispatch) => {
        dispatch(progress.setLoading(true));
        getPath([{path: "check-email"}, {path: "changeEmail"}])
            .then(loadedNewEmail => {
                dispatch(actions.loadNewEmailSuccess(loadedNewEmail));
                dispatch(header.updateTitle((loadedNewEmail.dictionary && loadedNewEmail.dictionary.pageTitle) ? loadedNewEmail.dictionary.pageTitle : ''));
                dispatch(progress.setLoading(false));
            })
            .catch(err => {
            dispatch(actions.loadNewEmailFail(err));
            dispatch(progress.setLoading(false));
        });
    },

    loadNewEmailSuccess: (newEmailData) => ({ type: types.NEW_EMAIL_LOAD_SUCCESS, newEmailData }),
    loadNewEmailFail: (error) => ({ type: types.NEW_EMAIL_LOAD_FAIL, error }),
    changeEmailSuccess: () => ({ type: types.NEW_EMAIL_CHANGED_SUCCESS }),
    changeEmailFail: (error) => ({ type: types.NEW_EMAIL_CHANGED_FAIL, error }),
    triggerError: (type, emailError) => ({ type, emailError }),
    clearErrors: () => ({type: types.CLEAR_ERRORS})
}

export const getNewEmailData = (state) => state.newEmail.newEmailData;
export const getEmailError = (state) => state.newEmail.emailError;
