import { usePolicyBasedAccessControl } from "@core/application/policies/hooks";
import { Policy } from "@core/application/policies/protocol";
import { useMemo } from "react";
import { useHomeLinksQuery } from "@hooks/home";
import { asDataQuery, withSessionScope } from "@hooks/higher-order-hooks";
import { apiRequest } from "@services/api";
import { useQuery } from "react-query";

const useLoginQuery = (options) => {
    const { data: url } = useHomeLinksQuery("user-email-context");

    const queryKey = ["bc_iam", "identity", { url }];

    const queryFn = async () => {
        const response = await apiRequest
            .set("Accept", "application/hal+json")
            .get(`${url}/self`)
            .ok((response) => response.status === 200 || response.status === 403)
            .send();
        return response.status === 200 ? response : false;
    };

    const useSessionScopedQuery = withSessionScope(useQuery);
    return useSessionScopedQuery({ queryKey, queryFn, ...options });
};

export const useCurrentLoginQuery = asDataQuery(useLoginQuery);

export const useIsLoggedIn = () => {
    const checkAccessGranted = usePolicyBasedAccessControl();

    return useMemo(() => checkAccessGranted(Policy.EMAIL_READ), [checkAccessGranted]);
};
