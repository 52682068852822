import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { forwardRef, useId, useMemo } from "react";
import Card from "@material-ui/core/Card";
import { useProperties } from "@providers/PropertiesProvider";
import Color from "color";
import { useDataTestId } from "@hooks/utils";

interface CardContainerProps {
  className?: string
  disabled?: boolean
  button?: boolean
  selected?: boolean
  forceActive?: boolean
  size?: string
  [key: string]: any
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        height: "100%",
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        textDecoration: "none",
        // @ts-expect-error lazy
        boxShadow: ({ disabled, button, selected }) => {
            if (selected && button) return `0 0 0 4px ${Color(theme.palette.success.main).desaturate(0.5).fade(0.85).toString()}`;
            return theme.shadows[1];
        },
        transition: `${theme.transitions.create(["background-color", "transform", "height", "width", "filter", "box-shadow"], {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeInOut
        })}`,
        "&:hover, &:focus, &:active": {
            // @ts-expect-error 12lazy34
            filter: ({ disabled, button }) => {
                if (disabled && button) return "contrast(92.5%)";
                if (!disabled && button) return "contrast(97.5%)";
            },
            // @ts-expect-error lazy
            cursor: ({ disabled, button }) => {
                if (disabled) return "not-allowed";
                return !disabled && button ? "pointer" : "inherit";
            },
        },
    },
}), { name: "CardContainer" });

const CardContainer = forwardRef<HTMLDivElement, CardContainerProps>(({
    className,
    disabled: isDisabled = false,
    button: isButton = false,
    selected: isSelected = false,
    forceActive: isForceActive = false,
    size = "default",
    children,
    id,
    ...props
}, ref) => {
    const dataTestId = useDataTestId(id);
    const properties: any = useProperties();
    const disabled = useMemo(() => ((properties?.disabled || isDisabled) ?? false), [properties]);

    const classes = useStyles({
        disabled: isDisabled && disabled && !isForceActive,
        button: isButton,
        selected: isSelected,
        ...props
    });

    return (
        <Card
            classes={classes}
            className={`${classes.root} ${className}`}
            ref={ref}
            {...props}
            {...dataTestId}
        >
            {children}
        </Card>
    );
});

CardContainer.displayName = "CardContainer";

export default CardContainer;
