export default {
    toolbarWrapper: {
        width: "100%"
    },
    overflowMenu: {
        "& > .MuiIconButton-root": {
            color: "inherit"
        }
    },
    overflowMenuItem: {
        padding: [4, 8],
        "&&:before": {
            height: 0,
        }
    }
};
