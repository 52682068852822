import withFooter from "@components/withFooter";
import { useErrorData } from "@modules/error/hooks";
import { useGlobalDataDictionaryQuery } from "@hooks/global";
import { usePageTitle } from "@hooks/header";
import { useLocationEffect } from "@hooks/router";
import withAll from "lodash/flowRight";

import DialogNoConnection from "@modules/error/components/DialogNoConnection";
import DialogUnknownError from "@modules/error/components/DialogUnknownError";

import { withHeaderWithoutMenu } from "@components/withHeader";

import { DEFAULT_ERROR_DIALOG_TEXT, ERROR_TYPES } from "@ducks/error-boundary";

import { fireExceptionEvent } from "@services/tracking.service";
import get from "lodash/get";
import React, { useCallback } from "react";
import ApplicationProvider from "@providers/ApplicationProvider";
import ReactRouterNavigateFunctionProvider from "@providers/ReactRouterNavigateFunctionProvider";
import { ImplementationsProvider } from "@providers/ImplementationsProvider";
import appImplementations from "@config/implementations";
import { PropertiesProvider } from "@providers/PropertiesProvider";
import ContainerProvider from "@core/application/ioc/providers";

const ErrorHandler = ({ errorData = { errorType: false } }) => {
    const { data: dictionary, isLoading, isIdle } = useGlobalDataDictionaryQuery();
    const { unsetErrorData } = useErrorData();

    const errorType = get(errorData, "errorType", false);
    const errorMessage = get(errorData, "errorCaught.message", null);

    const DialogToRender = errorType === ERROR_TYPES.LOW_LEVEL_NETWORK_ERROR ? DialogNoConnection : DialogUnknownError;

    const onDidChangeLocation = useCallback(() => {
        if (errorType) {
            const payload = { type: errorType, message: errorMessage };

            fireExceptionEvent(payload);
            console.error(payload);
        }

        return unsetErrorData();
    }, [errorType, errorMessage]);

    usePageTitle("");
    useLocationEffect(onDidChangeLocation);

    if (isLoading || isIdle) return null;

    return (
        <DialogToRender
            dict={dictionary ?? DEFAULT_ERROR_DIALOG_TEXT}
        />
    );
};

const ErrorComponent = () => {
    const { data: dictionary, isLoading, isIdle } = useGlobalDataDictionaryQuery();
    usePageTitle("Fehler!");

    if (isLoading || isIdle) return null;

    return (
        <DialogUnknownError
            dict={dictionary ?? DEFAULT_ERROR_DIALOG_TEXT}
        />
    );
};
export const UnknownErrorComponent = () => {
    return (
        <ApplicationProvider>
            <ReactRouterNavigateFunctionProvider>
                <ImplementationsProvider variations={appImplementations}>
                    <PropertiesProvider>
                        <ContainerProvider>
                            <ErrorComponent />
                        </ContainerProvider>
                    </PropertiesProvider>
                </ImplementationsProvider>
            </ReactRouterNavigateFunctionProvider>
        </ApplicationProvider>
    );
};

export default withAll(
    withHeaderWithoutMenu,
    withFooter,
)(ErrorHandler);
