import React from 'react';

export const withStrictMode = Component => props => {
    return (
        <React.StrictMode>
            <Component {...props} />
        </React.StrictMode>
    )
};

export default withStrictMode;
