export default {
    underline: {
        "&::before": {
            border: 0
        },
        "&::after": {
            border: 0
        }
    },
};
