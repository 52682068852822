export const types = {
    UPDATE: 'HEADER/UPDATE',
}

export const initialState = {
    title: '',
    loggedIn: false,
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE:
            return { ...state, title: action.title, subtitle: action.subtitle }
        default:
            return state;
    }
}

export const actions = {
    updateTitle: (title, subtitle = null) => ({type: types.UPDATE, title, subtitle})
}

export const getTitle = (state) => state.header.title;
export const getSubtitle = (state) => state.header.subtitle;
