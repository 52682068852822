import { ContainerModule, injectable, Container, interfaces, inject, named, postConstruct, preDestroy } from "inversify";

import Bind = interfaces.Bind;
import ServiceIdentifier = interfaces.ServiceIdentifier;
import IContainer = interfaces.Container;

export const Injectable = injectable();

export const PostConstruct = postConstruct();
export const PreDestroy = preDestroy();

export const Contributions = Symbol("Contributions");

export const bindContributable = <T extends object>(bind: Bind, symbolOfContributable: symbol) => {
    function collectContributions(container: IContainer, collection: T[]): T[] {
        if (container.isBound(symbolOfContributable)) {
            collection.push(...container.getAll<T>(symbolOfContributable));
        }

        if (container.parent === null) {
            return collection;
        }

        return collectContributions(container.parent, collection);
    }

    bind(Contributions)
        .toDynamicValue((ctx) => collectContributions(ctx.container, []))
        .inSingletonScope()
        .whenTargetNamed(symbolOfContributable);
};

export { ContainerModule, Container, inject as Inject, named as Named };
export type { ServiceIdentifier };
