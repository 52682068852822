/*
 * (c)Mindit 2018
 * Last modified: Friday, 22nd June 2018 2:23:41 pm
 * Author: Valentin Raduti (valentin.raduti@mindit.io>)
 */
export const types = {
    LOADING: "PROGRESS/LOADING",
    ERROR: "PROGRESS/ERROR",
    NEXT_BUTTON_DISABLED: "PROGRESS/NEXT_BUTTON_DISABLED",
};

const defaultText = "Bitte warten";

export const initialState = {
    loading: false,
    error: null,
    text: defaultText,
    isNextButtonDisabled: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.LOADING:
            return { ...state, error: null, loading: action.loading, text: action.text };
        case types.ERROR:
            return { ...state, error: action.error };
        case types.NEXT_BUTTON_DISABLED:
            return { ...state, isNextButtonDisabled: action.isDisabled };
        default:
            return state;
    }
};

export const actions = {
    setLoading: (loading, text = defaultText) => ({ type: types.LOADING, loading, text }),
    raiseError: error => ({ type: types.ERROR, error }),
    setNextButtonDisabled: isDisabled => ({ type: types.NEXT_BUTTON_DISABLED, isDisabled }),
};

export const isLoading = (state) => state.progress.loading;
export const isNextButtonDisabled = (state) => state.progress.isNextButtonDisabled;
export const getError = (state) => state.progress.error;
export const getText = (state) => state.progress.text;
