
export const Container = (props) => {
    const staggerIn = props?.staggerIn ?? 0.5;
    const delayIn = props?.delayIn ?? 0.5;
    const staggerOut = props?.staggerOut ?? 0.5;

    return {
        initial: {
            transition: {
                staggerChildren: staggerIn,
                delayChildren: delayIn,
                staggerDirection: 1,
            },
        },
        animate: {
            transition: {
                staggerChildren: staggerIn,
                delayChildren: delayIn,
                staggerDirection: 1,
            },
        },
        exit: {
            transition: {
                staggerChildren: staggerOut,
                delayChildren: delayIn,
                staggerDirection: 1,
            },
        },
    };
};
