import { useNavigate } from "react-router-dom";

import type { PropsWithChildren, FC, ComponentType } from "react";

import NavigateFunctionProvider from "@providers/NavigateFunctionProvider";

const ReactRouterNavigateFunctionProvider: FC<PropsWithChildren> = ({ children }) => <NavigateFunctionProvider navigateFactory={useNavigate}>
    {children}
</NavigateFunctionProvider>;

export const withReactRouterNavigateFunctionProvider = <P extends object = object>() => (Component: ComponentType<P>) => (props: P) => <ReactRouterNavigateFunctionProvider>
    <Component {...props} />
</ReactRouterNavigateFunctionProvider>;

export default ReactRouterNavigateFunctionProvider;
