import { QueryClientProvider as QueryClientProviderBase, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const defaultOptions = {
    queries: {
        select: (response) => response.body,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        keepPreviousData: true,
        retry: 0,
        retryOnMount: false,
        cacheTime: Infinity
    }
};

const queryClient = new QueryClient({ defaultOptions });

const QueryClientProvider = ({ children, disableDevtools = false }) => <QueryClientProviderBase client={queryClient}>
    {children}
    {/* eslint-disable-next-line no-undef */}
    {process.env.NODE_ENV !== "production" && !disableDevtools && <ReactQueryDevtools position={"bottom-right"}/>}
</QueryClientProviderBase>;

export const withQueryClientProvider = (Component) => (props) => <QueryClientProvider>
    <Component {...props}/>
</QueryClientProvider>;

export default QueryClientProvider;
