import { breakpoints } from "@theme/breakpoints";
import shape from "@theme/shape";

export default {
    root: {
        position: "relative",
        textTransform: "none",
        borderRadius: shape.borderRadius,
        [breakpoints.up("600")]: {
            minWidth: "auto",
        },
        "&$selected": {
            fontWeight: "700",
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
            "&:before": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: 3,
                backgroundColor: "currentColor",
                borderRadius: 3
            }
        }
    },
    wrapper: {
        textWrap: "nowrap"
    }
};
