import { useQuery } from "react-query";
import { useHomeLinksQuery } from "@hooks/home";
import { asDictionaryQuery, withEnabledCondition, withPageScope } from "@hooks/higher-order-hooks";
import { apiRequest } from "@services/api";

export * from "@common/bounded-context/follow-up-communication/hooks/support-contact-data";

const useSupportContactDataQuery = (options) => {
    const { data: url } = useHomeLinksQuery("support-contact-data");

    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);

    const queryKey = ["support-contact-data", url];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/json");

    return useConditionalQuery({ queryKey, queryFn, ...options });
};

export const useSupportContactDataDictionaryQuery = asDictionaryQuery(useSupportContactDataQuery);
