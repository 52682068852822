import type { FC, PropsWithChildren } from "react";
import type { useQuery, QueryKey, UseQueryOptions, UseQueryResult } from "react-query";

import { createContext, useContext } from "react";
import { withRefetchInterval } from "@hooks/higher-order-hooks";

type UseQuery<R = unknown, E = unknown, D = R, K extends QueryKey = QueryKey> = typeof useQuery<R, E, D, K>;

const RefreshPeriodContext = createContext<number | false>(false);

export function useRefreshPeriod() {
    return useContext(RefreshPeriodContext);
}

export function withRefreshPeriod<R = unknown, E = unknown, D = R, K extends QueryKey = QueryKey>(useQuery: UseQuery<R, E, D, K>) {
    return (options: UseQueryOptions<R, E, D, K>) => {
        const refreshPeriod = useRefreshPeriod();
        const useQueryWithRefetchInterval = withRefetchInterval(useQuery, refreshPeriod) as UseQuery<R, E, D, K>;

        return useQueryWithRefetchInterval(options) as UseQueryResult<D, E>;
    };
}

type RefreshPeriodProviderProps = PropsWithChildren<{
    readonly refreshPeriod: number | false
}>;

const RefreshPeriodProvider: FC<RefreshPeriodProviderProps> = ({ children, refreshPeriod }) => <RefreshPeriodContext.Provider value={refreshPeriod}>
    {children}
</RefreshPeriodContext.Provider>;

export default RefreshPeriodProvider;
