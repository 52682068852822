import { createGlobalState, useGlobalState } from "@hooks/state";
import { useMemo } from "react";

export const ERROR_TYPES = Object.freeze({
    LOW_LEVEL_NETWORK_ERROR: "LOW_LEVEL_NETWORK_ERROR_CAUGHT",
    SERVER_NETWORK_ERROR: "SERVER_ERROR_CAUGHT",
    FRONTEND_ERROR: "FRONTEND_ERROR_CAUGHT"
});

const errorState = createGlobalState({
    errorType: undefined,
    errorCaught: undefined
});

export const useErrorData = () => {
    const state = useGlobalState(errorState);
    const errorData = state.get({ noproxy: true });

    const setErrorData = useMemo(() => (errorType, errorCaught) => {
        state.set({ errorType, errorCaught });
    }, []);

    const unsetErrorData = useMemo(() => () => {
        state.set({ errorType: undefined, errorCaught: undefined });
    }, []);

    return { errorData, setErrorData, unsetErrorData };
};
