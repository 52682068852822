import { actions as header } from "@ducks/header";
import {createGlobalState, useGlobalState} from "@hooks/state";
import {useEffect, useMemo} from "react";
import { useDispatch } from "react-redux";

export const usePageTitle = pageTitle => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(header.updateTitle(pageTitle ?? ''));
    }, [dispatch, pageTitle]);
};

const initialHeaderState = {
    visible: false,
}

const headerState = createGlobalState({
    data: {
        ...initialHeaderState
    }
});

export const useHeaderData = () => {
    const state = useGlobalState(headerState);
    const headerData = state.data;

    const setHeaderData = useMemo(() => ({visible}) => {
        state.data.set({ visible });
    }, [state.data]);

    const resetHeaderData = useMemo(() => () => {
        state.data.set({ ...initialHeaderState });
    }, [state.data]);

    return { headerData: headerData, setHeaderData, resetHeaderData };
};
