import type { FC, PropsWithChildren } from "react";
import { createContext } from "react";
import type { Container } from "@core/application/ioc";
import { useContainerQuery } from "@core/application/ioc/hooks";

export const ContainerContext = createContext<Container | undefined>(undefined);

const ContainerProvider: FC<PropsWithChildren> = ({ children }) => {
    const { isLoading, isIdle, data: container } = useContainerQuery({ keepPreviousData: false });

    if (isLoading || isIdle) return null;

    return <ContainerContext.Provider value={container}>
        {children}
    </ContainerContext.Provider>;
};

export default ContainerProvider;
