import React from "react";
import Header from "@components/Header";

const defaultHeaderProps = ({
    showMenu: true,
});

export const withHeader = Component => props => {
    return (
        <>
            <Header headerProps={{ ...defaultHeaderProps }} {...props} />
            <Component {...props} />
        </>
    );
};

export const withHeaderWithoutMenu = Component => props => {
    return (
        <>
            <Header headerProps={{ ...defaultHeaderProps, showMenu: false }} {...props} />
            <Component {...props} />
        </>
    );
};

export default withHeader;
