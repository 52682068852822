import type { To, NavigateOptions } from "react-router-dom";

import { type FC, type PropsWithChildren, createContext, useContext } from "react";

type NavigateFunction = (to: To, options?: NavigateOptions) => void;

type NavigateFunctionFactory = () => NavigateFunction;

const NavigateFunctionContext = createContext<NavigateFunctionFactory | undefined>(undefined);

export function useNavigate() {
    const navigationFactory = useContext(NavigateFunctionContext);

    if (navigationFactory) {
        return navigationFactory();
    }

    throw new Error("useNavigate must be used within a NavigateFunctionContext.");
}

type NavigateFunctionProviderProps = PropsWithChildren<{
    readonly navigateFactory: NavigateFunctionFactory
}>;

const NavigateFunctionProvider: FC<NavigateFunctionProviderProps> = ({ children, navigateFactory }) => <NavigateFunctionContext.Provider value={navigateFactory}>
    {children}
</NavigateFunctionContext.Provider>;

export type { NavigateOptions, NavigateFunction, NavigateFunctionFactory, NavigateFunctionProviderProps };

export default NavigateFunctionProvider;
