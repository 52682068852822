import type { Policies } from "@core/application/policies/protocol";
import { Policy } from "@core/application/policies/protocol";
import type { ContainerModule } from "@core/application/ioc";

import get from "lodash/get";

export interface ContainerModuleImport {
    readonly default: ContainerModule | ContainerModule[]
}

export interface DynamicContainerModule {
    shouldLoad(policies: Policies | undefined): boolean
    load(): Promise<ContainerModuleImport>
}

const modules = [
    {
        shouldLoad: () => true,
        load: () => import("@core/application/application-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/core/core-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/wizard/wizard-module")
    },
    {
        shouldLoad: (policies) => get(policies, Policy.ONBOARDING_ACCESS, false),
        load: () => import("@core/modules/onboarding/onboarding-modules")
    },
    {
        shouldLoad: (policies) => get(policies, Policy.PAYMENTS_ACCESS, false),
        load: () => import("@core/modules/payments/payments-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/portfolio-analytics/portfolio-analytics-modules")
    },
    {
        shouldLoad: (policies) => get(policies, Policy.INVESTMENT_PROFILE_ACCESS, false),
        load: () => import("@core/modules/portfolio-management/portfolio-management-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/postbox/postbox-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/advisor/advisor-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/payments-plan/payment-plans-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/declaration-of-consent-task/declaration-of-consent-task-modules")
    },
    {
        shouldLoad: (policies) => get(policies, Policy.TOPICS_ACCESS, false),
        load: () => import("@core/modules/topics/topics-modules")
    },
    {
        shouldLoad: () => true,
        load: () => import("@core/modules/contract-management/contract-management-modules")
    }
] as DynamicContainerModule[];

export default modules;
