import { createState, useHookstate } from "@hookstate/core"
import { useMemo, useRef, useEffect } from "react"

export { useLocalStorage } from "@hooks/utils"

export { createState as createGlobalState, useHookstate as useGlobalState }

export const useCreateProviderState = (initialState) => {
    const state = createState(initialState)

    useEffect(() => () => state.destroy(), []) // eslint-disable-line react-hooks/exhaustive-deps

    return state
}

export const useStateWithoutRerender = (initialState) => {
    const ref = useRef(initialState)
    const value = ref.current

    const setValue = useMemo(() => (newValue) => {
        ref.current = newValue
    }, [ref])

    return [value, setValue]
}
