/*
 * (c)investify 2019
 * Author: Ivan Mokhnal (ivan.mokhnal@investify.lu>)
 */

export const types = {    
    RING_CHART_HIGHLIGHT_SECTOR: 'RING_CHART/HIGHLIGHT_SECTOR',
    RING_CHART_EXPAND_SECTOR: 'RING_CHART/EXPAND_SECTOR',
    RING_CHART_COLLAPSE_SECTOR: 'RING_CHART/COLLAPSE_SECTOR'
}

export const initialState = {
    expandedSector: null,
    highlightedSector: null,
    error: null
}

export default (state = initialState, action) => {
    switch (action.type) {       
        case types.RING_CHART_HIGHLIGHT_SECTOR:
            return { ...state, highlightedSector: action.highlightedSector }
        case types.RING_CHART_EXPAND_SECTOR:
            return { ...state, expandedSector: action.expandedSector }
        case types.RING_CHART_COLLAPSE_SECTOR:
            return { ...state, expandedSector: null }
        default:
            return state;
    }
}

export const actions = {
    highlightSector: (highlightedSector) => ({ type: types.RING_CHART_HIGHLIGHT_SECTOR, highlightedSector }),
    expandSector: (expandedSector) => ({ type: types.RING_CHART_EXPAND_SECTOR, expandedSector }),
    collapseSector: (collapsedSector) => ({ type: types.RING_CHART_COLLAPSE_SECTOR, collapsedSector })
}

export const getHighlightedSector = (state) => state.ringChart.highlightedSector;
export const getExpandedSector = (state) => state.ringChart.expandedSector;
