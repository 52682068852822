import { createContext, useMemo, useContext } from "react";
import { v4 } from "uuid";

const LoadingContext = createContext(undefined);

export const useLoadingContext = () => useContext(LoadingContext);

export const withLoadingContext = (useQuery) => (options = {}) => {
    const contextId = useLoadingContext();

    return useQuery({ ...options, meta: { ...options.meta, contextId } });
};

const LoadingContextProvider = ({ children }) => {
    const contextId = useMemo(() => v4(), []);

    return <LoadingContext.Provider value={contextId}>
        {children}
    </LoadingContext.Provider>;
};

export default LoadingContextProvider;

export const withLoadingContextProvider = Component => props => {
    return <LoadingContextProvider>
        <Component {...props}/>
    </LoadingContextProvider>;
};
