import { withLoadingContext } from "@providers/LoadingContextProvider";
import { withModuleScope } from "@providers/ModuleScopeProvider";

import get from "lodash/get";
import defaults from "lodash/defaults";

export const Scope = {
    APPLICATION: "app",
    PAGE: "page",
    SESSION: "session",
    MODULE: "module"
};

export const asLinksQuery = (query) => (relation, options) => {
    const subSelect = options?.select ?? ((x) => get(x, "href"));
    const select = (response) => subSelect(get(response, `body._links.${relation}`));

    return query({ ...options, select });
};

export const asEmbeddedQuery = (query) => (id, options) => {
    const subSelect = options?.select ?? ((x) => x);
    const select = (response) => subSelect(get(response, `body._embedded.${id}`));

    return query({ ...options, select });
};

export const asDataQuery = (query) => (options) => {
    const subSelect = options?.select ?? ((x) => x);
    const select = (response) => subSelect(get(response, "body"));

    return query({ ...options, select });
};

export const asIdQuery = (query) => (id, options) => {
    const select = (response) => get(response, `body.${id}`);

    return query({ ...options, select });
};

export const asContentQuery = (query) => (options) => {
    const select = (response) => get(response, "body.content_");

    return query({ ...options, select });
};

export const asDictionaryQuery = (query) => (options) => {
    const select = (response) => get(response, "body.dictionary");

    return query({ ...options, select });
};

export const asContextsQuery = (query) => (options) => {
    const select = (response) => get(response, "body.contexts");

    return query({ ...options, select });
};

export const withMetadata = (queryOrMutation, metadata) => (options) => {
    const mergedMetadata = defaults({}, options?.meta, metadata);

    return queryOrMutation({ ...options, meta: mergedMetadata });
};

export const withApplicationScope = (query) => {
    const queryWithMetadata = withMetadata(query, { scope: Scope.APPLICATION });

    return (options) => queryWithMetadata({ staleTime: Infinity, ...options });
};

export const withPageScope = (query) => {
    const queryWithMetadata = withMetadata(query, { scope: Scope.PAGE });

    return (options) => queryWithMetadata({ staleTime: Infinity, ...options });
};

export const withSessionScope = (query) => {
    const queryWithMetadata = withMetadata(query, { scope: Scope.SESSION });

    return (options) => queryWithMetadata({ staleTime: Infinity, ...options });
};

export const withEnabledCondition = (query, checkEnabled) => ({ enabled = true, ...options }) => {
    const isEnabled = checkEnabled(enabled);

    return query({ ...options, enabled: isEnabled });
};

export const withRefetchInterval = (useQuery, refetchInterval) => ({ queryFn, ...rest }) => {
    const queryFnForPolling = () => {
        const queryFnExecution = queryFn();

        if (refetchInterval && queryFnExecution.hasOwnProperty("set")) {
            return queryFnExecution.set("X-No-Activity", "true");
        }

        return queryFnExecution;
    };

    return useQuery({ queryFn: queryFnForPolling, refetchInterval: refetchInterval ?? false, refetchIntervalInBackground: true, ...rest });
};

export { withModuleScope, withLoadingContext };
