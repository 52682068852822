import { apiRequest } from "@services/api";
import { asDictionaryQuery, withEnabledCondition, withLoadingContext, withPageScope } from "@hooks/higher-order-hooks";
import { useQuery } from "react-query";
import { useResolveURL } from "@hooks/common";

export * from "@common/hooks/static-information";

const useScreenServiceStaticInformationQuery = ({ contextId, contentId, ...options }) => {
    // TODO: fetch link from either customer-home, portfolio-analytics, or wherever available in the context.
    const url = useResolveURL(`/border-proxy/screen-service/screens/static-information/${contextId}/${contentId}`);

    const queryKey = ["static-information", contextId, contentId];

    const queryFn = () => apiRequest
        .get(url)
        .set("Accept", "application/json");

    const useScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(useScopedQuery, (enabled) => enabled && !!contextId && !!contentId);
    const useQueryWithLoadingContext = withLoadingContext(useConditionalQuery);

    return useQueryWithLoadingContext({ queryKey, queryFn, ...options });
};

export const useScreenServiceStaticInformationDictionaryQuery = asDictionaryQuery(useScreenServiceStaticInformationQuery);
