import type { DependencyList } from "react";
import { useCallback } from "react";
import { useEffect, useMemo } from "react";

import config from "@config";

type PanelType = "left" | "right" | "main";
type ValueType = number | string;

export const useResolveURL = (path: string) => {
    return useMemo(() => {
        const { publicUrl }: any = config;
        const baseURL = process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL || publicUrl || "";

        if (path.startsWith(baseURL)) {
            return path;
        }

        return `${baseURL}/${path.startsWith("/") ? path.substring(1) : path}`;
    }, [path]);
};

export const usePanelWidthEffect = (panel: PanelType, value: ValueType) => {
    const variable = (panel: PanelType) => {
        switch (panel) {
            case "left": return "--leftPanelWidth";
            case "right": return "--rightPanelWidth";
            case "main": return "--mainPanelWidth";
        }
    };

    const amount = (value: ValueType) => {
        if (typeof value === "number") {
            return `${value}px`;
        }
        return value;
    };

    useEffect(() => {
        const property = variable(panel);

        document.documentElement.style.setProperty(property, amount(value));

        return () => {
            document.documentElement.style.removeProperty(property);
        };
    }, []);
};

export const usePanelWidth = () => {
    const variable = (panel: PanelType) => {
        switch (panel) {
            case "left": return "--leftPanelWidth";
            case "right": return "--rightPanelWidth";
            case "main": return "--mainPanelWidth";
        }
    };

    const amount = (value: ValueType) => {
        if (typeof value === "number") {
            return `${value}px`;
        }
        return value;
    };

    return useCallback((panel: PanelType, value: ValueType) => {
            const property = variable(panel);
            document.documentElement.style.setProperty(property, amount(value));
    }, []);
};
