import { legacyApiRequest } from "@services/api";
import get from "lodash/get";
import env from "@config";
import { getHome as getApplicationHomeSingleton } from "@ducks/application-lifecycle";
import { getStoredPartnerBinding, saveBindingToLocalStorage } from "@services/binding.service";

const identity = x => x;

/**
 * @return a cold "promise" for the response, which has to be subscribed to in order for the
 *         request to take place
 */
export const getHal = (href) =>
    legacyApiRequest.get(href).set("Accept", "application/hal+json");

// TODO "all" segments can only be placed at the end of the path
const handle = (halObj, segment) => new Promise((resolve, reject) => {
    if (halObj._embedded && halObj._embedded[segment.path] && (!segment.method || segment.method === "get")) {
        resolve(segment.all ? halObj._embedded[segment.path] : halObj._embedded[segment.path][0]);
    } else if (halObj._links && halObj._links[segment.path]) {
        let url = halObj._links[segment.path].href;

        // treat case that a link object has a 2 entries: templated and non templated
        if (!url && segment.template && Array.isArray(halObj._links[segment.path])) {
            url = halObj._links[segment.path].find(link => link.templated).href;
        }

        if (segment.template) {
            url = url.replace("{" + segment.template.key + "}", segment.template.value);
        }
        switch (segment.method) {
            case "post":
                legacyApiRequest
                    .set("Accept", null)
                    .set("Content-Type", null)
                    .post(url)
                    .send(segment.body)
                    .then(re => resolve(re.body))
                    .catch(re => reject(re));
                break;
            case "put":
                legacyApiRequest
                    .set("Accept", null)
                    .set("Content-Type", null)
                    .put(url)
                    .send(segment.body)
                    .then(re => resolve(re.body))
                    .catch(re => reject(re));
                break;
            case "get":
            default:
                legacyApiRequest
                    .set("Accept", null)
                    .set("Content-Type", null)
                    .get(url)
                    .send()
                    .then(re => resolve(re.body),
                        err => reject(err))
                    .catch(re => reject(re));
                break;
        }
    } else {
        reject(new Error("Segment not found: " + JSON.stringify(segment)));
    }
});

export const follow = (halObj, segments) => {
    if (segments.length === 1) {
        return handle(halObj, segments[0]);
    } else {
        return handle(halObj, segments[0]).then(re => {
            return follow(re, segments.slice(1));
        });
    }
};


const getHome = () => getApplicationHomeSingleton();

export const getGuestHome = () =>
    legacyApiRequest.get(env.API_GUEST_ROOT)
        .set("Accept", "application/hal+json");

export const getScalarLink = (linkRel, useGuestHome = false) =>
    (useGuestHome ? getGuestHome() : getHome())
        .then(re => re.body._links[linkRel].href);

export const getPath = (segments, useGuestHome = false) =>
    (useGuestHome ? getGuestHome() : getHome())
        .then(re => follow(re.body, segments))
        .catch(err => {
            if (err.response) {
                console.error("API ERROR Status code:", err.response.statusCode, "message:", err.message);
            }
            console.error(err);
            throw err;
        });

export const updateUserPartnerBindings = () => {
    return getScalarLink("provide-binding")
        .then(provideBindingHref =>
            legacyApiRequest.post(provideBindingHref)
                .set("Accept", "application/hal+json")
                .send(getStoredPartnerBinding())
                .then(bindingResponse => saveBindingToLocalStorage(bindingResponse.body))
        );
};
export const getCustomerState = () => {
    return getHome()
        .then(re => re.body._embedded["login-context"][0]._links["customer-state"].href)
        .then(href => legacyApiRequest.get(href))
        .then(re => re.body);
};

export const getCurrentUserEmail = () => {
    return getPath([{ path: "user-data-context" }])
        .then(re => re._embedded.userData[0].contactDetails.email);
};

export const confirmChanges = (href) => new Promise((resolve, reject) => {
    return legacyApiRequest
        .post(href)
        .send({ comfirmInvestmentPlanEdit: true })
        .set("Accept", "application/hal+json")
        .then(re => {
            resolve();
        }, err => {
            reject(err);
        })
        .catch(err => {
            reject(err);
        });
});

export const changePassword = (password, token) => new Promise((resolve, reject) => {
    getGuestHome().then(re => {
        const resetPasswordUrl = re.body._links["change-password"].href;
        legacyApiRequest.post(resetPasswordUrl)
            .send({
                processToken: token,
                password
            })
            .set("Accept", "application/hal+json")
            .set("Content-Type", "application/json")
            .catch(err => {
                reject(err);
            })
            .then((res) => resolve(res));
    });
});

export const forgotPassword = (user) => new Promise((resolve, reject) => {
    getPath([{ path: "login-context" }]).then(re => {
        legacyApiRequest.post(re._links["forgot-password-process"].href)
            .send({ email: user })
            .then(re => {
                resolve(re);
            }, err => {
                reject(err);
            })
            .catch(err => {
                reject(err);
            });
    });
});

export const changeEmail = (email) => new Promise((resolve, reject) => {
    getPath([{ path: "check-email" }, { path: "changeEmail" }])
        .then(re => {
            legacyApiRequest.put(re._links.emailUserData.href)
                .set("Accept", "application/hal+json")
                .send({ email })
                .then(success => {
                    resolve(success);
                }).catch(err => {
                reject(err);
            });
        })
        .catch(err => {
            reject(err);
        });
});

export const resendVerificationEmail = () => new Promise((resolve, reject) =>
    getPath([{ path: "user-email-context" }])
        .then(re => {
            legacyApiRequest.post(re._links.emailValidationLink.href)
                .set("Content-Type", "application/json")
                .set("Accept", "application/hal+json")
                .send({})
                .then(resolve, reject);
        }));

export const getContractsScreen = () => {
    return getPath([{ path: "contract-documents" }]);
};

export const sendPrivacyPolicyAcceptance = () => new Promise((resolve, reject) =>
    getScalarLink("accept-privacy-policy")
        .then(acceptPrivacyPolicyHref => {
            legacyApiRequest.post(acceptPrivacyPolicyHref)
                .set("Content-Type", "application/json")
                .set("Accept", "application/json")
                .send({})
                .then(resolve, reject);
        }));
export const payment = (href, initialPayment) => new Promise((resolve, reject) => {
    return legacyApiRequest
        .post(href)
        .set("Content-Type", "application/json")
        .set("Accept", "application/hal+json")
        .send({ amount: initialPayment })
        .then(re => {
            resolve();
        })
        .catch(err => {
            reject(new Error(err));
        });
});

export const savingsRate = (href, savingsPlan) => new Promise((resolve, reject) => {
    return legacyApiRequest
        .post(href)
        .set("Content-Type", "application/json")
        .set("Accept", "application/hal+json")
        .send({ amount: savingsPlan })
        .then(re => {
            resolve();
        })
        .catch(err => {
            reject(new Error(err));
        });
});

export const discardChanges = (href) => new Promise((resolve, reject) => {
    if (href.includes("127.0.0.1")) {
        href = href.replace("127.0.0.1", "localhost");
    }
    return legacyApiRequest
        .post(href)
        .set("Content-Type", "application/json")
        .set("Accept", "application/hal+json")
        .send()
        .then(re => {
            resolve();
        })
        .catch(err => {
            reject(err);
        });
});

/*
 * Follow (templated) links within particular hal object (not starting at APIRoot)
 */

export const followPath = (halObj, segments) => new Promise((resolve, reject) => {
    return resolve(follow(halObj, segments));
});

export const completePersonalData = (href) =>
    legacyApiRequest.post(href).then(identity);

export const completeSignContracts = (href) =>
    legacyApiRequest.post(href);


export const getStepPersonalData = href => {
    return legacyApiRequest.get(href)
        .set("Accept", "application/hal+json")
        .then(repr => repr.body);
};

export const getPersonalDataStepScreen = stepId =>
    getScalarLink("contract-data-step")
        .then(templatedStepHref => templatedStepHref.replace("{step}", stepId))
        .then(getStepPersonalData);

export const putAnswers = (url, answers) => legacyApiRequest.put(url)
    .set("Content-Type", "application/hal+json")
    .set("Accept", "application/hal+json")
    .send(answers);

// THEME SELECTION
export const addTopicToInvestmentPlan = (topicUuid) => new Promise((resolve, reject) => {
    getPath([{ path: "topics" }])
        .then(re => {
            legacyApiRequest.post(re._links.newInvestmentPlan.href)
                .send({ topicUuid })
                .then(success => {
                    resolve(success);
                })
                .catch(err => {
                    reject(err);
                });
        })
        .catch(err => {
            reject(err);
        });
});


export const getPayoutScreen = () => {
    return getPath([{ path: "payout" }]);
};

export const removeTopicFromInvestmentPlan = (url) => new Promise((resolve, reject) =>
    legacyApiRequest.delete(url)
        .then(success => resolve(success))
        .catch(err => reject(err))
);

export const editInvestmentPlan = () => new Promise((resolve, reject) =>
    getScalarLink("investment-plan-edit")
        .then(editInvestmentPlanHref => {
            legacyApiRequest.post(editInvestmentPlanHref)
                .set("Content-Type", "application/json")
                .set("Accept", "application/hal+json")
                .send({})
                .then(resolve, reject);
        })
);

export const followRequirements = (repr) => {
    const requirements = get(repr, "_links.requirements");
    if (!requirements) {
        return Promise.resolve();
    }

    return legacyApiRequest.get(requirements[0].href).then(response => {
        const unfulfilledReq = response.body.requirements.find(el => !el.isFullfilled && !!el.requirement);

        if (!unfulfilledReq) {
            return;
        }

        return unfulfilledReq.requirement;
    });
};
