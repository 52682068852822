import React, { createContext, useContext, useMemo } from "react";

import type { PropsWithChildren, FC, ComponentType } from "react";
import type { Implementations } from "@config/implementations";

import get from "lodash/get";

/** @deprecated Use FacadeContext instead. */
const ImplementationsContext = createContext<Implementations | undefined>(undefined);

/** @deprecated Use FacadeProviderProps instead. */
export type ImplementationsProviderProps = PropsWithChildren<{
    readonly variations?: Implementations
}>;

/** @deprecated Use FacadeProvider instead. */
const ImplementationsProvider: FC<ImplementationsProviderProps> = ({ children, variations }) => (
    <ImplementationsContext.Provider value={variations}>
        {children}
    </ImplementationsContext.Provider>
);

/** @deprecated Use useFacade instead. */
function useImplementation<Type extends keyof Implementations, ID extends keyof Implementations[Type]>(type: Type, id: ID): Implementations[Type][ID] {
    const implementations = useContext(ImplementationsContext);

    if (implementations === undefined) {
        throw new Error("useImplementation must be used under a ImplementationsProvider with defined variations.");
    }

    return useMemo(() => get(implementations, [type, id]), [implementations, type, id]);
}

/** @deprecated Use useFacade instead. */
function useHookImplementation<ID extends keyof Implementations["hooks"]>(id: ID) {
    return useImplementation("hooks", id);
}

/** @deprecated Use withFacadeProvider instead. */
// eslint-disable-next-line react/display-name
const withImplementationsProvider = <P extends object = object>(variations: Implementations) => (Component: ComponentType<P>) => (props: P) => <ImplementationsProvider variations={variations}>
    <Component {...props} />
</ImplementationsProvider>;

export { ImplementationsProvider, useHookImplementation, withImplementationsProvider };
