import {
    asDictionaryQuery,
    asEmbeddedQuery,
    withEnabledCondition, withLoadingContext,
    withPageScope, withSessionScope
} from "@hooks/higher-order-hooks";
import { useHomeLinksQuery } from "@hooks/home";
import { apiRequest, legacyRequest } from "@services/api";
import { useQuery } from "react-query";
import get from "lodash/get";

const usePostBoxQuery = (options) => {
    const { data: url } = useHomeLinksQuery("postbox");

    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);
    const useConditionalQueryWLC = withLoadingContext((useConditionalQuery));

    const queryKey = ["bc_postbox", "postbox", url];

    const queryFn = () => apiRequest
        .set("Accept", "application/json")
        .get(url)
        .send();

    return useConditionalQueryWLC({ queryKey, queryFn, ...options });
};

export const usePostBoxEmbeddedQuery = asEmbeddedQuery(usePostBoxQuery);
export const usePostBoxDictionaryQuery = asDictionaryQuery(usePostBoxQuery);

export const usePostboxIconQuery = (options) => {
    const { data: postboxDictionary } = usePostBoxDictionaryQuery(options);

    const url = get(postboxDictionary, "pdfIcon");
    const usePageScopedQuery = withSessionScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);

    const useConditionalQueryWLC = withLoadingContext((useConditionalQuery));

    const queryKey = ["bc_postbox", "postbox", "pdf-icon"];

    const queryFn = () => legacyRequest
        .get(url);

    const select = (response) => response.text;
    return useConditionalQueryWLC({ queryKey, queryFn, select, keepPreviousData: true, ...options });
};
