const styles = (theme) => ({
    root: {
        position: "static",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
    },
    appbar: {
        height: theme.props.header.height,
    },
    desktopUserPanel: {
        marginRight: "-10px",
    },
    desktopUserPanelButton: {
        ...theme.typography.subtitle2,
        fontSize: "1rem",
        width: "auto",
        "&$disabled": {
            color: "inherit"
        },
        padding: theme.spacing(0.5, 1),
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: 50, whiteSpace: "nowrap", overflow: "hidden",
        },
    },
    contractMenuButton: {
        ...theme.typography.caption,
        display: "inline-flex",
        fontSize: "0.75rem",
        width: "auto",
        height: "100%",
        margin: theme.spacing("auto", 0),
        padding: theme.spacing(0),
        justifyContent: "flex-end",
        "&$disabled": {
            color: "inherit"
        },
        "& > .MuiButton-label": {
            textAlign: "right",
            [theme.breakpoints.down("lg")]: {
                maxWidth: "20ch",
            },
            [theme.breakpoints.down("md")]: {
                maxWidth: "10ch",
            },
            maxWidth: "40ch",
            display: "flex",
            "& > .MuiButton-endIcon": {
                marginLeft: theme.spacing(0.5),
            }
        }
    },
    contractName: {
        [theme.breakpoints.down("sm")]: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    },
    toolbar: {
        width: "100%",
        margin: theme.spacing(0, "auto"),
        justifyContent: "space-between",
        maxWidth: theme.props.shell.application.width,
        minHeight: theme.props.header.height,
        "& > a": {
            textTransform: "none",
        },
        [theme.breakpoints.down(theme.props.shell.application.width + 1)]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        // styling reset of padding for toolbox
        padding: 0,
    },
    userIcon: {},
    logo: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center left",
        backgroundSize: "contain, 0",
        display: "block",
        width: 120,
        height: 32,
        [theme.breakpoints.down("xs")]: {
            width: 31, marginRight: 0, backgroundSize: "0, contain",
        },
    },
    placeholder: {
        height: theme.props.header.height, display: "block",
    },
    userEmailContainer: {
        [theme.breakpoints.between("sm", "sm")]: {
            maxWidth: 210, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
        },
    },
    pageTitle: {
        display: "block",
        flexBasis: "100%",
    },
    title: {
        fontSize: "1rem",
        letterSpacing: 0,
        lineHeight: 1.5,
        fontWeight: 500
    },
    disabled: {
        color: "inherit"
    },
    logoContainer: {},
    menuContainer: {},
});

export default styles;
