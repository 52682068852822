import { useQuery } from "react-query";
import { withApplicationScope, withLoadingContext } from "@hooks/higher-order-hooks";
import { get } from "lodash";
import dictionaries from "@config/dictionaries";

export const useDictionaryQuery = (boundedContext, options) => {
    const useScopedQuery = withApplicationScope(useQuery);
    const useQueryWithLoadingContext = withLoadingContext(useScopedQuery);

    const importOrRequest = dictionaries[boundedContext];

    const queryKey = ["dictionary", boundedContext];
    const queryFn = typeof importOrRequest === "function" ? importOrRequest : () => importOrRequest;

    return useQueryWithLoadingContext({ queryKey, queryFn, cacheTime: Infinity, keepPreviousData: true, ...options });
};

export const useBoundedContextDictionaryQuery = (boundedContext, options) => {
    const select = (response) => get(response, "default.dictionary");

    return useDictionaryQuery(boundedContext, { select, ...options });
};
