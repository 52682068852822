import withAll from "lodash/flowRight";
import { useErrorData, ERROR_TYPES } from "@modules/error/hooks";
import ErrorHandler from "@modules/error/components/GlobalErrorHandler";
import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import withDictionary from "@components/withDictionary";
import { Dictionary } from "@config/dictionaries";

const GlobalErrorBoundary = ({ children }) => {
    const { errorData, setErrorData } = useErrorData();

    useErrorBoundary((error) => {
        if (error.response && error.response.error && error.response.serverError) {
            setErrorData(ERROR_TYPES.SERVER_NETWORK_ERROR, error.response.error);
        } else {
            setErrorData(ERROR_TYPES.FRONTEND_ERROR, { error });
        }
    });

    if (errorData.errorCaught) {
        return <ErrorHandler errorData={errorData} />;
    }
    return children;
};

export const withGlobalErrorBoundary = (Component) => (props) => (
    <GlobalErrorBoundary>
        <Component {...props} />
    </GlobalErrorBoundary>
);

GlobalErrorBoundary.displayName = "GlobalErrorBoundary";

export default withAll(
    withDictionary(Dictionary.SCREEN_ERROR),
    withErrorBoundary,
)(GlobalErrorBoundary);
