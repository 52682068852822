import { useMemo } from "react";
import type { QueryClient, Query } from "react-query";

import { useQueryClient } from "react-query";

import isMatch from "lodash/isMatch";
import get from "lodash/get";

type ExtendedQueryClient = QueryClient & {
    removeAnnotatedQueries(annotations: Record<string, unknown>): Promise<void>
    invalidateAnnotatedQueries(annotations: Record<string, unknown>): Promise<void>
    removeScopedQueries(scope: string, additionalMetaFilters?: Record<string, unknown>): Promise<void>
    invalidateScopedQueries(scope: string, additionalMetaFilters?: Record<string, unknown>): Promise<void>
};

class QueryClientExtension implements ProxyHandler<QueryClient> {
    private readonly queryClient: QueryClient;

    public constructor(queryClient: QueryClient) {
        this.queryClient = queryClient;
    }

    public get(target: QueryClient, property: string | symbol, receiver: unknown) {
        return get(target, property, get(this, property));
    }

    public async removeAnnotatedQueries(annotations: Record<string, unknown>): Promise<void> {
        const predicate = this.createQueryPredicate(annotations);

        return this.queryClient.removeQueries({ predicate });
    }

    public async invalidateAnnotatedQueries(annotations: Record<string, unknown>): Promise<void> {
        const predicate = this.createQueryPredicate(annotations);

        return this.queryClient.invalidateQueries({ predicate });
    }

    public async removeScopedQueries(scope: string, additionalAnnotations?: Record<string, unknown>): Promise<void> {
        return this.removeAnnotatedQueries({ scope, ...additionalAnnotations });
    }

    public async invalidateScopedQueries(scope: string, additionalAnnotations?: Record<string, unknown>): Promise<void> {
        return this.invalidateAnnotatedQueries({ scope, ...additionalAnnotations });
    }

    private createQueryPredicate(annotations: Record<string, unknown>): (query: Query) => boolean {
        return (query: Query) => {
            const { isFetching } = query.state;
            const matchesAnnotations = isMatch(query?.meta ?? {}, annotations);

            return matchesAnnotations && !isFetching;
        };
    }
}

export function useExtendedQueryClient(): ExtendedQueryClient {
    const queryClient = useQueryClient();

    return useMemo(() => new Proxy(queryClient, new QueryClientExtension(queryClient)) as ExtendedQueryClient, [queryClient]);
}

export { useQueryClient };
