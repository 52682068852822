import { createContext, useContext } from "react";
import { asParams } from "@components/application/navigation/util";
import { useLocation } from "react-router-dom";

const FlowContext = createContext();

export const useFlowContext = () => useContext(FlowContext);

const FlowContextProvider = ({ children }) => {
    const location = useLocation();

    const { flow } = asParams(location.search);

    return <FlowContext.Provider value={flow}>
        {children}
    </FlowContext.Provider>;
};

export const withFlowContextProvider = (Component) => (props) => <FlowContextProvider>
    <Component {...props}/>
</FlowContextProvider>;

export default FlowContextProvider;
