import { forwardRef } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "@components/Modal.styles";

import ModalHeader from "@components/ModalHeader";
import ModalContent from "@components/ModalContent";
import ModalFooter from "@components/ModalFooter";

import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import If from "@components/If";

// eslint-disable-next-line no-unused-vars
export const ModalComponent = forwardRef(({
    classes,
    open,
    handleModal,
    children,
    closeButton = false,
    size,
    ...props
}, ref) => (
    <If condition={open}>
        <Dialog
            maxWidth={size}
            open={open}
            keepMounted={false}
            onClose={handleModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
            scroll={"body"}
            ref={ref}
            classes={{
                root: classes.root,
                paper: classes.paper,
                paperScrollBody: classes.paperScrollBody,
            }}
            {...props}
        >
            {closeButton &&
                <IconButton
                    size={"small"}
                    aria-label="close"
                    key="close"
                    className={classes.closeButton}
                    onClick={handleModal}
                >
                    <CloseIcon />
                </IconButton>
            }
            {children}
        </Dialog>
    </If>
));

const Modal = withStyles(styles, { name: "Modal" })(ModalComponent);

// ModalComponent.Header = ModalHeader
// ModalComponent.Content = ModalContent
// ModalComponent.Footer = ModalFooter
ModalComponent.propTypes = {
    // open: PropTypes.bool.isRequired,
    // handleModal: PropTypes.func.isRequired,
    size: PropTypes.string,
};
ModalComponent.defaultProps = {
    size: "lg"
};

ModalComponent.displayName = "ModalComponent";

export { Modal, ModalHeader, ModalContent, ModalFooter };

export default Modal;
