import { Contributions, Inject, Injectable, Named, PostConstruct } from "@core/application/ioc";
import type { MenuItem } from "@core/application/menu/contributables";
import { MenuContribution } from "@core/application/menu/contributables";
import { checkPolicyScopeFilterPredicate } from "@core/application/menu/utils";

@Injectable
export class MenuRegistry {
    @Inject(Contributions) @Named(MenuContribution)
    private readonly contributions: MenuContribution[];

    private readonly menuItems: Map<string, MenuItem> = new Map();

    @PostConstruct
    protected init(): void {
        this.contributions.forEach((contribution) => contribution.registerContributions(this));
    }

    public registerMenuItem(menuItem: MenuItem): void {
        if (this.menuItems.has(menuItem.id)) throw new Error(`There is already a menu entry with id '${menuItem.id}'.`);
        else {
            this.menuItems.set(menuItem.id, menuItem);
        }
    }

    public unregisterMenuItem(id: string): void {
        this.menuItems.delete(id);
    }

    public getMenuItems(menu?: symbol): MenuItem[] {
        return Array.from(this.menuItems.values())
            .filter((menuItem) => !menu || !menuItem.menus || menuItem.menus.includes(menu));
    }

    public getOrderedMenuItems(menu?: symbol): MenuItem[] {
        return this.getMenuItems(menu).sort((a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0));
    }

    public getPolicyScopedMenuItems(menu?: symbol): MenuItem[] {
        return this.getOrderedMenuItems(menu).filter(checkPolicyScopeFilterPredicate);
    }
}
