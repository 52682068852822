export const Dictionary = {
    BC_ADVISORY_PROCESS: {
        ADVISORY_PROCESS: "@bounded-context/advisory-process/dictionary/advisoryProcess",
        TILE: "@bounded-context/advisory-process/dictionary/tile"
    },
    BC_ASSET_MANAGEMENT: "@bounded-context/asset-management/dictionary/assetManagement",
    BC_AUDIT_LOGGING: {
        DEFAULT: "@bounded-context/audit-logging/dictionary/audit-logging",
    },
    BC_BANK_IMPORT: "@bounded-context/bank-import/dictionary/bankImport",
    BC_CONTRACT_APPROVAL: {
        DEFAULT: "@bounded-context/contract-approval/dictionary/contract-approval",
        CUSTOMERS: "@bounded-context/contract-approval/dictionary/customers",
    },
    BC_ADVISOR_IDENTIFICATION: {
        DEFAULT: "@bounded-context/advisor-ident/dictionary/advisor-identification",
    },
    BC_CONTRACT_MANAGEMENT: "@bounded-context/contract-management/dictionary/contract-management",
    BC_CONTRACT_TERMINATION: "@bounded-context/contract-termination/dictionary/contract-termination",
    BC_CONTRACT_SIGNING_PROCESS: "@bounded-context/contract-signing-process/dictionary/contract-signing-process",
    BC_DECLARATION_OF_CONSENT_TASK: "@bounded-context/declaration-of-consent-task/dictionary/declaration-of-consent-task",
    BC_CUSTODIAN_ACCOUNTS: {
        DEFAULT: "@bounded-context/custodian-accounts/dictionary/custodianAccounts",
    },
    BC_CUSTODIAN_ACCOUNTS_OPENING_INTENTION: {
        DEFAULT: "@bounded-context/custodian-accounts-opening-intention/dictionary/custodianAccountsOpeningIntention",
    },
    BC_CUSTOMER_MANAGEMENT: {
        DEFAULT: "@bounded-context/customer-management/dictionary/customerManagement",
        ADVISOR_BINDINGS: "@bounded-context/customer-management/dictionary/customerAdvisorBindings"
    },
    BC_CUSTOMER_INVESTMENT_AMOUNT: {
        TILE: "@bounded-context/customer-investment-amount/dictionary/tile"
    },
    BC_CUSTOMER_INVESTMENT_STRATEGY: {
        DEFAULT: "@bounded-context/customer-investment-strategy/dictionary/customerInvestmentStrategy",
        FEASIBILITY: "@bounded-context/customer-investment-strategy/dictionary/feasibility",
        FORECAST: "@bounded-context/customer-investment-strategy/dictionary/forecast",
        SPECIAL_CONDITIONS: "@bounded-context/customer-investment-strategy/dictionary/specialConditions",
        RANGE_RETURN: "@bounded-context/customer-investment-strategy/dictionary/rangeReturn",
        SIMULATIONS: "@bounded-context/customer-investment-strategy/dictionary/simulations",
        TILE: "@bounded-context/customer-investment-strategy/dictionary/tile",
        ADVISORY_PROCESS_TILE_EXTENSION: "@bounded-context/customer-investment-strategy/dictionary/advisory-process/tileExtension",
        REINVESTMENT_RATE_EXPORT: "@bounded-context/customer-investment-strategy/dictionary/reinvestment-rate/cisReinvestmentRates",
    },
    BC_DATA_ANALYSES: "@bounded-context/data-analyses/dictionary/data-analyses",
    BC_DOCUMENT_FOLDER: "@bounded-context/document-folder/dictionary/document-folder",
    BC_IAM: {
        STAFF_USER_MANAGEMENT: "@bounded-context/iam/dictionary/staffUserManagement",
        IDENTITY_MANAGEMENT: "@bounded-context/iam/dictionary/identityManagement",
    },
    BC_INVESTMENT_PROFILE: {
        DEFAULT: "@bounded-context/investment-profile/dictionary/investmentProfile",
        TILE: "@bounded-context/investment-profile/dictionary/tile",
        ADVISORY_PROCESS_TILE_EXTENSION: "@bounded-context/investment-profile/dictionary/advisory-process/tileExtension"
    },
    BC_INVESTMENTS: {
        DEFAULT: "@bounded-context/investments/dictionary/investments",
        INVESTMENT_AMOUNT: "@bounded-context/investments/dictionary/investmentsSum"
    },
    BC_IDENTIFICATIONS: {
        DEFAULT: "@bounded-context/identifications/dictionary/identifications",
        DETAIL: "@bounded-context/identifications/dictionary/identificationDetail",
    },
    BC_IDENTIFICATION_TASKS: {
        DEFAULT: "@bounded-context/identifications-tasks/dictionary/identificationTasks",
        TABLE: "@bounded-context/identifications-tasks/dictionary/identificationTasksTable",
    },
    BC_KYC: {
        DEFAULT: "@bounded-context/kyc/dictionary/kycCheck",
        FIDUCIA: "@bounded-context/kyc/dictionary/fiduciaKyc",
        OSPLUS: "@bounded-context/kyc/dictionary/osplusKyc",
        ADDITIONAL_DATA: "@bounded-context/kyc/dictionary/additionalData"
    },
    BC_MARKETING: {
        PROMOTION_CAMPAIGNS: "@bounded-context/marketing/dictionary/promotionCampaigns",
    },
    BC_PAYMENTS: {
        DASHBOARD: "@bounded-context/payments/dictionary/dashboard",
        DASHBOARD_EXTENSION: "@bounded-context/payments/dictionary/customer-dashboard",
        WITHDRAWALS: "@bounded-context/payments/dictionary/withdrawals",
        DEPOSITS: "@bounded-context/payments/dictionary/deposits",
        STATIC_INFORMATION_PROCESS_CHANGES: "@bounded-context/payments/dictionary/static-information-process-changes",
        DEFAULT: "@bounded-context/payments/dictionary/payments"
    },
    BC_PAYMENT_PLANS: {
        DEFAULT: "@bounded-context/payment-plans/dictionary/payment-plans",
    },
    BC_PORTFOLIO_ANALYSIS: {
        DASHBOARD: {
            TOTAL_PORTFOLIO: "@bounded-context/portfolio-analysis/dictionary/dashboard/totalPortfolio",
            PERFORMANCE: "@bounded-context/portfolio-analysis/dictionary/dashboard/performance",
            PORTFOLIO_EVOLUTION: "@bounded-context/portfolio-analysis/dictionary/portfolioEvolution",
            PORTFOLIO_REALIZATION_OF_PURPOSE: "@bounded-context/portfolio-analysis/dictionary/dashboard/realizationOfPurpose",
            DIRECTIVE_CONFORMITY: "@bounded-context/portfolio-analysis/dictionary/directiveConformity",
            SUSTAINABILITY: "@bounded-context/portfolio-analysis/dictionary/sustainability",
            VALUE_PRESERVATION: "@bounded-context/portfolio-analysis/dictionary/dashboard/valuePreservation"
        },
        PORTFOLIO_DETAILS: "@bounded-context/portfolio-analysis/dictionary/portfolioDetails",
        TRANSACTIONS: "@bounded-context/portfolio-analysis/dictionary/transactions",
    },
    BC_PORTFOLIO_MANAGEMENT: {
        DEFAULT: "@bounded-context/portfolio-management/dictionary/portfolioManagement",
        INSTRUCTIONS: "@bounded-context/portfolio-management/dictionary/instructions"
    },
    BC_POSTBOX: "@bounded-context/postbox/dictionary/postbox",
    BC_PRODUCT_ORCHESTRATION: {
        CONTRACT_IMPORT: {
            ACCESS: "@bounded-context/product-orchestration/dictionary/contractImport"
        }
    },
    BC_TRADING: {
        DEFAULT: "@bounded-context/trading/dictionary/trading",
        EXPORTS: "@bounded-context/trading/dictionary/tradingExport"
    },
    BC_TOPHOLDING_MANAGEMENT: "@bounded-context/topholding-management/dictionary/assetManagement",
    BC_USER_MANAGEMENT: {
        LOGIN: "@bounded-context/user-management/authentication/dictionary/login",
        TOTP: "@bounded-context/user-management/authentication/dictionary/totp",
        RECOVER_PASSWORD: "@bounded-context/user-management/authentication/dictionary/recoverPassword"
    },
    BC_PERSONS: {
        DEFAULT: "@bounded-context/persons/common/dictionary/default",
        NATURAL: "@bounded-context/persons/natural/dictionary/naturalPersons",
        LEGAL: "@bounded-context/persons/legal/dictionary/legalPersons",
        ABSTRACT: "@bounded-context/persons/abstract/dictionary/abstractPersons",
        NATURAL_WIZARD: "@bounded-context/persons/natural/dictionary/wizard",
        LEGAL_WIZARD: "@bounded-context/persons/legal/dictionary/wizard"
    },
    BC_SEPA_MANDATE: {
        DEFAULT: "@bounded-context/sepa-mandate/dictionary/sepa-mandate"
    },
    BC_SALES_ORGANIZATIONS: "@bounded-context/sales-organizations/dictionary/sales-organizations",
    SCREEN_EXPORTS: "@screens/dictionary/exports",
    SCREEN_DASHBOARD: "@screens/dictionary/dashboard",
    SCREEN_WIZARD: "@screens/dictionary/wizard",
    SCREEN_ERROR: "@screens/dictionary/error",
    SCREEN_USER_DETAILS: "@screens/dictionary/userDetails",
} as const;
