import { apiRequest } from "@services/api";
import { asDataQuery, withEnabledCondition, withLoadingContext, withPageScope } from "@hooks/higher-order-hooks";
import { useQuery } from "react-query";
import { isSuperSet } from "@utils";
import { useHookImplementation } from "@providers/ImplementationsProvider";

import get from "lodash/get";

export const useInvestmentProfileHistoryUrlQuery = Symbol("useInvestmentProfileHistoryUrlQuery");

export const useInvestmentProfileTileDictionaryQuery = Symbol("useInvestmentProfileTileDictionaryQuery");

const useInvestmentProfileHistoryQuery = (options) => {
    const { data: url } = useHookImplementation(useInvestmentProfileHistoryUrlQuery)();

    const usePageScopedQuery = withPageScope(useQuery);
    const useQueryWithLoadingContext = withLoadingContext(usePageScopedQuery);
    const useConditionalQuery = withEnabledCondition(useQueryWithLoadingContext, (enabled) => enabled && !!url);

    const queryKey = ["investment-profile", "history", url];

    const queryFn = () => apiRequest
        .set("Accept", "application/hal+json")
        .get(url)
        .send();

    return useConditionalQuery({ queryKey, queryFn, ...options, useErrorBoundary: true });
};

export const useInvestProfileHistoryDataQuery = asDataQuery(useInvestmentProfileHistoryQuery);

export const useInvestmentProfileValidityStateQuery = (options) => {
    const select = (response) => get(response, "body.activeInvestmentProfile.validityState");
    return useInvestmentProfileHistoryQuery({ ...options, select });
};

export const PROFILE_STATUS = {
    state: {
        INVALID: "INVALID",
        INDICATION: "INDICATION",
    },
    cause: {
        DISCONTINUED_QUESTIONNAIRE: "DISCONTINUED_QUESTIONNAIRE",
        UP_TO_DATE_CHECK: "UP_TO_DATE_CHECK",
    },
    userImpact: {
        NEEDS_RENEWAL: "NEEDS_RENEWAL",
        BLOCKING: "BLOCKING",
        NON_BLOCKING: "NON_BLOCKING",
    },
};

export const isBlocking = (validityState) => validityState && isSuperSet(validityState?.userImpact, [PROFILE_STATUS.userImpact.BLOCKING]);

export const isInvalid = (validityState) => validityState && validityState.state === PROFILE_STATUS.state.INVALID;

export const isRenewalNeeded = (validityState) => validityState && isSuperSet(validityState?.userImpact, [PROFILE_STATUS.userImpact.NEEDS_RENEWAL]);
