import type {
    usePoliciesContentQuery,
    UsePoliciesContentQuery,
    usePolicyTestByAggregateIdContentQuery,
    UsePolicyTestByAggregateIdContentQuery
} from "@core/application/policies/hooks";
import type { useInvestmentProfileHistoryUrlQuery, useInvestmentProfileTileDictionaryQuery } from "@hooks/investment-profile";

import type { UseQueryResult, useQuery, UseQueryOptions } from "react-query";

import type {
    useCustomerInvestmentStrategyFeasibilityDictionaryQuery
} from "@bounded-context/customer-investment-strategy/hooks/customer-investment-strategy";

import type { usePostboxPDFIconQuery } from "@bounded-context/postbox/contributables";
import type { useCustomerDashboardTransactionsDictionaryQuery } from "@bounded-context/portfolio-analysis/hooks/transactions";
import type { useCustomerSupportContactDictionaryQuery } from "@bounded-context/follow-up-communication/hooks/support-contact-data";

import type { useStaticInformationDictionaryQuery } from "@hooks/static-information";

// TODO: these types should actually be exported from the files where the symbols are located‚
//       however these files first need to be migrated to TypeScript.
type UseQuery = typeof useQuery;
type UseUrlQuery = () => UseQueryResult<string>;
type UseDictionaryQuery = () => UseQueryResult<Record<string, string>>;
type UseDataQuery = (options?: UseQueryOptions<Record<string, unknown>>) => UseQueryResult<Record<string, unknown>>;

export type UseRoute = () => string;
export type UseInvestmentProfileHistoryUrlQuery = UseUrlQuery;
export type UseInvestmentProfileTileDictionaryQuery = UseQuery;

export type UseDeclarationOfConsentTaskFollowLinksQuery = UseUrlQuery;

export type UsePersonDashboardDataQuery = UseDataQuery;
export type UsePostboxPDFIconQuery = UseDataQuery;

export type Hooks = Readonly<{
    [usePoliciesContentQuery]: UsePoliciesContentQuery
    [usePolicyTestByAggregateIdContentQuery]: UsePolicyTestByAggregateIdContentQuery
    [useInvestmentProfileHistoryUrlQuery]: UseInvestmentProfileHistoryUrlQuery
    [useInvestmentProfileTileDictionaryQuery]: UseInvestmentProfileTileDictionaryQuery
    [useCustomerInvestmentStrategyFeasibilityDictionaryQuery]: UseDictionaryQuery
    [useCustomerDashboardTransactionsDictionaryQuery]: UseDictionaryQuery
    [useCustomerSupportContactDictionaryQuery]: UseDictionaryQuery | undefined
    [usePostboxPDFIconQuery]: UsePostboxPDFIconQuery
    [useStaticInformationDictionaryQuery]: UseDictionaryQuery
}>;

export type Implementations = Readonly<{
    hooks: Hooks
}>;

const implementations: Implementations | undefined = undefined;

export default implementations;
