const styles = () => ({
    link: {
        textDecoration: "none",
        "& > *": {
            textDecoration: "none",
        }
    },
});

export default styles;
