
export const TransitionHover = (props) => {
    const duration = props?.duration ?? 0.32;
    const ease = props?.ease ?? [0.43, 0.13, 0.23, 0.96];

    return { duration, ease };
};

export const TransitionEnter = (props) => {
    const duration = props?.durationIn ?? 0.32;
    const ease = props?.easeIn ?? [0.32, 0.64, 0.5, 0.96];

    return { duration, ease };
};

export const TransitionExit = (props) => {
    const duration = props?.durationOut ?? 0.48;
    const ease = props?.easeOut ?? [0.43, 0.13, 0.23, 0.96];

    return { duration, ease };
};
