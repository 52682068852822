import { useCallback, useEffect, useRef, useState } from "react";
import includes from "lodash/includes";
import snakeCase from "lodash/snakeCase";
import { transform, set } from "lodash";
import {
    isArray, isObjectLike, isPlainObject, map,
} from "lodash/fp";
import isEmpty from "lodash/isEmpty";
import camelCase from "lodash/camelCase";
import find from "lodash/find";

export const parsePlaceholderText = (fullText, replaceWith) => {
    let parsedText = fullText;
    if (!parsedText) {
        return "";
    }
    Object.keys(replaceWith).forEach((key) => {
        const replacement = replaceWith[key];
        if (replacement) {
            parsedText = parsedText.replaceAll(`{{${key}}}`, replacement);
        } else {
            parsedText = parsedText.replace(`{{${key}}}`, "").replace(/ +(?= )/g, "");
        }
    });

    return parsedText;
};

export const parsePlaceholderTextSingleBrace = (fullText, replaceWith) => {
    let parsedText = fullText;
    if (!parsedText) {
        return "";
    }
    Object.keys(replaceWith).forEach((key) => {
        const replacement = replaceWith[key];
        if (replacement) {
            parsedText = parsedText.replace(`{${key}}`, replacement);
        } else {
            parsedText = parsedText.replace(`{${key}}`, "").replace(/ +(?= )/g, "");
        }
    });

    return parsedText;
};

export const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return numberWithDecimalSeparator
        .toLocaleString(locale)[1];
};

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const splitAndPad = (number, precision = 2) => {
    const splitNumber = `${number}`
        .replace(".", ",")
        .split(",");
    return [
        splitNumber[0] || "0",
        (splitNumber[1] || "")
            .padEnd(precision, "0")
    ];
};

export const precisionRound = (number, precision = 2) => {
    const sign = Math.sign(number);
    const amount = Math.abs(number);
    const decDigits = 11;
    const multiplicator = 10 ** precision;
    const roundedNr = Math.round(
        parseFloat((amount * multiplicator).toFixed(decDigits))
    )
        / multiplicator;
    return splitAndPad(roundedNr * sign, precision);
};

export function isSuperSet(set, subset) {
    for (const e of subset) {
        if (!includes(set, e)) return false;
    }
    return true;
}

export const useToggle = (initialState = false) => {
    const [state, setState] = useState(initialState);

    const toggle = useCallback(() => setState((state) => !state), []);

    return [state, toggle];
};

export const hexToRGB = (h) => {
  let r = 0,
g = 0,
b = 0;

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;

  // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }

  return `rgb(${+r},${+g},${+b})`;
};

export const getValuesFromRGB = (rgb) => /\(([^)]+)\)/.exec(rgb)[1];

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === "object" && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export const mergeArrays = (arr1, arr2, id) => arr1.reduce((acc, b) => {
        const idx = acc.findIndex((item) => item[id] === b[id]); // look for the acc has the same id while iterating array1
        if (idx > -1) { // if found need to merge with value of array2
            acc[idx] = Object.assign(b, acc[idx]);
            return acc;
        }
        return [...acc, b]; // if we don't find anything so "b" is an unique entry
    }, arr2); // inital values of reduce is from array2

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export const isDate = (date) => (new Date(date) !== "Invalid Date") && isValidDate(new Date(date));

// eslint-disable-next-line camelcase
export function isISO_8601_Date(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return isValidDate(d);
}

export function isISODate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?(\.\d{3})?/.test(str)) return false;
    const d = new Date(str);
    return isValidDate(d) || isISO_8601_Date(str);
}

export function distributedCopy(items, n) {
    const elements = [items[0]];
     const totalItems = items.length - 2;
     const interval = Math.floor(totalItems / (n - 2));
    for (let i = 1; i < n - 1; i++) {
        elements.push(items[i * interval]);
    }
    elements.push(items[items.length - 1]);
    return elements;
}

// eslint-disable-next-line no-extend-native
Date.prototype.withoutTime = function () {
    const d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
};

export const dataTestId = (id, location = window.location) => {
    if (!id) return;
    const path = location.pathname;
    const value = snakeCase(`${path}_${id}`);

    return ({ "data-testid": value });
};

export const createData = (keys = [], attributes, { key = "attributeKey", value = "attributeValue", returnObject = {}, renameKeys = {} } = {}) => {
    if (isEmpty(key)) return returnObject;
    return keys.reduce((obj, k) => {
        const foundAttribute = find(attributes, attribute => attribute?.[key] === k);
        if (renameKeys[k]) {
            obj[renameKeys[k]] = foundAttribute ? foundAttribute?.[value] : "";
            return obj;
        }
        obj[k] = foundAttribute ? foundAttribute?.[value] : "";
        return obj;
    }, returnObject);
};


function createIteratee(converter, self) {
    return (result, value, key) => set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

export function createConverter(keyConverter = camelCase) {
    return function humps(node) {
        if (isArray(node)) return map(humps, node);
        if (isPlainObject(node)) return transform(node, createIteratee(keyConverter, humps));
        return node;
    };
}

export const sortObjectKeys = (obj, orderArray) => {
    return Object.keys(obj)
        .sort((key1, key2) => {
            const index1 = orderArray.indexOf(key1), index2 = orderArray.indexOf(key2);
            if (index1 === -1 || index2 === -1) {
                // If key is not in the orderArray, place it at the end, do not change original order
                return index1 === -1 ? (index2 === -1 ? 0 : 1) : -1;
            }
            return index1 - index2;
        })
        .reduce((result, key) => {
            result[key] = obj[key];
            return result;
        }, {});
}
