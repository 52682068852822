import Spinner from "@components/Spinner/Spinner";
import MotionContainer from "@components/transitions/MotionContainer";
import {Fade} from "@components/transitions/utils/variants";
import {useLoadingContext} from "@providers/LoadingContextProvider";
import {StyleProvider} from "@providers/StyleProvider";
import {Paper} from "@material-ui/core";
import {AnimatePresence} from "framer-motion";
import {useMemo} from "react";
import {useSpinDelay} from "spin-delay";
import {useIsFetching} from "react-query";
import {withStyles} from "@material-ui/styles";

const styles = (theme) => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.tooltip -1,
    }
});

const LoadingTileComponent = ({classes, loading = true, id = "fallback"}) => {
    const motionProperties = ({
        className: classes.root,
        variants: Fade({durationIn: 0, durationOut: 250}).in,
        initial: {opacity: 1},
        component: MotionContainer,
        elevation: 0,
    });

    return (
        <AnimatePresence>
            {loading && <Paper {...motionProperties} ><Spinner /></Paper>}
        </AnimatePresence>
    );
};

const LoadingTile = withStyles(styles, {name: "LoadingTile"})(LoadingTileComponent);

const withLoadingBoundary = Component => props => {
    return (
        <LoadingBoundary {...props}>
            <Component {...props}/>
        </LoadingBoundary>
    );
};

export const LoadingBoundary = ({children, ...props}) => {
    const contextId = useLoadingContext();
    const predicate = query => {
        const isObserver = query.observers.map(observer => observer.options.meta?.contextId).includes(contextId);
        const noPreviousData = query.state.data === undefined;

        return isObserver && noPreviousData;
    };
    const isFetching = useIsFetching({predicate});

    const timedIsFetching = useSpinDelay(isFetching > 0, {delay: 0, minDuration: 500});
    const loading = useSpinDelay(timedIsFetching, {delay: 0, minDuration: 500});

    const style = useMemo(() => {
        return ({display: timedIsFetching ? "none" : "initial"});
    }, [ timedIsFetching ]);

    return (
        <StyleProvider value={style}>
            <LoadingTile loading={loading} id={props?.id}/>
            {children}
        </StyleProvider>
    );
}

export default withLoadingBoundary;
