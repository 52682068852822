const hintModel = (obj, visible) => {
    return {
        heading: obj.hint.title,
        description: obj.hint.description,
        id: obj.id || "screen",
        isVisible: visible
    };
};

export const types = {
    GET_HINTS: "STEP_META/GET_HINTS",
    SHOW_SINGLE_HINT: "STEP_META/SHOW_SINGLE_HINT",
    GIVE_PRIORITY: "STEP_META/GIVE_PRIORITY",
    SET_HINT_COLLAPSED: "STEP_META/SET_HINT_COLLAPSED",
    TRIGGER_WARNING: "STEP_META/TRIGGER_WARNING"
};

export const initialState = {
    hints: {
        screen: [],
        // 'question': [],
        answer: []
    },
    cacheSelectedHint: {},
    collapsed: false,
    priority: null,
    warnings: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_HINTS:
            return {
                ...state,
                hints: {
                    screen: (action.hints && action.hints.hint) ? [hintModel(action.hints, true)] : [],
                    // question: action.hints ? action.hints.questions.filter(q => q.hint).map(q => hintModel(q, true)) : [],
                    answer: (action.hints && action.hints.questions && Array.isArray(action.hints.questions))
? action.hints.questions
                        .map(q => q.answers.filter(a => a.hint)
                            .map(a => hintModel(a, state.cacheSelectedHint && a.id === state.cacheSelectedHint.id)))
                        .reduce((arr, acc) => acc.concat(arr), [])
: []
                }
            };
        case types.SHOW_SINGLE_HINT:
            return {
                ...state,
                hints: {
                    ...state.hints,
                    [action.hint.type]: [
                        ...state.hints[action.hint.type].map(hint => ({ ...hint, isVisible: action.hint.id === hint.id }))
                    ]
                },
                cacheSelectedHint: action.hint,
                priority: "hints",
                collapsed: false
            };
        case types.GIVE_PRIORITY:
            return {
                ...state,
                priority: action.to
            };
        case types.SET_HINT_COLLAPSED: {
            return {
                ...state,
                collapsed: action.collapsed
            };
        }
        case types.TRIGGER_WARNING: {
            return {
                ...state,
                warnings: action.warnings
            };
        }
        default:
            return state;
    }
};

export const actions = {
    getHints: hints => ({ type: types.GET_HINTS, hints }),
    toggleHint: hint => ({ type: types.TOGGLE_HINT, hint }),
    showSingleHint: hint => ({ type: types.SHOW_SINGLE_HINT, hint }),
    setPriority: to => ({ type: types.GIVE_PRIORITY, to }),
    setHintCollapsed: collapsed => ({ type: types.SET_HINT_COLLAPSED, collapsed }),
    triggerWarning: warnings => ({ type: types.TRIGGER_WARNING, warnings })
};

export const getHints = (state) => state.stepMeta.hints;
export const getPriority = (state) => state.stepMeta.priority;
export const getHintCollapsed = (state) => state.stepMeta.collapsed;
export const getWarnings = (state) => state.stepMeta.warnings;

// export const getVisibility = (state) => state.stepMeta.isVisible;
