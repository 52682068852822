import type { StackProps } from "@components/Stack";
import type { Theme } from "@material-ui/core";

const styles = (theme: Theme) => ({
    root: {
        display: "flex",
        alignItems: (props: StackProps) => {
            if (props.centered === true) return "center";
            switch (props.direction) {
                case "row":
                case "row-reverse":
                    return "center";
                default:
                    return "flex-start";
            }
        },
        justifyContent: (props: StackProps) => {
            if (props.centered === true) return "center";
            switch (props.direction) {
                case "column":
                case "column-reverse":
                    return "center";
                default:
                    return "flex-start";
            }
        },
        flexDirection: (props: StackProps) => props.direction,
        gap: (props: StackProps) => theme.spacing(props.spacing ?? 1)
    }
});

export default styles;
