import palette from "@theme/palette";
import { Color } from "@utils/color";
import typography from "@theme/typography";

export default {
    root: {
        position: "relative",
        margin: [25, 0],
        padding: 0,
        height: 0,
    },
    marked: {
        marginBottom: 32,
    },
    trackBefore: {
        backgroundColor: palette.primary.main,
        borderColor: palette.primary.main,
        borderWidth: 1,
        borderStyle: "none",
        borderRadius: 8

    },
    trackAfter: {
        backgroundColor: palette.background.default,
        borderColor: palette.background.default,
        borderWidth: 1,
        borderStyle: "none",
        borderRadius: 8,
        boxShadow: `0 0 0px 2px ${Color(palette.primary.main).fade(0.75).toString()}`,
    },
    mark: {
        width: 8,
        height: 8,
        backgroundColor: palette.common.white,
        transform: "translate(-50%, 0)",
        borderRadius: 8,
        boxShadow: `0 0 0px 2px ${Color(palette.primary.main).desaturate(0.95).fade(0.9).toString()}`,
    },
    markActive: {
        width: 8,
        height: 8,
        backgroundColor: palette.common.white,
        transform: "translate(-50%, 0)",
        borderRadius: 8,
        opacity: 1
    },
    thumb: {
        height: 16,
        width: 16,
        marginTop: 0,
        marginLeft: 0,
        backgroundColor: palette.common.white,
        borderWidth: 0,
        top: 0,
        borderStyle: "none",
        boxShadow: `0 0 0px 2px ${Color(palette.primary.main).fade(0.25).toString()}`,
        transform: "translate(-50%, -25%)",
        "&$activated": {
            height: 16,
            width: 16,
        }
    },
    rail: {
        height: 8,
        opacity: 1,
        backgroundColor: Color(palette.primary.main).desaturate(0.95).fade(0.9).toString()
    },
    track: {
        height: 8,
        borderRadius: 8
    },
    markLabel: {
        ...typography.subtitle2
    },
    markLabelActive: {
        ...typography.subtitle2
    }
};
