import {actions as progress} from './progress';
import {getPath, payment, savingsRate} from '@services/api.service';
import {actions as header} from '@ducks/header';

export const types = {
    INVESTMENT_AMOUNT_LOAD: 'INVESTMENT_AMOUNT_LOAD/LOAD',
    INVESTMENT_AMOUNT_LOAD_SUCCESS: 'INVESTMENT_AMOUNT_LOAD/LOAD_SUCCESS',
    INVESTMENT_AMOUNT_LOAD_FAIL: 'INVESTMENT_AMOUNT_LOAD/LOAD_FAIL',
    INVESTMENT_AMOUNT_LOAD_INVESTMENT_HORIZON: 'INVESTMENT_AMOUNT_LOAD/INVESTMENT_HORIZON'
};

export const initialState = {
    performance: null,
    initialInvestment: 0,
    investmentAmount: 0,
    minimalSavingsPlan: 0,
    investmentHorizon: sessionStorage.getItem('investmentHorizon') || 3,
    performanceForecast: null,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.INVESTMENT_AMOUNT_LOAD_SUCCESS:
            return {
                ...state,
                ...action.investmentAmount,
                minimumInitialInvestment: action.investmentAmount._embedded.investmentCommitment.minimumInitialInvestment,
                initialInvestment: action.investmentAmount._embedded.investmentCommitment.initialInvestment,
                minimalSavingsPlan: action.investmentAmount._embedded.investmentCommitment.minimalSavingsPlan,
                savingsRate: action.investmentAmount._embedded.investmentAmount.savingsRate,
                performanceForecast: action.investmentAmount._embedded.performanceForecast,
                error: null,
            };
        case types.INVESTMENT_AMOUNT_LOAD_FAIL:
            return {...state, error: action.error};
        case types.INVESTMENT_AMOUNT_LOAD_INVESTMENT_HORIZON:
            return {
                ...state,
                investmentHorizon: action.investmentHorizon,
                error: null};
        default:
            return state;
    }
}

export const actions = {
    loadInvestmentAmount: () => (dispatch, getState) => new Promise(
        (resolve, reject) => {
            dispatch(progress.setLoading(true));
            getPath([{ path: "investment-amount" }]).then(re => {
                dispatch(actions.loadInvestmentAmountSuccess(re));
                dispatch(header.updateTitle((re.dictionary && re.dictionary.pageTitle) ? re.dictionary.pageTitle : ''));
                dispatch(progress.setLoading(false));
                resolve();
            }).catch(err => {
                dispatch(actions.loadInvestmentAmountFail(err));
                dispatch(progress.setLoading(false));
                reject(err);
            })
        }),
    updateInitialInvestment: (value, refresh = true) => (
        dispatch, getState) => {
            return payment(
                getState().investmentAmount._links.payment.href,
                value).then(() => {
                if (refresh) {
                    dispatch(actions.loadInvestmentAmount());
                }
            }).catch(err => {
                dispatch(progress.setLoading(false));
                return Promise.reject(err);
            });
    },
    updateSavingsRate: (value, refresh = true) => (
        dispatch, getState) => {
        return savingsRate(
            getState().investmentAmount._links.investmentAmount.href,
            value).then(() => {
            if (refresh) {
                dispatch(actions.loadInvestmentAmount());
            }
        }).catch(err => {
            dispatch(progress.setLoading(false));
            return Promise.reject(err);
        });
    },
    updateInvestmentHorizon: (investmentHorizon) => {
        const serializedState = JSON.stringify(investmentHorizon);
        sessionStorage.setItem('investmentHorizon', serializedState);
        return ({
            type: types.INVESTMENT_AMOUNT_LOAD_INVESTMENT_HORIZON,
            investmentHorizon,
        });
    },
    loadInvestmentAmountSuccess: (investmentAmount) => ({
        type: types.INVESTMENT_AMOUNT_LOAD_SUCCESS,
        investmentAmount,
    }),
    loadInvestmentAmountFail: (error) => ({type: types.INVESTMENT_AMOUNT_LOAD_FAIL, error})
};

export const getInvestmentAmount = (state) => state.investmentAmount;
export const getInvestmentHorizon = (state) => state.investmentAmount.investmentHorizon;
export const getInitialInvestment = (state) => state.investmentAmount.initialInvestment;
export const getSavingsRate = (state) => state.investmentAmount.savingsRate;
export const getMinimumInitialInvestment = (state) => state.investmentAmount.minimumInitialInvestment;
export const getMinimalSavingsPlan = (state) => state.investmentAmount.minimalSavingsPlan;
export const getPerformanceForecast = (state) => state.investmentAmount.performanceForecast;
export const getDictionary = (state) => state.investmentAmount.dictionary;
