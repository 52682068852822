import { CircularProgress } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import React from "react";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    spinner: {
        position: "relative",
    }
});

export default withStyles(styles, { name: "Spinner" })(React.forwardRef(({
    loading = true,
    size = 32,
    classes,
    disableShrink = true,
    ...props
}, ref) => {
    return (
        <Fade in={loading} timeout={1000}>
            <div className={classes.root}>
                <CircularProgress
                    classes={{
                        root: classes.spinner
                    }}
                    ref={ref}
                    size={size}
                    disableShrink={disableShrink}
                    {...props}
                />
            </div>
        </Fade>
    );
}));
