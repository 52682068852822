import { useInject } from "@core/application/ioc/hooks";
import type { MenuItem } from "@core/application/menu/contributables";
import { MenuRegistry } from "@core/application/menu/services";

import type { ComponentType } from "react";
import { useNavigate } from "@hooks/router";
import { findActiveMenuItem, getMenuItemPath } from "@core/application/menu/utils";
import isEmpty from "lodash/isEmpty";

const Item = ({ component: Component, ...props }: MenuItem & { component: ComponentType<MenuItem> }) => <Component key={props?.id} {...props} />;

export function useMenuItemEntries(DefaultMenuItemComponent: ComponentType<MenuItem>, menu?: symbol) {
    const registry = useInject(MenuRegistry);
    const menuEntries = registry.getOrderedMenuItems(menu);

    if (isEmpty(menuEntries) || !Array.isArray(menuEntries)) {
        return null;
    }

    return menuEntries.map((menuEntry) => <Item key={menuEntry.id} component={menuEntry.component ?? DefaultMenuItemComponent} {...menuEntry} />);
}

export function useNavigationMenu(menu?: symbol) {
    const navigate = useNavigate();
    const menuItems = useInject(MenuRegistry).getPolicyScopedMenuItems(menu);

    const defaultActiveMenuItem = menuItems.find((menuItem) => getMenuItemPath(menuItem) === ".");
    const activeMenuItem = findActiveMenuItem(menuItems);

    const onChangeActiveMenuItem = (menuItem: MenuItem) => navigate(getMenuItemPath(menuItem));

    return {
        activeMenuItem: activeMenuItem ?? defaultActiveMenuItem,
        menuItems,
        onChangeActiveMenuItem
    };
}
