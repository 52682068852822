import { getPath } from '@services/api.service';
import { actions as header } from '@ducks/header';

export const types = {
    CHECK_EMAIL_LOAD: 'CHECK_EMAIL/LOAD',
    CHECK_EMAIL_LOAD_SUCCESS: 'CHECK_EMAIL/LOAD_SUCCESS',
    CHECK_EMAIL_LOAD_FAIL: 'CHECK_EMAIL/LOAD_FAIL',
}

export const initialState = {
    checkEmailData: null,
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CHECK_EMAIL_LOAD_SUCCESS:
            return { ...state, checkEmailData: action.checkEmailData }
        case types.CHECK_EMAIL_LOAD_FAIL:
            return { ...state, error: action.error }
        default:
            return state;
        }
}

export const actions = {
    loadCheckEmail: () => (dispatch) => new Promise((resolve, reject) => {

        return getPath([{path: "check-email"}])
            .then(loadedCheckEmail => {
                dispatch(actions.loadCheckEmailSuccess(loadedCheckEmail));
                dispatch(header.updateTitle((loadedCheckEmail.dictionary && loadedCheckEmail.dictionary.pageTitle) ? loadedCheckEmail.dictionary.pageTitle : ''));
                resolve();
            })
            .catch(err => {
                dispatch(actions.loadCheckEmailFail(err));
                reject(err);
            })
        }),
    loadCheckEmailSuccess: (checkEmailData) => ({ type: types.CHECK_EMAIL_LOAD_SUCCESS, checkEmailData }),
    loadCheckEmailFail: (error) => ({ type: types.CHECK_EMAIL_LOAD_FAIL, error }),
}

export const getCheckEmailData = (state) => state.checkEmail.checkEmailData;
