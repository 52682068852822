import { useHomeLinksQuery } from "@hooks/home";
import { asDictionaryQuery, withEnabledCondition } from "@hooks/higher-order-hooks";
import { useQuery } from "react-query";
import { apiRequest } from "@services/api";

export * from "@common/bounded-context/customer-investment-strategy/hooks/customer-investment-strategy";

const useCustomerInvestmentStrategyFeasibilityScreenQuery = (options) => {
    const { data: url } = useHomeLinksQuery("customer-investment-strategy-feasibility-screen");

    const useConditionalQuery = withEnabledCondition(useQuery, (enabled) => enabled && !!url);

    const queryKey = ["customer-investment-strategy-feasibility", { url }];

    const queryFn = () => apiRequest
        .set("Accept", "application/json")
        .get(url)
        .send();

    return useConditionalQuery({ queryKey, queryFn, ...options });
};

/** @override */
export const useCustomerInvestmentStrategyFeasibilityDictionaryQueryImpl = asDictionaryQuery(useCustomerInvestmentStrategyFeasibilityScreenQuery);
