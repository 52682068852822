/* eslint-disable react/display-name */
import React, { useContext, useEffect } from "react";
import { Scope, withMetadata } from "@hooks/higher-order-hooks";
import { useExtendedQueryClient } from "@hooks/query-client";

const ModuleScopeContext = React.createContext();

const useModuleIdentifier = () => {
    return useContext(ModuleScopeContext);
};

const ModuleScopeProvider = ({ moduleId, children }) => {
    const queryClient = useExtendedQueryClient();

    useEffect(
        () => () => {
            queryClient.removeScopedQueries(Scope.MODULE, { moduleId });
        }
        , [moduleId, queryClient]
    );

    return (
        <ModuleScopeContext.Provider value={moduleId}>
            {children}
        </ModuleScopeContext.Provider>
    );
};

const withModuleScope = (useQuery) => (options) => {
    const moduleId = useModuleIdentifier();
    const fallback = moduleId === undefined;
    const scope = fallback ? Scope.PAGE : Scope.MODULE;

    const useQueryWithMetadata = withMetadata(useQuery, { scope, moduleId, fallback });

    return useQueryWithMetadata({ ...options, staleTime: Infinity });
};

const withModuleScopeProvider = ({ moduleId }) => (Component) => (props) => (
    <ModuleScopeProvider moduleId={moduleId}>
        <Component {...props}/>
    </ModuleScopeProvider>
);

export {
    ModuleScopeContext,
    ModuleScopeProvider,
    withModuleScopeProvider,
    withModuleScope
};
