import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import { batchActions } from 'redux-batched-actions';
import to from 'await-to-js';

import { legacyApiRequest } from '@services/api';

import { actions as progress } from '@ducks/progress';
import { actions as stepMetaActions } from '@ducks/step-meta';
import { getPath, putAnswers } from '@services/api.service';
import { actions as header } from '@ducks/header';

export const types = {
    ONBOARDING_LOAD_STEP_SUCCESS: 'ONBOARDING/LOAD_STEP_SUCCESS',
    ONBOARDING_LOAD_STEP_FAIL: 'ONBOARDING/LOAD_STEP_FAIL',
    ONBOARDING_STORE_ANWSERS: 'ONBOARDING/STORE_ANWSERS',
    ONBOARDING_APPLY_TO_ANSWERS: 'ONBOARDING/APPLY_TO_ANSWERS',
    ONBOARDING_STEP_ERROR: 'ONBOARDING/STEP_ERROR',
    ONBOARDING_CLEAR_ERRORS: 'ONBOARDING/CLEAR_ERRORS',
    ONBOARDING_STORE_QUESTIONNAIRE_MODULE: 'ONBOARDING/STORE_QUESTIONNAIRE_MODULE',
}

export const initialState = {
    currentStep: null,
    questions: [],
    answers: {},
    error: null,
    stepError: null,
    currentStepId: null,
    refreshAnswers: true,
    additions: null,
    warnings: [],
    questionnaireModule: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ONBOARDING_LOAD_STEP_SUCCESS:
            return {
                ...state,
                currentStep: action.step,
                currentStepId: action.currentStepId,
                answers: action.answers,
                questions: action.questions,
                additions: action.additions,
                warnings: action.warnings,
                error: null
            };
        case types.ONBOARDING_LOAD_STEP_FAIL:
            return { ...state, currentStep: null, currentStepId: null, error: action.error };
        case types.ONBOARDING_APPLY_TO_ANSWERS: {
            const answers = cloneDeep(state.answers);
            return { ...state, answers: set(answers, action.path, action.value) };
        }
        case types.ONBOARDING_STORE_ANWSERS:
            return { ...state, answers: action.answers };
        case types.ONBOARDING_STEP_ERROR:
            return { ...state, stepError: action.stepError };
        case types.ONBOARDING_CLEAR_ERRORS:
            return { ...state, stepError: null };
        case types.ONBOARDING_STORE_QUESTIONNAIRE_MODULE:
            return { ...state, questionnaireModule: action.questionnaireModule };
        default:
            return state;
    }
}

let loadStepPromise;

const errorHandler = (dispatch) => (error) => {
    if (!!error.handled) {
        return Promise.reject(error);
    }

    dispatch(privateActions.loadStepFail(error));
    return Promise.reject(error);
}

const getRiskProfileStart = (dispatch, desiredStepId) =>
    getPath([{ path: 'risk-profile-start' }])
        .then(
            res => {
                dispatch(privateActions.storeQuestionnaireModule(res.questionnaireModuleName));

                if (desiredStepId in res._links) {
                    return res._links[desiredStepId];
                }

                return res._links['current-step'];
            },
            (err) => {
                if (err.response.statusCode === 404) {
                    return postRiskProfileStart();
                }

                return Promise.reject(err);
            }
        );

const postRiskProfileStart = (dispatch) =>
    getPath([{ path: 'risk-profile-start', method: 'post' }])
        .then(res => {
            dispatch(privateActions.storeQuestionnaireModule(res.questionnaireModuleName));

            return res._links['current-step']
        });

const privateActions = {
    loadStepSuccess: (
        currentStepId,
        step,
        answers,
        questions,
        additions,
        warnings,
    ) => ({
        type: types.ONBOARDING_LOAD_STEP_SUCCESS,
        step,
        currentStepId,
        answers,
        questions,
        additions,
        warnings,
    }),
    loadStepFail: error => ({ type: types.ONBOARDING_LOAD_STEP_FAIL, error }),
    displayStepError: stepError => ({ type: types.ONBOARDING_STEP_ERROR, stepError }),
    clearErrors: _ => ({ type: types.ONBOARDING_CLEAR_ERRORS }),
    buildError: (errors) => (dispatch, getState) => {
        const parsedErrors = errors.map(e =>
            ({ ...e, description: getCurrentStep(getState()).dictionary[e.errorCode] }));

        dispatch(privateActions.displayStepError(parsedErrors));
    },
    loadStep: (stepLink) => async (dispatch) => {
        if (loadStepPromise) {
            loadStepPromise.abort();
            loadStepPromise = null;
        }
        loadStepPromise = legacyApiRequest.get(stepLink.href);

        const [stepErr, stepResp] = await to(loadStepPromise);
        if (stepErr) errorHandler(dispatch);

        const step = stepResp.body;

        const currentStepId = stepLink.name;

        // Dont mistakenly use screen service step id. Always use ids from advisory-service.
        delete step.id;

        const links = get(step, '_links');

        const answersLink = get(links, 'answers.href');

        let answersErr, answersResp;
        if (answersLink) {
            loadStepPromise = legacyApiRequest.get(answersLink);
            [answersErr, answersResp] = await to(loadStepPromise);
        }

        const preferredQuestionsLink = get(answersResp, 'body._links.bc-investment-profile-knowledge-and-experience-value-ranges.href');

        const questionsLink = preferredQuestionsLink || get(links, 'questions.href');

        let questionsErr, questionsResp;
        if (questionsLink) {
            loadStepPromise = legacyApiRequest.get(questionsLink);
            [questionsErr, questionsResp] = await to(loadStepPromise);
        }

        if (questionsErr || answersErr) errorHandler(dispatch);

        let { questions, hint, warnings } = get(questionsResp, 'body', {});

        if (preferredQuestionsLink) {
            questions = get(questionsResp, 'body');
        }

        let { answers, additions } = get(answersResp, 'body', {});

        // to be removed when answers are fetched from advisory-service
        //answers = get(step, '_embedded.answers');

        dispatch(batchActions([
            privateActions.loadStepSuccess(
                currentStepId,
                step,
                answers,
                questions,
                additions,
                warnings,
            ),

            header.updateTitle((step.dictionary && step.dictionary.pageTitle) ? step.dictionary.pageTitle : '', (step.dictionary && step.dictionary.stepName) ? step.dictionary.stepName : null),

            // TODO: Check if implementation can be changed to make this less ugly
            stepMetaActions.getHints({ hint, questions }),

            stepMetaActions.setHintCollapsed(false)
        ]));

        return currentStepId;
    },
    storeQuestionnaireModule: questionnaireModule => ({
        type: types.ONBOARDING_STORE_QUESTIONNAIRE_MODULE,
        questionnaireModule,
    }),
};

export const actions = {
    enterProcess: (desiredStepId) => (dispatch) => {
        dispatch(progress.setLoading(true));

        return (!!desiredStepId ? getRiskProfileStart(dispatch, desiredStepId) : postRiskProfileStart(dispatch))
            .then(currentStepLink => privateActions.loadStep(currentStepLink)(dispatch))
            .catch(errorHandler(dispatch))
            .finally(() => dispatch(progress.setLoading(false)));
    },
    nextStep: () => (dispatch, getState) => {
        const cs = getCurrentStep(getState());
        const url = cs._links['answers'].href;

        dispatch(batchActions([
            progress.setLoading(true),
            privateActions.clearErrors(),
            stepMetaActions.setHintCollapsed(false)
        ]));

        return putAnswers(url, { answers: getAnswers(getState()) })
            .then(
                () => cs._links['next-step'],
                (err) => {
                    if (get(err, 'response.body.answerErrors')) {
                        err.handled = true;
                        dispatch(batchActions([
                            privateActions.buildError(err.response.body.answerErrors),
                            stepMetaActions.setPriority('errors'),
                            stepMetaActions.setHintCollapsed(true)
                        ]));
                    }

                    return Promise.reject(err);
                }
            )
            .then(nextStepLink =>
                legacyApiRequest.post(nextStepLink.href)
                    .then(resp => resp.body._links['current-step'])
                    .then((currentStepLink) => privateActions.loadStep(currentStepLink)(dispatch))
            )
            .catch(errorHandler(dispatch))
            .finally(() => dispatch(progress.setLoading(false)));
    },
    prevStep: () => (dispatch, getState) => {
        const cs = getCurrentStep(getState());
        const prevStepLink = cs._links['previous-step'];

        dispatch(batchActions([
            progress.setLoading(true),
            privateActions.clearErrors(),
            stepMetaActions.setHintCollapsed(false)
        ]));

        return legacyApiRequest.post(prevStepLink.href)
            .then(resp => resp.body._links['current-step'])
            .then((currentStepLink) => privateActions.loadStep(currentStepLink)(dispatch))
            .catch(errorHandler(dispatch))
            .finally(() => dispatch(progress.setLoading(false)));
    },
    finishRiskProfile: () => (dispatch) => {
        dispatch(progress.setLoading(true));
        return getPath([{ path: 'risk-profile-finish', method: 'post', body: {} }])
            .then(_ => _)
            .catch(errorHandler(dispatch))
            .finally(() => dispatch(progress.setLoading(false)));
    },
    storeAnswers: (answers) => ({ type: types.ONBOARDING_STORE_ANWSERS, answers }),
    applyToAnswers: (path, value) => ({
        type: types.ONBOARDING_APPLY_TO_ANSWERS,
        path,
        value
    }),
}

export const getCurrentStep = (state) => state.onboarding.currentStep;
export const getQuestions = (state) => state.onboarding.questions;
export const getCurrentStepId = (state) => state.onboarding.currentStepId;
export const getErrors = (state) => state.onboarding.error;
export const getStepError = (state) => state.onboarding.stepError;
export const getAnswers = (state) => state.onboarding.answers;
export const getWarnings = (state) => state.onboarding.warnings;
export const getAdditions = (state) => state.onboarding.additions;
export const getQuestionById = (state, id) => state.onboarding.questions.find(x => x.id === id);
export const getAnswerById = (state, id) => state.onboarding.answers.find(x => x.id === id);
export const getDataById = (state, id) => ({
    question: getQuestionById(state, id),
    answer: getAnswerById(state, id),
    id
});
export const getQuestionnaireModule = state => state.onboarding.questionnaireModule;
