import Color from "color";

const styles = theme => ({
    menuRoot: {
        color: theme.palette.text.main,
        padding: theme.spacing(0.5,0),
        boxShadow: theme.shadows[8]
    },
    listRoot: {},
    itemRoot: {
        ...theme.typography.subtitle2,
        fontSize: "1rem",
        justifyContent: "flex-end",
        minWidth: theme.spacing(25),
        minHeight: theme.spacing(6),
        '&:hover': {
            backgroundColor: Color(theme.palette.primary.main).toString(),
            color: Color(theme.palette.primary.main).isDark() ? theme.palette.common.white : theme.palette.text.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: Color(theme.palette.primary.main).isDark() ? theme.palette.common.white : theme.palette.text.main
            },
        }
    },
    itemIcon: {
        width: theme.spacing(4),
        height: "auto",
        marginRight: "auto",
        paddingRight: theme.spacing(5),
        display: "flex",
        alignItems: "center"
    }
});

export default styles;
