import React, { useMemo } from "react";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import { useProperties } from "@providers/PropertiesProvider";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDataTestId } from "@hooks/utils";

const styles = theme => ({
    root: {
        ...theme.typography.button,
        padding: theme.spacing(1, 2),
        minHeight: theme.spacing(6),
        borderRadius: theme.shape.borderRadius,
    },
    backButton: {
        width: "100%",
        minWidth: 110,
        padding: 0,
        display: "flex",
        justifyContent: "flex-start",
    },
    goBackElement: {
        marginRight: 10,
    },
    label: {},
    startIcon: {},
    textSizeSmall: {
        "&": {
            minHeight: "auto !important",
            fontSize: theme.typography.body2.fontSize,
            padding: theme.spacing(1, 0.5)
        }
    },
    sizeSmall: {
        "&": {
            minHeight: "auto !important",
            fontSize: theme.typography.body2.fontSize,
            padding: theme.spacing(1)
        }
    },
    outlinedSizeSmall: {
        extend: "sizeSmall",
    },
    containedSizeSmall: {
        extend: "sizeSmall",
    }
});

const ButtonComponent = React.forwardRef(
    (
        {
            children,
            gobackbutton = false,
            goforwardbutton = false,
            height = null,
            margin,
            classes,
            goToPaymentButton,
            id,
            ...props
        }
        , ref
    ) => {
        const { variant } = props;
        const properties = useProperties();
        const disabled = useMemo(() => properties?.disabled ?? false, [properties]);
        const dataTestId = useDataTestId(id);

        return (
            <Button
                id={id}
                ref={ref}
                variant={variant ?? gobackbutton ? "text" : "contained"}
                color="primary"
                style={{ height, margin }}
                classes={{
                    root: classes.root,
                    label: classes.label,
                    startIcon: classes.startIcon,
                    sizeSmall: classes.sizeSmall,
                    textSizeSmall: classes.textSizeSmall,
                    outlinedSizeSmall: classes.outlinedSizeSmall,
                    containedSizeSmall: classes.containedSizeSmall
                }}
                aria-disabled={disabled}
                disabled={disabled}
                disableRipple={variant === "text"}
                startIcon={gobackbutton && <ChevronLeftIcon color={"inherit"}/>}
                endIcon={goforwardbutton && <ChevronRightIcon color={"inherit"}/>}
                {...props}
                {...dataTestId}
            >
                {children}
            </Button>
        );
    });

export default withStyles(styles, { name: "ButtonComponent" })(ButtonComponent);
