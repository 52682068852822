import { getPath } from '@services/api.service';
import { register } from '@services/auth.service';
import { actions as progress } from '@ducks/progress';
import { actions as header } from '@ducks/header';
import { actions as authActions } from '@ducks/auth';

export const types = {
    REGISTRATION_SUCCESS: 'REGISTRATION/SUCCESS',
    REGISTRATION_LOAD_SUCCESS: 'REGISTRATION/LOAD_SUCCESS',
    REGISTRATION_LOAD_FAIL: 'REGISTRATION/LOAD_FAIL',
    EMAIL_ERROR: 'REGISTRATION/EMAIL_ERROR',
    PASSWORD_ERROR: 'REGISTRATION/PASSWORD_ERROR',
    RETYPE_PASS_ERROR: 'REGISTRATION/RETYPE_PASS_ERROR',
    CLEAR_ERRORS: 'REGISTRATION/CLEAR_ERRORS',
    GENERIC_ERROR: 'REGISTRATION/GENERIC_ERROR',
};

const errorMap = {
    BAD_EMAIL_SYNTAX: [types.EMAIL_ERROR],
    VIOLATED_PASSWORD_REQUIREMENTS: [types.PASSWORD_ERROR, types.RETYPE_PASS_ERROR],
    EMAIL_AND_PASSWORD_NOT_OK: [types.EMAIL_ERROR, types.PASSWORD_ERROR, types.RETYPE_PASS_ERROR],
    EMAIL_NOT_WHITELISTED: [types.EMAIL_ERROR],
    DUPLICATE_EMAIL: [types.EMAIL_ERROR]
}

export const initialState = {
    user: null,
    loadedRegister: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.REGISTRATION_SUCCESS:
            return { user: { ...action.user } }
        case types.REGISTRATION_LOAD_SUCCESS:
            return { ...state, loadedRegister: action.loadedRegister };
        case types.REGISTRATION_LOAD_FAIL:
            return { ...state, loadedRegister: null };
        case types.EMAIL_ERROR:
            return { ...state, emailError: action.emailError };
        case types.PASSWORD_ERROR:
            return { ...state, passwordError: action.emailError };
        case types.RETYPE_PASS_ERROR:
            return { ...state, retypePassError: action.emailError };
        case types.CLEAR_ERRORS:
            return {...state, emailError:null, passwordError: null, retypePassError: null };
        case types.GENERIC_ERROR:
          return { ...state, passwordError: action.emailError };
        default:
            return state;
    }
};

export const actions = {
    loadRegister: (href) => (dispatch) => {
        dispatch(progress.setLoading(true));
        getPath([{ path: "register-screen" }]).then(loadedRegister => {
            dispatch(actions.loadRegisterSuccess(loadedRegister));
            dispatch(progress.setLoading(false));
            dispatch(header.updateTitle((loadedRegister.dictionary && loadedRegister.dictionary.pageTitle) ? loadedRegister.dictionary.pageTitle : ''));
        }).catch(err => {
            dispatch(actions.loadRegisterFail(err));
            dispatch(progress.setLoading(false));
        });
    },
    register: (user, pass, dataprotection) => (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(progress.setLoading(true));
        dispatch(actions.clearErrors());
        register(user, pass, dataprotection)
            .then(() => {
              dispatch(authActions.authenticationEstablished('USER'));
              return getPath([{ path: 'user-data-context' }])
            })
            .then(re => {
                dispatch(actions.registerSuccess(re._embedded.userData[0]));
                dispatch(progress.setLoading(false));

                resolve();
            })
            .catch(err => {
                if (err.response.body && errorMap[err.response.body.errorType]) {
                    errorMap[err.response.body.errorType].forEach(errorType => dispatch(actions.triggerError(errorType, getState().registration.loadedRegister.dictionary[err.response.body.errorType])));
                }
                else {
                    dispatch(actions.triggerError(types.GENERIC_ERROR, getState().registration.loadedRegister.dictionary.genericError));
                }

                reject();
                dispatch(progress.setLoading(false));
            })
    }),
    registerSuccess: user => ({ type: types.REGISTRATION_SUCCESS, user }), // (dispatch) => dispatch(authActions.loginSuccess(user)),//
    loadRegisterSuccess: loadedRegister => ({ type: types.REGISTRATION_LOAD_SUCCESS, loadedRegister }),
    loadRegisterFail: () => ({ type: types.REGISTRATION_LOAD_FAIL }),
    displayStepError: stepError => ({ type: types.REGISTRATION_STEP_ERROR, stepError }),
    hideToast: () => ({ type: types.REGISTRATION_HIDE_TOAST }),
    triggerError: (type, emailError) => ({ type, emailError }),
    clearErrors: () => ({type: types.CLEAR_ERRORS})
};

export const loadRegister = (state) => state.registration.loadedRegister;
export const getUser = (state) => state.registration.user;
export const getEmailError = (state) => state.registration.emailError;
export const getPasswordError = (state) => state.registration.passwordError;
export const getRetypePassError = (state) => state.registration.retypePassError



