import React, { memo } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "@components/Modal.styles";

import {
    DialogTitle,
} from "@material-ui/core";

export const Header = ({ children, classes, ...props }) => (
        <DialogTitle
            id="modal-title"
            disableTypography
            classes={{
                root: classes.header
            }}
            {...props}
        >
            {children}
        </DialogTitle>
    );

Header.displayName = "ModalHeader";

export default memo(withStyles(styles, { name: "ModalHeader" })(Header));
