import { legacyApiRequest } from '@services/api';

export const getStep = href => {
  return new Promise((resolve, reject) => {
    return legacyApiRequest.put(href)
        .set('Content-Type', 'application/hal+json')
        .set("Accept", "application/hal+json")
        .withCredentials()
        .then(re =>
            legacyApiRequest.get(re.body._links["current-step"].href).withCredentials())
                .then(re => resolve(re.body))
                .catch(err => reject(err))
        .catch(err => reject(err));
    });
}

export const getStepPersonalData = href => {
  return new Promise((resolve, reject) => legacyApiRequest.get(href)
    .set("Accept", "application/hal+json")
    .withCredentials()
    // .then(re => apiRequest.get(re.body._links[""].href).withCredentials())
    .then(re => resolve(re.body)));
}

export const getBreakdownMock = () => {
    const breakdowns = {
        "ECO":{
            "summary":{
                "value":9014.92,
                "delta": 75.35,
                "currency":"€"
            },
                "breakdown":[
                {
                    "name":"iShares Core MSCI Japan IMI UITS ETF",
                    "stock":1105,
                    "value":2001.05,
                    "currency": "€"
                },
                {
                    "name":"iShares Core MSCI Pacific ex Japan UITS ETF",
                    "stock":7135,
                    "value":701.15,
                    "currency": "€"
                }
            ],
        },
        "ASIAN_SHARES":{
            "summary":{
                "value":1968.64,
                "delta": 391.33,
                "currency":"€"
            },
            "breakdown":[
                {
                    "name":"iShares Automation & Robotics UITS ETF",
                    "stock":1327,
                    "value":1968.64,
                    "currency": "€"
                }
            ],
            "series": [
                {
                    "title": "together",
                    "data": [0, 1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map(n => {
                    return { "date": new Date(2018, 0, 1+n*15), "value": 20000 + Math.random()* 20000 }
                    }),
                    "color": "#70AAD6"
                },
                {
                    "title": "ASIAN_SHARES",
                    "data": [0, 1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map(n => {
                    return { "date": new Date(2018, 0, 1+n*15), "value": 20000 + Math.random()* 20000 }
                    }),
                    "color": "#E1C098"
                }
            ]
        }
    }
    return breakdowns;
}

export const getProductPresentation = (initialInvestment, monthlyInvestment) => new Promise((resolve, reject) => resolve({
  "parameters": {
    "initial-investment": initialInvestment,
    "monthly-investment": monthlyInvestment
  },
  "dictionary": {
    "page-title": "Hier sehen Sie persönliche Geldanlage für die nächster 3 Jahre",
    "page-subtitle": "- investify stimmt die Zusammensetzung ihrer Geldanlage laufend auf ihr individuelles Profil ab -",
    "pie-expand": "Details anzeigen",
    "back": "Zurück",
    "more-info": "mehr info",
    "chart-title": "Prognostizierte Wertentwicklung Ihrer Anlage",
    "info-title": "Ihre Chancen",
    "initial-investment": "Initiale Einzhalung",
    "monthly-investment": "Monatliche Zahlung",
    "portfolio-download": "Portfolio downloaden",
    "change-themes": "Möchten Sie sich nochmal die Themen ansehen?",
    "cost-breakdown": "Kostenblatt ansehen",
    "gross-revenue": "Enwartete Rendite",
    "all-in-stuff": "All-In Gebühr",
    "product-cost": "Produktkosten",
    "net-revenue": "Netto-Rendite",
    "all-per-year": "Alle Angaben pro Jahr",
    "PRODUCT_CHART_OPTIMISTIC": "I'm besten fall (95% Quantil)",
    "PRODUCT_CHART_EXPECTED": "erwartete Wertenwicklung",
    "PRODUCT_CHART_PESSIMISTIC": "Schlimmsten Fall (5% Quantil)",
    "chart-marker": "Ihre gewählte Anlagedauer",
    "even-more-info": "Deutsche AlgemeineZeitung"
  },
  "split": [{
    "id": "CORE",
    "title": "Basisanlage",
    "color": "#70AAD6",
    "percent": .3,
    "description": "Id voluptate consequat aliqua deserunt reprehenderit. Cupidatat non nisi Lorem deserunt. Labore sunt minim reprehenderit et eiusmod officia magna. Laboris laboris dolore amet incididunt ut aute pariatur et ad commodo occaecat velit est. Dolore adipisicing pariatur ea cupidatat aliquip culpa adipisicing excepteur sunt ut."
  },
  {
    "id": "TOPICS",
    "title": "Themen",
    "color": "#E1C098",
    "percent": .7,
    "description": "Officia nulla qui aliqua incididunt ipsum. Ipsum sunt deserunt eu adipisicing cupidatat aliqua. Reprehenderit est culpa voluptate duis mollit ad sit anim voluptate tempor et consequat. Officia anim proident ea do voluptate. Cupidatat magna in anim Lorem minim exercitation. Commodo officia sit ipsum anim do consequat irure ea. Ad commodo exercitation in deserunt pariatur ipsum consectetur eu minim proident qui officia."
  }],
  "performance": {
    "revenue": {
      "currency": "€",
      "value": 123.56,
      "percentile": .12
    },
    "investment": {
      "currency": "€",
      "value": 12.34,
      "percentile": -.3
    },
    "cost": {
      "currency": "€",
      "value": 1.23,
      "percentile": .23
    },
    "net": {
      "currency": '€',
      "value": 1234.5,
      "percentile": 1.23
    }
  },
  "pdfLink":{
    "url":"https://google.com",
    "literal":"cost-breakdown"
  },
  "performance-chart": {
    "title": "chart-title",
    "description":"Labore id in exercitation magna ex id incididunt eu voluptate excepteur officia dolore. Ullamco sint non eiusmod dolore. Enim culpa culpa nisi qui sunt nisi et amet fugiat nostrud ipsum ea non elit. Excepteur officia sint commodo laborum exercitation enim minim ad occaecat dolor nulla excepteur sunt.",
    "link":{
        "url":"https://google.com",
        "literal":"even-more-info"
    },
    "series": [
      {
        "title": "PRODUCT_CHART_OPTIMISTIC",
        "data": [0, 1, 2, 3, 4, 5, 6, 7].map(n => {
          return { "year": 2018 + n, "value": initialInvestment + monthlyInvestment * 2 * n }
        }),
        "color": "green"
      },
      {
        "title": "PRODUCT_CHART_PESSIMISTIC",
        "data": [0, 1, 2, 3, 4, 5, 6, 7].map(n => {
          return { "year": 2018 + n, "value": initialInvestment + monthlyInvestment * n }
        }),
        "color": "red"
      },
      {
        "title": "PRODUCT_CHART_EXPECTED",
        "data": [0, 1, 2, 3, 4, 5, 6, 7].map(n => {
          return { "year": 2018 + n, "value": initialInvestment + monthlyInvestment * 1.5 * n }
        }),
        "color": "black"
      },
    ],
  }
}));

export const getMyTopics = () => new Promise((resolve, reject) => resolve([
  {
    "id": "ECO",
    "color": "#EBBDC2",
    "title": "Ökologie",
    "percent": .2,
    "description": "Non ea duis ullamco mollit commodo cillum voluptate dolore laboris elit irure aute. In sit deserunt elit ea tempor aliquip et esse mollit veniam aliquip nulla voluptate. Dolore nisi in culpa occaecat aliqua culpa quis incididunt. Occaecat aliqua laborum irure duis dolore laboris nisi voluptate aute esse enim velit qui duis. Ad ipsum quis excepteur do minim ipsum dolor fugiat. Labore sit culpa fugiat anim elit dolore nisi adipisicing."
  },
  {
    "id": "OLD_GEEZERRS",
    "color": "#D9848B",
    "title": "Alternde Bevölkerung",
    "percent": .1,
    "description": "Et voluptate et nisi occaecat mollit incididunt quis veniam excepteur consectetur Lorem laboris esse. Esse est ut proident incididunt nostrud occaecat reprehenderit laboris veniam ut. Dolor est labore fugiat culpa cupidatat mollit eu pariatur veniam culpa."
  },
  {
    "id": "GOLD",
    "color": "#CF666D",
    "title": "Gold",
    "percent": .7,
    "description": "Labore exercitation do nostrud anim ex. Exercitation sunt non excepteur nostrud eiusmod do tempor excepteur duis dolore est consectetur enim in. Velit exercitation voluptate et aliquip ad qui occaecat aute irure cillum velit. Enim proident qui consequat nulla irure fugiat ullamco laboris cillum velit elit do ad."
  }]
));

export const getMyCore = () => new Promise((resolve, reject) => resolve([
  {
    "id": "ASIAN_SHARES",
    "color": "#1A2693",
    "title": "Asiatische Aktien",
    "percent": .2,
    "description": "Non ea duis ullamco mollit commodo cillum voluptate dolore laboris elit irure aute. In sit deserunt elit ea tempor aliquip et esse mollit veniam aliquip nulla voluptate. Dolore nisi in culpa occaecat aliqua culpa quis incididunt. Occaecat aliqua laborum irure duis dolore laboris nisi voluptate aute esse enim velit qui duis. Ad ipsum quis excepteur do minim ipsum dolor fugiat. Labore sit culpa fugiat anim elit dolore nisi adipisicing."
  },
  {
    "id": "AMERICAN_SHARES",
    "color": "#85BDDD",
    "title": "Amerikanische Aktien",
    "percent": .1,
    "description": "Et voluptate et nisi occaecat mollit incididunt quis veniam excepteur consectetur Lorem laboris esse. Esse est ut proident incididunt nostrud occaecat reprehenderit laboris veniam ut. Dolor est labore fugiat culpa cupidatat mollit eu pariatur veniam culpa."
  },
  {
    "id": "STATE_BONDS",
    "color": "#5665EC",
    "title": "Staatsanleihen",
    "percent": .7,
    "description": "Labore exercitation do nostrud anim ex. Exercitation sunt non excepteur nostrud eiusmod do tempor excepteur duis dolore est consectetur enim in. Velit exercitation voluptate et aliquip ad qui occaecat aute irure cillum velit. Enim proident qui consequat nulla irure fugiat ullamco laboris cillum velit elit do ad."
  }
]));

export const getLandingPage = () => new Promise((resolve, reject) => resolve({
  "id": "LANDING_PAGE",

  "_links": {
    "self": { "href": "/landing-page/screen" }
  },

  "_embedded": {
    "landingImageUrl1": "https://images.ctfassets.net/8j6mzqtgmxed/1rZpP1fLUsYMuk8uqw6MYE/80615646b493853f8c5232ef77934652/landing1.jpeg",
    "landingImageUrl2": "https://images.ctfassets.net/8j6mzqtgmxed/76ChJCVOmsm6EUWK40gyye/18b213de2bbb5194e3b6c5241c3632ef/landing2.jpeg",
    "landingImageUrl3": "https://images.ctfassets.net/8j6mzqtgmxed/6WRakVlmBUGQCWcEKCAu2q/a9c7958ad573a4ccdb09b941674d28d6/landing3.jpeg",
    "landingImageUrl4": "https://images.ctfassets.net/8j6mzqtgmxed/4clrKBbYI0wAa2QomUEWOA/30af17f3125dc4fb7c034008aada9275/landing4.jpeg",
    "landingImageUrl5": "https://images.ctfassets.net/8j6mzqtgmxed/4gvP0jQpLaU8woumM6ygSO/8d6230d503730e9e135ab12fdc8a63f9/landing5.jpeg",

    "splitInvestments": [{
      "id": "EMERGING",
      "title": "Emerging market stocks",
      "color": "#F4DADC",
      "percent": .1,
    },
    {
      "id": "ASIAN",
      "title": "Asian/pacific stocks",
      "color": "#EBBDC2",
      "percent": .33,
    },
    {
      "id": "AMERICAN",
      "title": "American stocks",
      "color": "#D9848B",
      "percent": .37,
    },
    {
      "id": "EUROPEAN",
      "title": "European stocks",
      "color": "#CF666D",
      "percent": .2,
    }],

    "dictionary": {
      "landingSupText": "Investify offers you a monetary investment that is individually tailored to your needs",
      "landingInfText": "We would like to know you better so that you can personalize your investments. How does your personal risk profile looks?",
      "buttonRiskProfile": "Create your risk profile"
    }
  }
}));

export const getTransactions = () => new Promise((resolve, reject) => resolve({
    "id": "TRANSACTIONS_PAGE",

    "_links": {
      "self": { "href": "/transactions-page/screen" }
    },

    "dictionary": {
        "title": "Total stock",
        "totalStock": 13658.98,
        "infoSidebarTitle": "Any question?",
        "infoSidebar": "If you have questions about your transactions, you can always contact our support.",
        "phoneNumberSidebar1": "+352 27 86 28 68",
        "phoneNumberSidebar2": "+49 241 9550 9291",
        "emailSidebar": "service@investify.com",
        "trasactionsButton": "Back to the homepage",
        "paginationText1": "Page",
        "paginationText2": "of",
        "infoText": "Wir zeigen Ihnen die Transaktionen der letzten 60 Tage an. Wenn Sie mehr Transaktionen sehen mochten, melden Sie sich bitte bei unserem Support."
    },

      "_embedded": {
          "transactions": [{
              "impactDate": "2019-10-11",
              "type": "ACCOUNT_BALANCING",
              "value": 0.13,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2019-10-11",
              "type": "TAX_EQUALIZATION",
              "value": -0.02,
              "netEffect": "NEGATIVE"

          }, {
              "impactDate": "2018-10-10",
              "type": "CREDIT",
              "purpose": "ISIN:DE000ETFL227",
              "value": 0.05,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2018-09-26",
              "type": "CREDIT",
              "purpose": "ISIN:IE0032523478",
              "value": 0.09,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2018-09-12",
              "type": "ACCOUNT_BALANCING",
              "value": 0.07,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2018-09-12",
              "type": "TAX_EQUALIZATION",
              "value": -0.01,
              "netEffect": "NEGATIVE"

          }, {
              "impactDate": "2018-09-11",
              "type": "TAX_EQUALIZATION",
              "value": 1.92,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2018-08-30",
              "type": "CREDIT",
              "purpose": "ISIN:IE00B1FZS467",
              "value": 0.41,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2018-08-28",
              "type": "PURCHASE",
              "purpose": "ISIN:LU0631859062",
              "value": -30.57,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-27",
              "type": "SALE",
              "purpose": "ISIN:DE000A0KFFW9",
              "value": 24.76,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:LU0446734526",
              "value": -3.02,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:LU0136240974",
              "value": -5.31,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:LU0446734104",
              "value": -11.37,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00B1FZS467",
              "value": -4.34,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00BKM4GZ66",
              "value": -7.32,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00B3ZW0K18",
              "value": -15.60,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00B86MWN23",
              "value": -8.85,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00B6SPMN59",
              "value": -7.08,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00BWBXM948",
              "value": -49.93,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:DE0006289465",
              "value": -3.77,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:DE000ETFL227",
              "value": -1.31,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00B4WXJJ64",
              "value": -5.48,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00BYZK4552",
              "value": -54.47,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:DE0005933923",
              "value": -35.58,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:DE0005933972",
              "value": -18.28,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2018-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00BSKRJX20",
              "value": -2.31,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2017-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE00BD1F4M44",
              "value": -9.64,
              "netEffect": "NEUTRAL"

          }, {
              "impactDate": "2017-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:LU0846194776",
              "value": -4.72,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2017-08-24",
              "type": "PURCHASE",
              "purpose": "ISIN:IE0032523478",
              "value": -2.43,
              "netEffect": "NEUTRAL"
          }, {
              "impactDate": "2017-08-21",
              "type": "TRANSFER_IN",
              "value": 250.00,
              "netEffect": "POSITIVE"
          }, {
              "impactDate": "2017-08-16",
              "type": "CREDIT",
              "purpose": "ISIN:DE0006289465",
              "value": 0.11,
              "netEffect":  "POSITIVE"

          }, {
              "impactDate": "2017-08-09",
              "type": "TAX_EQUALIZATION",
              "value": 1.05,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2017-08-03",
              "type": "CREDIT",
              "purpose": "ISIN:LU0446734526",
              "value": 0.83,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2017-08-03",
              "type": "CREDIT",
              "purpose": "ISIN:LU0136240974",
              "value": 0.58,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2017-08-03",
              "type": "CREDIT",
              "purpose": "ISIN:LU0446734104",
              "value": 3.42,
              "netEffect": "POSITIVE"

          }, {
              "impactDate": "2017-08-02",
              "type": "TAX_EQUALIZATION",
              "value": 14.60,
              "netEffect": "POSITIVE"

          }]
      }

  }));

  export const getNewPin = () => new Promise((resolve, reject) => resolve({

    "dictionary": {
        "PinResetTitle": "Neue PIN vergeben",
        "PinResetSubtitle": "Sie haben einen Freigabecode von unserem Supportmitarbeiter fur diese Transaktion bekommen. Bitte geben Sie diesenhier an.",
        "PinResetPlaceholder1": "Freigabecode",
        "PinResetPlaceholder2": "Neue PIN",
        "PinResetPlaceholder3": "Neue PIN wiederholen",
        "PinResetButton": "PIN bestatigen",

        "PinChangedTitle": "PIN erfolgreich geandert!",
        "PinChangedCheckedIcon": "https://app.contentful.com/spaces/hkdgr7e0ksoy/assets/6vzRnTEReRSHw46a5QqAbb",
        "PinChangedSubtitle": "Ihre PIN wurde erfolgreich geandert!",
        "PinChangedHomepageButton": "Zur Homepage",

        "TokenExpiredTitle": "Link nicht mehr gultig",
        "cancelIcon": "https://app.contentful.com/spaces/hkdgr7e0ksoy/assets/DMeCsSXnrEqSLSbCDaKRJ",
        "TokenExpiredSubtitle": "Ihr Link ist abgelaufen. Bitte kontaktieren Sie erneut unseren Support unter",
        "phoneNumber1": "+352 27 86 28 68",
        "phoneNumber2": "+49 241 9550 9291",
        "text": "oder",
        "email": "service@investify.com",

        "RetriesExceededTitle": "Friegabecode verfallen",
        "RetriesExceededSubtitle": "Sie haben Ihren Freigabecode zu haufig falsch eingegeben. Bitte kontaktieren Sie erneut unseren Support unter",
    },
  }));


