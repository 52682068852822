import config from "@config";
import { asDataQuery, asEmbeddedQuery, asLinksQuery, withSessionScope } from "@hooks/higher-order-hooks";
import { apiRequest } from "@services/api";
import { useQuery } from "react-query";
import { prospectAuth } from "@services/auth.service";
import { getStoredPartnerBinding } from "@services/binding.service";

import get from "lodash/get";

const { API_ROOT } = config;

const withAuthenticationProcess = (queryFn) => {
    return async (...args) => {
        try {
            return await queryFn(...args);
        } catch (error) {
            const responseStatus = get(error, "response.statusCode");

            if (responseStatus === 401) {
                await prospectAuth(getStoredPartnerBinding());
                return queryFn(...args);
            }

            throw error;
        }
    };
};

const useHomeQuery = (options) => {
    const queryKey = ["home"];

    const queryFn = () => apiRequest
        .get(API_ROOT)
        .set("Accept", "application/hal+json")
        .send();

    const queryFnWithAuth = withAuthenticationProcess(queryFn);

    const useScopedQuery = withSessionScope(useQuery);

    return useScopedQuery({ queryKey, queryFn: queryFnWithAuth, ...options, useErrorBoundary: true });
};

export const useHomeDataQuery = asDataQuery(useHomeQuery);
export const useHomeEmbeddedDataQuery = asEmbeddedQuery(useHomeQuery);
export const useHomeLinksQuery = asLinksQuery(useHomeQuery);
