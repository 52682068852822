import merge from "lodash/merge";

import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import createPalette from "@material-ui/core/styles/createPalette";
import createTypography from "@material-ui/core/styles/createTypography";
import grey from "@material-ui/core/colors/grey";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import baseTheme from "@base/theme";
import shadows, { customShadows } from "@theme/shadows";
import shape from "@theme/shape";

import Color from "color";

const cleVRBlue = "#0066b3";
const cleVROrange = "#ff6600";
const cleVRWhite = "#ffffff";
const cleVRDarkBlue = "#002d67";

const cardBackgroundColor = "#f3f3f3";

const primary = cleVRDarkBlue;
const secondary = cleVROrange;

const error = "rgb(190,22,34)";
const warning = "rgb(234,157,73)";

const green = cleVRDarkBlue;
const red = cleVRDarkBlue;
const black = "#000000";

const uiTheme = createTheme({ shape, shadows: shadows.light });

const fontFamily = {
    heading: "\"Font Frutiger VR\", sans-serif",
    body: "\"Font Frutiger VR\", sans-serif"
};

export const palette = createPalette({
    primary: {
        main: primary,
    },
    secondary: {
        main: secondary
    },
    background: {
        default: cleVRWhite
    },
    error: {
        main: error,
        light: warning
    },
    grey: {
        ...grey,
        50: "#f6f6f6",
        100: "#eeeeee",
        200: "#e0e0e0",
        300: "#dcdcdc",
        400: "#bdbdbd",
        500: "#8a8a8a",
        600: "#757575",
        700: "#565555",
        800: "#424242",
        900: "#333333"
    },
    text: {
        main: black,
        primary: black,
        secondary: cleVRDarkBlue
    },
    black: {
        main: black,
    },
    positive: {
        main: green
    },
    negative: {
        main: red
    },
    neutral: {
        main: black,
    },
    link: {
        main: secondary,
    },
    appBar: {
        main: cleVRWhite
    },
    success: {
        main: primary,
    },
});

export const typography = createTypography(palette, {
    fontFamily: fontFamily.body,
    headingFontFamily: fontFamily.heading,

    button: {
        textTransform: "none",
        fontSize: "1rem",
        fontWeight: 400,
        letterSpacing: "normal",
        lineHeight: 1.25
    },
    h1: {
        fontFamily: fontFamily.heading,
        fontSize: "1.375rem",
        fontWeight: 300,
    },
    h2: {
        fontFamily: fontFamily.heading,
        fontSize: "1.27rem",
        fontWeight: 300,
    },
    h3: {
        fontFamily: fontFamily.heading,
        fontSize: "1.173rem",
        fontWeight: 300,
    },
    h4: {
        fontFamily: fontFamily.heading,
        fontSize: "1.083rem"
    },
    h5: {
        fontFamily: fontFamily.heading,
        fontSize: "1rem"
    },
    h6: {
        fontFamily: fontFamily.heading,
        fontSize: "0.923rem"
    },
    subtitle1: {
        fontFamily: fontFamily.heading,
        fontSize: "0.853rem"
    },
    body1: {
        fontSize: uiTheme.spacing(2),
        fontFamily: fontFamily.body,
        letterSpacing: 0,
        "& a": {
            color: palette.secondary.main,
        },
    },
    body2: {
        fontFamily: fontFamily.body,
        fontSize: uiTheme.spacing(1.875),
        letterSpacing: 0
    },
    caption: {
        fontFamily: fontFamily.body,
        fontSize: "0.75rem",
        letterSpacing: 0,
        display: "block",
        "& a": {
            color: palette.secondary.main,
        },
    },
    progressBar: {
        main: cleVRWhite
    }
});

const cf = {
    ...uiTheme,
    palette,
    typography,
    overrides: {
        MeasurementsTable: {
            positive: {
                backgroundColor: uiTheme.palette.success.main
            },
            negative: {
                backgroundColor: uiTheme.palette.error.main
            },
        },
        Header: {
            logo: {
                width: 200,
                height: 38
            }
        },
        LoginComponent: {
            root: {
                "& a": {
                    color: cleVRBlue
                },
                "& .MuiTypography-button": {
                    fontWeight: 400
                },
            },
            textImage: {
                backgroundColor: "none",
            },
            imageDescriptionText: {
                display: "none",
            },
            link: {
                fontWeight: 400,
                color: palette.link.main,
                backgroundColor: "transparent",
                textDecoration: "underline",
                textDecorationColor: secondary,
                "&:hover": {
                    color: secondary,
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                    textDecorationColor: secondary,
                },
            },
            textImagePosition: {
                objectPosition: "center 50%",
                [uiTheme.breakpoints.down("md")]: {
                    objectPosition: "0% 0%",
                }
            },
            linksContainer: {
                "& > $link": {
                    color: uiTheme.palette.common.white,
                    "&:hover": {
                        color: secondary,
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                        textDecorationColor: secondary,
                    },
                }
            },
        },
        RegistrationComponent: {
            textImage: {
                [uiTheme.breakpoints.up("sm")]: {
                    height: "450px!important",
                }
            },
            textImagePosition: {
                objectPosition: "100% 10%",
            },
            content: {
                alignSelf: "flex-end",
                marginBottom: 0,
                maxWidth: 400,
                height: 160,
                padding: uiTheme.spacing(1),
                paddingBottom: 0,
                backgroundColor: "rgb(255, 255, 255)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                [uiTheme.breakpoints.down("xs")]: {
                    width: "100%",
                    height: "auto",
                }
            },
            imageDescriptionText: {
                color: palette.primary.main,
            },
        },
        CheckEmailComponent: {
            textImage: {
                display: "flex",
                color: palette.primary.main,
            },
            content: {
                backgroundColor: "rgb(255, 255, 255)",
                padding: uiTheme.spacing(2),
                borderRadius: uiTheme.shape.borderRadius,
            },
            subtitle: {
                marginBottom: uiTheme.spacing(3),
            },
            body: {
                marginTop: 0,
                marginBottom: 30,
            },
            registered: {
                color: palette.primary.main,
            },
            logo: {
                marginTop: uiTheme.spacing(2),
            }
        },
        PasswordRecovery: {
            title: {
                fontWeight: 100,
            }
        },
        PromotionCodes: {
            modalTitle: {
                color: palette.text.primary
            }
        },
        CardHeader: {
            title: {
                color: black,
                fontWeight: 700,
            }
        },
        CardContainer: {
            root: {
                backgroundColor: cardBackgroundColor,
                boxShadow: () => `0px 0px 0px 1px ${Color(grey[300]).fade(0.6).toString()}, 0px 8px 24px -12px ${Color(grey[500]).fade(0.65).toString()}`,
            }
        },
        CustomerPortfolio: {
            selectedItem: {
                "& > td:not(.action)": {
                    backgroundColor: cleVRWhite,
                },
                [uiTheme.breakpoints.down("xs")]: {
                    backgroundColor: cleVRWhite,
                    "& > td": {
                        border: "none",
                    },
                }
            },
            rowOnHover: {
                cursor: "pointer",
                [uiTheme.breakpoints.down("xs")]: {
                    "&:hover": {
                        backgroundColor: cleVRWhite,
                    },
                },
                "&:hover": {
                    "& > td:not(.action)": {
                        backgroundColor: cleVRWhite,
                    },
                },
            },
        },
        PortfolioAnalysis: {
            legend: {
                "& .MuiButton-label": {
                    color: cleVRBlue,
                    textDecoration: "underline",
                    textDecorationColor: cleVRBlue
                }
            },
            positive: {
                color: cleVROrange
            },
            paddingOnMobile: {
                "& a": {
                    color: cleVRBlue
                }
            }
        },
        ButtonComponent: {
            root: {
                borderRadius: 4,
                fontWeight: 700
            }
        },
        Tab: {
            root: {
                borderRadius: 4
            }
        },
        MuiTab: {
            root: {
                borderRadius: 4
            }
        },
        CoreDetails: {
            legend: {
                "& .MuiButton-label": {
                    color: cleVRBlue,
                    textDecoration: "underline",
                    textDecorationColor: cleVRBlue
                }
            },
            positive: {
                color: cleVROrange
            },
            paddingOnMobile: {
                "& a": {
                    color: cleVRBlue
                }
            }
        },
        TopicHoldingDetails: {
            legend: {
                "& .MuiButton-label": {
                    color: cleVRBlue,
                    textDecoration: "underline",
                    textDecorationColor: cleVRBlue
                }
            },
            positive: {
                color: cleVROrange
            },
            topicBalance: {
                "& h3": {
                    color: black
                }
            },
            paddingOnMobile: {
                "& a": {
                    color: cleVRBlue
                }
            }
        },
        ViewPersonalData: {
            subtitle: {
                fontWeight: 100,
            },
        },
        PersonalDataMultiStepComponent: {
            nextButton: {
                "& button": {
                    border: `1px solid ${cleVRDarkBlue}`,
                }
            }
        },
        ControlDataComponent: {
            addTaxLandButton: {
                display: "none"
            }
        },
        NewEmail: {
            title: {
                fontWeight: 100,
            },
        },
        ResendEmail: {
            title: {
                fontWeight: 100,
            },
        },
        EmailValidation: {
            header: {
                fontWeight: 100,
            },
        },
        Footer: {
            brandAndYear: {
                visibility: "hidden",
            },
        },
        NumberFormat: {
            subscript: {
                fontSize: "inherit",
                paddingRight: 5,
            },
            suffix: {
                fontSize: "inherit",
            },
        },
        PrivateTabScrollButton: {
            root: {
                width: uiTheme.spacing(2)
            }
        },
        MuiTabScrollButton: {
            root: {
                width: uiTheme.spacing(2)
            }
        },
        MuiButton: {
            root: {
                fontSize: "1rem",
                fontWeight: "400",
                borderRadius: 4,
            },
            text: {
                "&.underline": {
                    textDecoration: "underline",
                    verticalAlign: "baseline",
                    margin: 0,
                    padding: 0,
                    minHeight: 0,
                    minWidth: 0,
                    "&:hover": {
                        backgroundColor: "transparent"
                    }
                }
            },
            textPrimary: {
                color: palette.link.main,
                fontSize: "0.75rem",
                textTransform: "none",
                fontWeight: 400,
                "&:hover": {
                    backgroundColor: "transparent"
                },
            },
            containedPrimary: {
                color: palette.common.white,
                backgroundColor: cleVROrange,
                "&:hover": {
                    color: palette.common.white,
                    backgroundColor: cleVRBlue
                },
            },
            outlined: {
                color: green,
                borderColor: green,
                padding: "6px 16px",
            },
            sizeLarge: {
                fontSize: "1.25rem"
            },
            sizeSmall: {
                fontSize: "0.9rem"
            }
        },
        MuiAppBar: {
            colorDefault: {
                backgroundColor: palette.appBar.main,
                color: palette.text.primary,
                boxShadow: "0px 3px rgba(0, 0, 0, 0.05)",
            }
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: "auto",
                alignItems: "flex-start"
            }
        },
        Input: {
            root: {
                fontSize: "1rem",
            },
            input: {
                fontSize: "1rem",
            }
        },

        MuiSelect: {
            select: {
                fontSize: "1rem",
                paddingLeft: 15,
                paddingRight: 25,
                "& em": {
                    opacity: 0.5
                }
            },
            icon: {
                color: palette.primary.main
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: grey[200]
            },
            barColorPrimary: {
                backgroundColor: green
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: palette.primary.main
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                fontFamily: fontFamily.body,
                color: palette.common.white,
            },
            toolbarBtnSelected: {
                fontFamily: fontFamily.body,
                color: palette.common.white,
            }
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: palette.primary.main,
                color: palette.common.white,
                "&:hover": {
                    backgroundColor: palette.primary.main
                }
            }
        },
        MuiPickersYear: {
            root: {
                fontFamily: fontFamily.body,
                color: palette.text.primary,
                "&:hover, &:focus, &:active": {
                    color: palette.text.primary,
                    backgroundColor: Color(palette.primary.main).fade(0.92).toString(),
                }
            },
            yearSelected: {
                backgroundColor: palette.primary.main,
                color: palette.common.white,
                "&:hover, &:focus, &:active": {
                    backgroundColor: palette.primary.main,
                    color: palette.common.white,
                },
            }
        },
        MuiPickersModal: {
            dialogRoot: {
                "& .MuiDialogActions-root button": {
                    backgroundColor: palette.primary.main
                }
            }
        },
        InfoTextComponent: {
            modal: {
                color: palette.text.primary,
            },
            infoIcon: {
                "&&": {
                    color: palette.text.primary,
                    fill: palette.text.primary,
                }
            }
        },
        InfoBox: {
            infoBoxContent: {
                color: palette.text.primary
            },
            infoBoxToggleButton: {
                color: palette.text.primary
            }
        },
        DownloadListItem: {
            modal: {
                color: palette.text.primary,
            },
            infoIcon: {
                "&&": {
                    color: palette.text.primary,
                    fill: palette.text.primary,
                }
            }
        },
        ExplanationComponent: {
            root: {
                color: palette.text.primary,
                background: "none"
            }
        },
        ModalListItem: {
            modal: {
                color: palette.text.primary,
            },
            infoIcon: {
                "&&": {
                    color: palette.text.primary,
                    fill: palette.text.primary,
                }
            }
        },
        MuiTypography: {
            h1: {
                color: black
            }
        },
        MuiInputAdornment: {
            root: {
                position: "absolute",
                right: 0
            }
        },
        MuiSlider: {
            trackBefore: {
                backgroundColor: palette.primary.main,
                borderColor: palette.primary.main,
                borderRadius: 10,
                borderWidth: 1,
                borderStyle: "solid",
            },
            trackAfter: {
                backgroundColor: palette.background.default,
                borderColor: palette.grey[500],
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 10
            },
            thumb: {
                height: 25,
                width: 25,
                backgroundColor: palette.grey[300],
                borderColor: palette.grey[500],
                borderWidth: 1,
                borderStyle: "solid",
                boxShadow: "0px 2px rgba(0, 0, 0, 0.1)",

                "&$activated": {
                    width: 28,
                    height: 28
                }
            },
            track: {
                height: "1rem"
            }
        },
        MuiTableCell: {
            root: {
                width: "25%",
                textAlign: "left",
                padding: 0
            }
        },
        MuiTabs: {
            root: {
                width: "100%",
            },
            indicator: {
                backgroundColor: palette.primary.main
            }
        },
        MuiMobileStepper: {
            dots: {
                margin: [0, 7]
            },
            dot: {
                [uiTheme.breakpoints.up("sm")]: {
                    margin: [0, 7]
                }
            }
        },
        MuiMenu: {
            paper: {
                boxShadow: uiTheme.shadows[2],
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                backgroundColor: palette.grey[50],
                marginBottom: 5
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                display: "auto",
            }
        },
        MuiExpansionPanel: {
            root: {
                "&:before": {
                    backgroundColor: "none"
                }
            },
            rounded: {
                width: "100%",
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: "none"
            }
        },
        MuiRadio: {
            colorSecondary: {
                "&$checked": {
                    color: palette.primary.main,
                },
            },
        },
        MuiIconButton: {
            root: {
                color: palette.text.primary,
            }
        },
        ProductPresentation: {
            infoTitle: {
                "& h4": {
                    fontWeight: 700,
                },
            },
        }
    },
    props: {
        MuiButton: {
            variant: "contained",
            color: "primary"
        },
        MuiAppBar: {
            elevation: 0,
            color: "default"
        },
        MuiInput: {
            fullWidth: true
        },
        MuiSelect: {
            IconComponent: ExpandMoreIcon
        },
        MuiCheckbox: {
            color: "primary"
        },
        icons: {
            filter: "none"
        }
    },
    shadows: shadows.light,
    customShadows: customShadows.light
};

export const theme = createTheme(
    merge(baseTheme, cf)
);

export default theme;
