import { initUserSensitiveTracking } from "@services/tracking.service";
import { getPath } from "@services/api.service";
import { prospectAuth } from "@services/auth.service";
import { legacyApiRequest } from "@services/api";
import env from "@config";
import { batchActions } from "redux-batched-actions";
import { actions as authActions } from "@ducks/auth";
import { actions as supportDataActions } from "@ducks/support-contact-data";

import store from "@ducks";

export const types = {
    GLOBAL_RESOURCE_LOAD_SUCCESS: "APPLICATION_LIFECYCLE/GLOBAL_RESOURCE_LOAD_SUCCESS",
    HOME_RESOURCE_LOAD_SUCCESS: "APPLICATION_LIFECYCLE/HOME_RESOURCE_LOAD_SUCCESS",
    RESOURCE_LOAD_FAIL: "APPLICATION_LIFECYCLE/RESOURCE_LOAD_SUCCESS",
};

export const initialState = {
    globalResource: null,
    homeResource: null,
    error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
      case types.GLOBAL_RESOURCE_LOAD_SUCCESS:
        return { ...state, globalResource: action.globalResource };
      case types.HOME_RESOURCE_LOAD_SUCCESS:
        return { ...state, homeResource: action.homeResource };
      case types.RESOURCE_LOAD_FAIL:
        return { ...state, error: action.error };
      default:
        return state;
  }
};

export const privateActions = {
  loadGlobalResourceSuccess: (globalResource) => ({ type: types.GLOBAL_RESOURCE_LOAD_SUCCESS, globalResource }),
  loadHomeResourceSuccess: (homeResource) => ({ type: types.HOME_RESOURCE_LOAD_SUCCESS, homeResource }),
  loadResourceFail: (err) => ({ type: types.RESOURCE_LOAD_FAIL, err }),
};

export const actions = {

  initialize: (storedPartnerBinding, resetAuth) => (dispatch) => {
    const getHome = () => legacyApiRequest.get(env.API_ROOT).set("Accept", "application/hal+json");

    return getHome()
      .catch((err) => {
        if (err && err.response && err.response.statusCode === 401) {
          return prospectAuth(storedPartnerBinding)
            .then(getHome);
        }

        return Promise.reject(err);
      })
      .then((re) => re.body)
      .then((repr) => {
        dispatch(resetAuth
? batchActions([
          privateActions.loadHomeResourceSuccess(repr),
            authActions.authenticationEstablished("UNKNOWN")
          ])
: privateActions.loadHomeResourceSuccess(repr));
      });
  },
  loadGlobalResource: () => (dispatch) => getPath([{ path: "global-data" }])
      .then((re) => {
        initUserSensitiveTracking(re.dictionary.uval);
        dispatch(privateActions.loadGlobalResourceSuccess(re));
        dispatch(supportDataActions.loadSupportContactData(re));
      })
      .catch((err) => {
        dispatch(privateActions.loadResourceFail(err));
        return Promise.reject(err);
      })
};

export const getGlobalResource = (state) => state.applicationLifecycle.globalResource;
export const getHomeResource = (state) => state.applicationLifecycle.homeResource;

// This is only here for easy backwards compatibility for api.service to just delegate it's own getHome to
export const getHome = () => {
    const home = getHomeResource(store.getState());
    if (!home) {
        return Promise.reject(new Error("application home is not yet available. The request being tried is outside the application lifecycle."));
    }

    return Promise.resolve({ body: home });
};
