import { useQuery } from "react-query";
import { apiRequest } from "@services/api";
import { useHomeLinksQuery } from "@hooks/home";
import { asDictionaryQuery, withEnabledCondition, withLoadingContext, withPageScope } from "@hooks/higher-order-hooks";

export * from "@common/bounded-context/portfolio-analysis/hooks/transactions";

const useTransactionsResourceQuery = (options) => {
    const { data: url } = useHomeLinksQuery("transactions");

    const usePageScopedQuery = withPageScope(useQuery);
    const useConditionalQuery = withEnabledCondition(usePageScopedQuery, (enabled) => enabled && !!url);
    const useConditionalQueryWLC = withLoadingContext(useConditionalQuery);

    const queryKey = ["bc_portfolio_analysis", "transactions", url];

    const queryFn = () => apiRequest
        .set("Accept", "application/json")
        .get(url)
        .send();

    return useConditionalQueryWLC({ queryKey, queryFn, ...options });
};

export const useCustomerTransactionsDictionaryQuery = asDictionaryQuery(useTransactionsResourceQuery);
