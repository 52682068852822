import { actions as progress } from '@ducks/progress';
import { getPath, resendVerificationEmail } from '@services/api.service';
import { actions as header } from '@ducks/header';

import get from 'lodash/get';

export const types = {
    RESEND_EMAIL_LOAD: 'RESEND_EMAIL/LOAD',
    RESEND_EMAIL_LOAD_SUCCESS: 'RESEND_EMAIL/LOAD_SUCCESS',
    RESEND_EMAIL_LOAD_FAIL: 'RESEND_EMAIL/LOAD_FAIL',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL/SUCCESS',
    RESEND_EMAIL_FAIL: 'RESEND_EMAIL/FAIL',
    CLEAR_ERRORS: 'RESEND_EMAIL/CLEAR_ERRORS'
}

export const initialState = {
    error: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.RESEND_EMAIL_LOAD_SUCCESS:
            return { ...state, resendEmailData: action.resendEmailData };
        case types.RESEND_EMAIL_LOAD_FAIL:
            return { ...state, error: action.error };
        case types.RESEND_EMAIL_SUCCESS:
            return state;
        case types.RESEND_EMAIL_FAIL:
            return { ...state, resendEmailError: action.resendEmailError };
        case types.CLEAR_ERRORS:
            return {...state, resendEmailError: null };
        default:
            return state;
        }
}

export const actions = {
    resendEmail: () => (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(actions.clearErrors());
        resendVerificationEmail()
            .then(re => {
                dispatch(actions.resendEmailSuccess());
                resolve();
            })
            .catch(err => {
                dispatch(actions.resendEmailFail(getState().resendEmail.resendEmailData.dictionary.UNKNOWN_ERROR));
                reject();
            })
    }),
    loadResendEmail: () => (dispatch) => {
        dispatch(actions.clearErrors());
        dispatch(progress.setLoading(true));
        getPath([{path: "check-email"}, {path: "resendEmail"}])
            .then(resendEmailScreen => {
                dispatch(actions.loadResendEmailSuccess(resendEmailScreen));
                dispatch(header.updateTitle(get(resendEmailScreen, 'dictionary.pageTitle', '')));
                dispatch(progress.setLoading(false));
            })
            .catch(err => {
                dispatch(actions.loadResendEmailFail(err));
                dispatch(progress.setLoading(false));
            });
    },

    loadResendEmailSuccess: (resendEmailData) => ({ type: types.RESEND_EMAIL_LOAD_SUCCESS, resendEmailData }),
    loadResendEmailFail: (error) => ({ type: types.RESEND_EMAIL_LOAD_FAIL, error }),
    resendEmailSuccess: () => ({ type: types.RESEND_EMAIL_SUCCESS }),
    resendEmailFail: (resendEmailError) => ({ type: types.RESEND_EMAIL_FAIL, resendEmailError }),
    clearErrors: () => ({type: types.CLEAR_ERRORS})
}

export const getResendEmailData = (state) => state.resendEmail.resendEmailData;
export const getResendEmailError = (state) => state.resendEmail.resendEmailError;
