import { getLandingPage } from '@services/gateway.service';
import { actions as progress } from '@ducks/progress';
import { actions as header } from '@ducks/header';

export const types = {
    LANDING_PAGE_LOAD: 'LANDING_PAGE/LOAD',
    LANDING_PAGE_LOAD_SUCCESS: 'LANDING_PAGE/LOAD_SUCCESS',
    LANDING_PAGE_LOAD_FAIL: 'LANDING_PAGE/LOAD_FAIL',
    LANDING_PAGE_HIGHLIGHT_ITEM: 'LANDING_PAGE/HIGHLIGHT_ITEM'
}

export const initialState = {
    landingPageData: null,
    error: null,
    highlightedItem: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.LANDING_PAGE_LOAD_SUCCESS:
            return { ...state, landingPageData: action.landingPageData }
        case types.LANDING_PAGE_LOAD_FAIL:
            return { ...state, error: action.error }
        case types.LANDING_PAGE_HIGHLIGHT_ITEM:
            return { ...state, highlightedItem: action.highlightedItem }
        default:
            return state;
        }
}

export const actions = {
    loadLandingPage: () => (dispatch) => {
        dispatch(progress.setLoading(true));
            getLandingPage().then(re => {
                dispatch(header.updateTitle(''));
                dispatch(actions.loadLandingPageSuccess(re._embedded));
                dispatch(progress.setLoading(false));
            }).catch(err => {
                dispatch(actions.loadLandingPageFail(err));
                dispatch(progress.setLoading(false));
            });
    },
    loadLandingPageSuccess: (landingPageData) => ({ type: types.LANDING_PAGE_LOAD_SUCCESS, landingPageData }),
    loadLandingPageFail: (error) => ({ type: types.LANDING_PAGE_LOAD_FAIL, error }),
    highlightItem: (highlightedItem) => ({ type: types.LANDING_PAGE_HIGHLIGHT_ITEM, highlightedItem }),
}

export const getLandingPageData = (state) => state.landingPage.landingPageData;
export const getHighlightedItem = (state) => state.landingPage.highlightedItem;
