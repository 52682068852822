import { type ComponentProps, type ElementType, type ComponentType, createContext, useContext, type FC, type PropsWithChildren } from "react";
import type { CreateTeleporterOptions } from "react-teleporter";

import { createTeleporter } from "react-teleporter";

export interface CreateFacadeOptions<T> {
    readonly name: string
    readonly defaultImplementation?: T
}

function createSlot<Component extends ElementType = "div">(as: Component, options?: CreateTeleporterOptions): [ComponentType<ComponentProps<Component>>, typeof Teleporter.Source] {
    const Teleporter = createTeleporter(options);

    const Slot: ComponentType<ComponentProps<Component>> = (props: ComponentProps<Component>) => <Teleporter.Target as={as} {...props}/>;

    return [Slot, Teleporter.Source];
}

function createFacade<T>({ name, defaultImplementation }: CreateFacadeOptions<T>) {
    const FacadeContext = createContext(defaultImplementation);

    const useFacade = () => {
        const context = useContext(FacadeContext);

        if (context === undefined) {
            throw new Error(`useFacade must be used under a FacadeProvider: ${name}`);
        }

        return context;
    };

    const FacadeProvider: FC<PropsWithChildren<{ slots: T }>> = ({ children, slots }) => <FacadeContext.Provider value={slots}>
        {children}
    </FacadeContext.Provider>;

    // eslint-disable-next-line react/display-name
    const withFacadeProvider = <P extends object = object>(slots: T) => (Component: ComponentType<P>) => (props: P) => <FacadeContext.Provider value={slots}>
        <Component {...props} />
    </FacadeContext.Provider>;

    return { useFacade, FacadeProvider, withFacadeProvider };
}

export { createSlot, createFacade };
