/* eslint-disable react/display-name */
import { DictionaryProvider } from "@providers/DictionaryProvider";
import { useBoundedContextDictionaryQuery } from "@hooks/dictionary";
import GlobalLoader from "@modules/loading/components/GlobalLoader";

const withDictionary = (dictionaryId, variant = "loader") => (Component) => (props) => {
    const { isLoading, isIdle, data: dictionary } = useBoundedContextDictionaryQuery(dictionaryId);

    if (isLoading || isIdle) {
        return <GlobalLoader />;
    }

    return <DictionaryProvider dictionary={dictionary}>
        <Component {...props}/>
    </DictionaryProvider>;
};

export default withDictionary;
