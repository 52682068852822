import "reflect-metadata";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/proposals/string-replace-all";
import React from "react";
import ReactDOM from "react-dom";

import Application from "@components/application";
import "./index.css";

// Disabled service worker to avoid problems with caching, etc.
// import registerServiceWorker from "./registerServiceWorker";

const jsx = (
  <Application />
);

// ReactDOM.hydrate(jsx, document.getElementById("root"));
ReactDOM.render(jsx, document.getElementById("root"));

// Disabled service worker to avoid problems with caching, etc.
// registerServiceWorker();
