import type { ComponentType } from "react";
import type { MenuRegistry } from "@core/application/menu/services";
import type { Route } from "@core/application/router/contributables";

interface MenuItemBase {
    readonly id: string
    readonly category?: MenuCategory
    readonly label: string
    readonly subLabel?: string
    readonly order?: number
    readonly menus?: ReadonlyArray<symbol>
    readonly icon?: ComponentType
    readonly component?: ComponentType<MenuItem>
    readonly useIsVisible?: () => boolean
    readonly policyScope?: string | string[]
    readonly usePolicyScope?: () => string | string[]
}

interface MenuItemWithRoute extends MenuItemBase {
    readonly route: string | Route
    readonly href?: never
}
interface MenuItemWithHref extends MenuItemBase {
    readonly href: string
    readonly route?: never
}

export type MenuItem = MenuItemWithRoute | MenuItemWithHref;
export const MenuContribution = Symbol("MenuContribution");

export interface MenuContribution {
    registerContributions(registry: MenuRegistry): void
}

export interface MenuCategory {
    id: string
    label: string
    order: number
}
