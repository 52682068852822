import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, compose } from "redux";
import { batchDispatchMiddleware } from "redux-batched-actions";

import progress from "@ducks/progress";
import topic from "@ducks/topic";
import auth, { types as authActions } from "@ducks/auth";
import personalData from "@ducks/personal-data-multi-step";
import investmentAmount from "@ducks/investment-amount";
import portfolioAnalysis from "@ducks/portfolio-analysis";
import topicHoldingDetails from "@ducks/topic-holding-details";
import coreDetails from "@ducks/core-details";
import registration from "@ducks/registration";
import passwordRecovery from "@ducks/password-recovery";
import landingPage from "@ducks/landing-page";
import stepMeta from "@ducks/step-meta";
import checkEmail from "@ducks/check-email";
import newEmail from "@ducks/registration-change-email";
import resendEmail from "@ducks/resend-email";
import onboarding from "@ducks/onboarding";
import ringChart from "@ducks/ring-chart";
import header from "@ducks/header";
import session from "@ducks/session";
import errorBoundary from "@ducks/error-boundary";
import applicationLifecycle from "@ducks/application-lifecycle";
import deviceLegitimation from "@ducks/device-legitimation";
import user from "@ducks/user";
import imprint from "@ducks/imprint";
import supportContactData from "@ducks/support-contact-data";

const composeEnhancers = (process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducer = combineReducers({
  progress,
  topic,
  auth,
  investmentAmount,
  portfolioAnalysis,
  topicHoldingDetails,
  coreDetails,
  ringChart,
  registration,
  passwordRecovery,
  personalData,
  landingPage,
  stepMeta,
  checkEmail,
  onboarding,
  header,
  session,
  newEmail,
  resendEmail,
  user,
  imprint,
  errorBoundary,
  applicationLifecycle,
  deviceLegitimation,
  supportContactData,
});

const configureAppStore = (preloadedState) => configureStore({
        reducer: (state, action) => {
            // Make sure all state is reset to initial state after logout.
            if (
                action.type === authActions.AUTH_AUTHENTICATION_CHANGE &&
                action.authentication.state === "LOST"
            ) {
                state = preloadedState;
            }

            return reducer(state, action);
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            immutableCheck: false,
        }).concat(batchDispatchMiddleware),
        preloadedState,
        composeEnhancers
    });

export default configureAppStore(window.REDUX_DATA);
