export * from "ahooks";

import { useCallback, useEffect, useId, useRef, useState } from "react";
import { dataTestId } from "@utils";
import { useLocation, matchPath } from "react-router-dom";
import { useParams } from "@hooks/router";

const useEffectInEvent = (event, useCapture, set = () => { /* NOOP */ }) => {
    useEffect(() => {
        set();
        window.addEventListener(event, set, useCapture);
        return () => window.removeEventListener(event, set, useCapture);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useRect = () => {
    const ref = useRef();
    const [rect, setRect] = useState();

    function set() {
        requestAnimationFrame(() => {
            if (!ref && !ref.current) return;
            return setRect(ref.current?.getBoundingClientRect());
        });
    }

    useEffectInEvent("resize", true, set);
    // useEffectInEvent("scroll", true, set);

    return [rect, ref];
};

function removeParamsFromPathname(pathname, params) {
    const path = pathname.split("/");

    // eslint-disable-next-line no-restricted-syntax
    for (const value of Object.values(params)) {
        const indexToRemove = path.indexOf(value);
        if (indexToRemove !== -1) path.splice(indexToRemove, 1);
    }

    return path.join("/");
}

// TODO Switch visibility of [data-testid] with .env variable
export const useDataTestId = (id) => {
    const fallbackId = useId();
    const params = useParams();
    const location = window.location;
    const pathName = removeParamsFromPathname(location.pathname, params);
    return dataTestId(id || fallbackId, { ...location, pathname: pathName });
};

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, doSetValue] = useState(JSON.parse(localStorage.getItem(key)) || defaultValue);

    const setValue = useCallback((value) => {
        localStorage.setItem(key, JSON.stringify(value));
        doSetValue(value);
    }, [key]);

    return [value, setValue];
};
