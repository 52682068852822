import React, { useContext } from "react"

const PropertiesContext = React.createContext({})

function PropertiesProvider({ children, value = {} }) {
  return (
    <PropertiesContext.Provider value={value}>
      {children}
    </PropertiesContext.Provider>
  )
}

const withPropertiesProvider = (Component) => (props) => (
      <PropertiesProvider>
        <Component {...props} />
      </PropertiesProvider>
  )

function useProperties() {
  const context = useContext(PropertiesContext)

  if (context === undefined) {
    throw new Error(
      "useProperties must be used within PropertiesContext",
    )
  }

  return context
}

export { PropertiesContext, PropertiesProvider, withPropertiesProvider, useProperties }
