import { changePassword, forgotPassword, getPath, } from "@services/api.service";
import { actions as progress } from "@ducks/progress";

export const types = {
    PASSWORD_RECOVERY_LOAD_SUCCESS: "PASSWORD_RECOVERY/LOAD_SUCCESS",
    PASSWORD_RECOVERY_LOAD_FAIL: "PASSWORD_RECOVERY/LOAD_FAIL",
    PASSWORD_RECOVERY_SUCCESS: "PASSWORD_RECOVERY/SUCCESS",
    EMAIL_ERROR: "PASSWORD_RECOVERY/EMAIL_ERROR",
    GENERIC_ERROR: "PASSWORD_RECOVERY/GENERIC_ERROR",
    SEND_NEW_PASSWORD: "PASSWORD_RECOVERY/SEND_NEW_PASSWORD",
    PASSWORD_CHANGE_ERROR: "PASSWORD_RECOVERY/PASSWORD_CHANGE_ERROR"
};

const errorMap = {
    BAD_EMAIL_SYNTAX: [types.EMAIL_ERROR]
};

export const initialState = {
    loadedRecover: null,
    user: null,
    error: null,
    sendNewPassword: undefined,
    passwordChangeError: undefined
};


export default (state = initialState, action) => {
    switch (action.type) {
        case types.PASSWORD_RECOVERY_LOAD_SUCCESS:
            return { ...state, loadedRecover: action.loadedRecover, user: null };
        case types.PASSWORD_RECOVERY_LOAD_FAIL:
            return { ...state, loadedRecover: null, user: null };
        case types.PASSWORD_RECOVERY_SUCCESS:
            return { ...state, loadedRecover: action.loadedRecover, user: action.user };
        case types.EMAIL_ERROR:
            return { ...state, emailError: action.emailError };
        case types.SEND_NEW_PASSWORD:
            return { ...state, sendNewPassword: true };
        case types.PASSWORD_CHANGE_ERROR:
            return { ...state, passwordChangeError: action.passwordChangeError };
        default:
            return state;
    }
};

export const actions = {
    loadRecover: () => (dispatch) => new Promise((resolve, reject) => {
        dispatch(progress.setLoading(true));
        getPath([{ path: "login-screen" }, { path: "password-reset" }])
            .then(re => {
                dispatch(actions.loadRecoverSuccess(re));
                dispatch(progress.setLoading(false));
                resolve(re);
            })
            .catch(err => {
                dispatch(actions.loadRecoverFail(err));
                dispatch(progress.setLoading(false));
                reject(err);
            });
    }),
    forgotPassword: (user) => (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(progress.setLoading(true));
        forgotPassword(user)
            .then(() => {
                resolve();
            })
            .catch(err => {
                if (err.response.body && errorMap[err.response.body.errorType]) {
                    errorMap[err.response.body.errorType].forEach(errorType => dispatch(actions.triggerError(errorType, err.response.body.message)));
                } else {
                    dispatch(actions.triggerError(types.EMAIL_ERROR, getState().passwordRecovery.loadedRecover.dictionary.genericError));
                }
                reject(err);
            })
            .finally(() => dispatch(progress.setLoading(false)));
    }),

    changePassword: (password, token) => (dispatch) => {
        dispatch(progress.setLoading(true));
        changePassword(password, token)
            .catch(err => {
                if (err.response.statusCode === 400 && err.response.body) {
                    dispatch(actions.setPasswordChangeError(err.response.body.outcome));
                }
            })
            .finally(() => {
                dispatch(actions.setSendNewPassword());
                dispatch(progress.setLoading(false));
            });
    },

    loadRecoverSuccess: loadedRecover => ({ type: types.PASSWORD_RECOVERY_LOAD_SUCCESS, loadedRecover }),
    loadRecoverFail: error => ({ type: types.PASSWORD_RECOVERY_LOAD_FAIL, error }),
    recoverSuccess: (user, loadedRecover) => ({ type: types.PASSWORD_RECOVERY_SUCCESS, user, loadedRecover }),
    triggerError: (type, emailError) => ({ type, emailError }),
    setSendNewPassword: () => ({ type: types.SEND_NEW_PASSWORD }),
    setPasswordChangeError: passwordChangeError => ({ type: types.PASSWORD_CHANGE_ERROR, passwordChangeError }),
};

export const loadRecover = (state) => state.passwordRecovery.loadedRecover;
export const getUser = (state) => state.passwordRecovery.user;
export const getEmailError = (state) => state.passwordRecovery.emailError;
export const getSendNewPassword = (state) => state.passwordRecovery.sendNewPassword;
export const getPasswordChangeError = (state) => state.passwordRecovery.passwordChangeError;
