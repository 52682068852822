import type { Route } from "@core/application/router/contributables";
import type { MenuItem } from "@core/application/menu/contributables";
import { usePolicyBasedAccessControl } from "@core/application/policies/hooks";
import { matchPath, useLocation } from "react-router-dom";
import get from "lodash/get";

interface PolicyScopeElement {
    policyScope?: string | string[]
    usePolicyScope?: () => string | string[]
    useIsVisible?: () => boolean
}

export function getMenuItemPath(menuItem: MenuItem): string {
    const { route } = menuItem;
    const pathCandidate = typeof route === "string" ? route : route?.path;

    if (pathCandidate === undefined) {
        return ".";
    }

    return pathCandidate;
}

export function getMenuItemRouteAsRoute(menuItem: MenuItem): Route {
    const { route } = menuItem;

    if (typeof route === "string") {
        throw new TypeError(`Route of MenuItem '${menuItem.id}' is a string.'`);
    }

    if (!route) {
        throw new Error(`Route of MenuItem '${menuItem.id}' is undefined.`);
    }

    return route;
}

export const checkPolicyScopeFilterPredicate = (el: PolicyScopeElement) => {
    const { policyScope = "", usePolicyScope = () => "", useIsVisible = () => true } = el;
    const validatePolicyBasedAccess = usePolicyBasedAccessControl();

    const availablePolicy = usePolicyScope() || policyScope;
    const isVisible = useIsVisible();

    if (!availablePolicy && isVisible) return true;
    return validatePolicyBasedAccess(availablePolicy);
};

export const getMenuPathAndSegments = (menuItem: MenuItem, pathname: string) => {
    const segments = pathname.split("/").filter(Boolean);
    const menuPath = getMenuItemPath(menuItem);
    const routeId = get(menuItem, "route.id", "");

    return { menuPath, segments, routeId };
};

export const pathMatchCheck = (menuPath: string, segments: string[], menuItem: MenuItem, routeId: string, pathname: string) => {
    if (pathname && matchPath({ path: menuPath }, pathname)) {
        return true;
    }
    // path segment matches
    return segments.includes(menuPath);
};

export const findActiveMenuItem = (menuItems: MenuItem[]) => {
    const location = useLocation();

    return menuItems.find((menuItem) => {
        const { menuPath, segments, routeId } = getMenuPathAndSegments(menuItem, location.pathname);
        return pathMatchCheck(menuPath, segments, menuItem, routeId, location.pathname);
    });
};
