import React from "react";

import {useContext} from "react";

const initialStyle = {};

const initialContextState = {
    style: initialStyle,
};

const StyleContext = React.createContext(initialContextState);

function StyleProvider({ children, value = initialStyle}) {
    return (
        <StyleContext.Provider value={value}>
            {children}
        </StyleContext.Provider>
    );
}

const withStyleProvider = (value = initialStyle) => Component => props => {
    return(
        <StyleProvider value={value}>
            <Component {...props} />
        </StyleProvider>
    );
};

function useStyleContext() {
    const context = useContext(StyleContext);

    if (context === undefined) {
        throw new Error("useStyleContext must be used within StyleContext");
    }

    return context;
}

export { StyleContext, StyleProvider, withStyleProvider, useStyleContext };
