// Scope of this service is to encapsulate tracking logic like publishing partner bindings
// or conversion events towards the tracking system (Google Tag Manager).
// We use the standard contract for working with the GTM (pushing data into the window.dataLayer
// array).
//
// @author: wolfgang.vespermann@investify.lu
export const initPartnerSensitiveTracking = partnerId => {
    push( { 'partner': { 'id': partnerId } } );
}

export const initUserSensitiveTracking = userId => {
    push( { 'uval': { 'value': userId } } );
}

export const fireExceptionEvent = error => {
    push( {
        event: 'exception',
        exception: {
          type: error.type,
          message: error.message
        }
    } );
}

export const fireUserRegisteredEvent = () => {
    pushEvent( 'user-registered' );
}

export const fireUserEmailConfirmedEvent = () => {
    pushEvent( 'email-confirmed' );
}

export const fireContractSignedEvent = () => {
    pushEvent( 'signed-contract' );
}

export const fireSelectedTopicsEvent = ( topics, activatedFilters, pageDetail ) => {
    push( {
        event: 'selected-topics',
        selectedTopics: {
            topics: topics,
            details: {
                activatedFilters: activatedFilters,
                page: pageDetail
            }
        }
    } );
}

export const fireCCCategorieOptInEvent = ( category, allow ) => {
    push( {
        event: 'cc-category-opt-in',
        ccCategory: {
          name: category,
          allow: allow
        }
    } );
}

export const fireCCUserAcknowledgedEvent = () => {
    pushEvent( 'cc-user-acknowledged' );
}

const pushEvent = type => {
    push( { 'event': type } );
}

const push = data => {
    window?.dataLayer && window.dataLayer.push( data );
}
