import theme from "@theme";
import ThemeProvider from "@providers/ThemeProvider";
import NotificationProvider from "@modules/notification/provider/Notification";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import QueryClientProvider from "@providers/QueryClientProvider";
import { ModuleScopeProvider } from "@providers/ModuleScopeProvider";
import withAll from "lodash/flowRight";
import { withErrorBoundary } from "react-use-error-boundary";

const ApplicationProvider = ({ children }) => (
    <ThemeProvider theme={theme}>
        <NotificationProvider>
            <QueryClientProvider>
                <MuiPickersUtilsProvider utils={LuxonUtils} locale="de">
                    <ModuleScopeProvider moduleId={"application"}>
                        {children}
                    </ModuleScopeProvider>
                </MuiPickersUtilsProvider>
            </QueryClientProvider>
        </NotificationProvider>
    </ThemeProvider>
);

export default withAll(withErrorBoundary)(ApplicationProvider);
