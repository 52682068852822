import { RouterRegistry } from "@core/application/router/services";
import { useInject } from "@core/application/ioc/hooks";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Navigate } from "react-router-dom";
import { buildTree, useLocation } from "@hooks/router";

export function useRoutes() {
    const registry = useInject(RouterRegistry);

    return useMemo(() => {
        const routes = registry.getAllRoutes();
        return buildTree(routes);
    }, [registry]);
}

export function useRegistryRoutes() {
    const registry = useInject(RouterRegistry);

    return useMemo(() => {
        return registry.getAllRoutes();
    }, [registry]);
}

export function useRedirects() {
    const registry = useInject(RouterRegistry);

    return useMemo(() => {
        const redirects = registry.getAllRedirects();

        return redirects.map((redirect) => ({
            path: redirect.from,
            element: <Navigate to={redirect.to}/>
        }));
    }, [registry]);
}

export function useRouterEvents({ onWillChangeRoute = () => {}, onDidChangeRoute = () => {} }) {
    const { pathname } = useLocation();
    const previousPathnameRef = useRef(pathname);
    const { current: previousPathname } = previousPathnameRef;

    useLayoutEffect(() => {
        if (pathname !== previousPathname) {
            onWillChangeRoute();
        }
    }, [pathname, previousPathname, onWillChangeRoute]);

    useEffect(() => {
        if (pathname !== previousPathname) {
            previousPathnameRef.current = pathname;

            onDidChangeRoute();
        }
    }, [pathname, previousPathname, onDidChangeRoute]);
}

export function useIsRoutableLink() {
    const registry = useInject(RouterRegistry);

    return useCallback((link: string) => {
        return registry.getAllRoutes().some(({ path }) => link === path);
    }, [registry]);
}
