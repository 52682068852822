import type { RouterRegistry } from "@core/application/router/services";
import type { ComponentType } from "react";

export type UserState = "PROSPECT" | "REGISTERED_USER" | "CUSTOMER" | undefined;

export interface Location {
    readonly params?: Record<string, string | undefined>
    readonly route: Route
    readonly search?: string
}

export interface Route {
    readonly Component: ComponentType
    readonly ErrorBoundary?: ComponentType
    readonly children?: Route[]
    readonly id: string
    readonly index?: true
    readonly path?: string
    readonly breadcrumb?: string
    readonly skip?: boolean
    readonly public?: boolean
}

export interface Redirect {
    readonly from: string
    readonly to: string
}

export interface StartingPoint {
    readonly canHandle: (userState: UserState, loginStatus: boolean) => boolean
    readonly default?: boolean
    readonly id: string
    readonly location: Location
}

export const RouterContribution = Symbol("RouterContribution");

export interface RouterContribution {
    registerContributions(registry: RouterRegistry): void
}
