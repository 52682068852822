import localStorage2 from 'local-storage';
import {initPartnerSensitiveTracking} from '@services/tracking.service';
import isEqual from 'lodash/isEqual';

// The intended behavior is as follows:
//
// Whenever the client is called using partner parameters, those are persisted in local storage and posted to the
// backend (as long as the client has a prospect identity, the backend allows updates). The frontend always updates
// the local storage state with the response from backend (after all an update may have different effects due to backend
// business rules).
//
// Whenever the client negotiates a new identity (either initially or through user login), the client tries to update the
// backend binding state with it's state from local storage (as derived from query params), in order to eventually have
// the consistent, same state in it's own local storage. That way the binding is sticky after logout.

export const saveToLocalStorageAndRemoveNvstParams = (params) => {
    const currentBinding = getStoredPartnerBinding();

    if(params.nvst_partner && currentBinding.partnerId !== params.nvst_partner) {
      currentBinding.partnerId = params.nvst_partner;

      // If we have a partner change, drop params from previous partner
      currentBinding.bindingParameters = {};
    }

    const partnerParams = Object.keys(params)
        .filter( (param) => param.indexOf('nvst_') === 0 && param !== 'nvst_partner' )
        .reduce( (obj, key) => ({ ...obj, [key]: params[key] }), {});

    if(Object.keys(partnerParams).length > 0) {
      currentBinding.bindingParameters = partnerParams;
    }

    const bindingsDidChange = saveBindingToLocalStorage(currentBinding);

    return {
      bindingsDidChange: bindingsDidChange,
      remainingParams: Object.keys(params)
        .filter(param => param.indexOf('nvst_') === -1)
        .reduce((acc, param) => ({ ...acc, [param]: params[param] }), {})
    }
};

export const saveBindingToLocalStorage = (binding) => {
  const currentBinding = getStoredPartnerBinding();

  if(isEqual(currentBinding, binding)) {
    return false;
  }

  localStorage2.set( 'nvst_partner', binding.partnerId );
  localStorage2.set( 'nvst_partner_params', binding.bindingParameters );

  initPartnerSensitiveTracking( binding.partnerId );

  return true;
}

export const getStoredPartnerBinding = () => {

  const getFromStorage = (paramName) => {
    let paramValue = localStorage.getItem(paramName);

    if(paramValue && paramValue.length>0 && paramValue.charAt(0) !== '{' && paramValue.charAt(0) !== '"') {
        localStorage2.set(paramName, paramValue);
    }

    return localStorage2.get(paramName);
  }

  return {
    type: 'BINDING',
    bindingType: 'BANK_ONLINE',
    partnerId: getFromStorage('nvst_partner') || null,
    bindingParameters: getFromStorage('nvst_partner_params') || {}
  }
}
