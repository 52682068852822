import palette from "@theme/palette";
import typography from "@theme/typography";
import color from "color";

export default {
    root: {
        fontSize: typography.overline.fontSize,
        fontWeight: typography.overline.fontWeight,
        lineHeight: typography.overline.lineHeight,
        borderBottom: `1px solid ${color(palette.divider).fade(0.8).toString()}`,
        padding: 8
    },
    stickyHeader: {
        backgroundColor: palette.background.paper
    }
};
