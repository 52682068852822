import { actions as progress } from '@ducks/progress';
import { actions as header } from '@ducks/header';
import { getPath } from '@services/api.service';

export const types = {
    IMPRINT_PAGE_LOAD_SUCCESS: 'IMPRINT_PAGE/LOAD_SUCCESS',
    IMPRINT_PAGE_LOAD_ERROR: 'IMPRINT_PAGE/LOAD_ERROR'
};

export const initialState = {
    imprintPageData: null,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.IMPRINT_PAGE_LOAD_SUCCESS:
            return { ...state, imprintPageData: action.data }
        case types.IMPRINT_PAGE_LOAD_ERROR:
            return { ...state, error: action.error }
        default:
            return state;
    }
};

export const actions = {
    loadImprintPageData: () => (dispatch) => {
        dispatch(progress.setLoading(true));
        getPath([{ path: 'imprint' }]).then(re => {
            dispatch(actions.loadImprintPageSuccess(re));
            dispatch(header.updateTitle((re.dictionary && re.dictionary.pageTitle) ? re.dictionary.pageTitle : ''));
            dispatch(progress.setLoading(false));
        }).catch(err => {
            dispatch(actions.loadImprintPageError(err));
            dispatch(progress.setLoading(false));
        });
    },
    loadImprintPageSuccess: (data) => ({ type: types.IMPRINT_PAGE_LOAD_SUCCESS, data }),
    loadImprintPageError: (err) => ({ type: types.IMPRINT_PAGE_LOAD_ERROR, err }),
}

export const getImprintPageData = (state) => state.imprint.imprintPageData;
