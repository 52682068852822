/**
 * Access deeply nested object property in a safe manner.
 * 
 * @deprecated use lodash get
 * 
 * @param {object} o The object
 * @param {array} p The path to the property
 * @returns {object} the property or null if any of the path segments did not exist
 */
export const path = (o, p) => p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o);

/**
 * Remove element from array by element identity.
 * 
 * @param {array} arr the array to remove the element from
 * @param {object} element the element to remove
 * @returns {object} the remove element or undefined
 */
export const remove = (arr, element) => {
  const index = arr.indexOf(element);
 
  if(index > -1) {
    arr.splice(index, 1);
    return element;
  }
}