import * as colors from "@material-ui/core/colors";
import shape from "@theme/shape";

export default {
    root: {
        borderRadius: shape.borderRadius,
        overflow: "hidden"
    },
    colorPrimary: {}
};
